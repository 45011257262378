import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, profiles }) => {
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return isAuthenticated ? (
    profiles && profiles?.length > 0 ? (
      <Outlet />
    ) : (
      <Navigate to={`/signup/step1${window.location.search}`} />
    )
  ) : (
    <Navigate to="/signup" />
  );
};

export default PrivateRoute;
