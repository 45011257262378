import * as React from 'react';

function CheckInIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" fill="url(#pattern0)" />
      <defs>
        <pattern
          id="pattern0"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#image0_1003_10705" transform="scale(0.015625)" />
        </pattern>
        <image
          id="image0_1003_10705"
          width="64"
          height="64"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAABYgAAAWIBXyfQUwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAQ/SURBVHic7ZtPaFRHGMB/aZSokSLBP9UYG2yJ/7VWrRS8eBWKFopQWlq8idJW8KQnDyooaItYaC8eRBCUIhYEwYO56MWtisY/qS1tksZgwLommhDzz8NscHZ2Zt7M7ntvnmR/MDA7+83MN9/uzPe9mXmQPquBn4EHwEvgBdAG/AQsCaBPatQhBj4GjBvSCHAUmBJIx8SoA65gHriaLgC1QTRNiB8pHeQQcAP4AxjWfH8ghKJJ0ELpAH8FGiSZucAZRWYQaExV04Q4TPHAThnkaoDfFNl9aSjownzgEHALyOM+l9U0WmjLREsFbY8D/wM5xPSZXfmwBV8hXFUlik2kvxz6642przywrYJxA/A1drflm8459Hk5xv5Gga22zmos3y0AHgEzpLJx4G/guUb+I964rgFEoCNzDzGnH9sUApoR60aLUr4CmFbIDwN3NHVnAR8oZc+ADxHTw4sjFFuzDVhuke+TZHO+nTnQLrXfbZFbi/jhZN33l9Nhm9TACLA0Qj4rBgBYT7EBrpkE37E00qx0/tBFy4yQA7qkz4tNgjYD1Ev5XodO+6T8+w7yPtRSHBTlHerIOtebhGwG8KVdys8GtsTY9naKB9FuEvQlTgNcUD6fBr6k2Iv4MhPYAfwS0VfZ2NzguJRvBTZHtDUd4frUv/8I0O+tmeBdSp8K7wNrCu3ayAHrCvn+QlteyKvoVcc6G4gvatSlZ4h4wIWcVK/PJBTnFADxeLuJ0iAoDm4DnyICqthIYuflNmLb63PgM4QLmmatYWYQEdT8XkhjcSjoSjlTIEsEmQJvHVUDhFYgNFUDhFYgNGkeQGwEThC9V9cD7EK/4ZEqcbvBm7hHfK0x9Jc5N7jQQ7YpMS0UQp3BvQL+U8oWEUCfUAZ4BKxUyjoQRkiVSe8FqgYIrUBo4lwDPgGOYz77azCU62hCHMDo6AR+IIU4wTcOuIu7n2/T1O/wqG/c55dIPQ7w2Qrv1JSpbtFGs4eslaTc4BDm05suYK+mfDfiDtF7hnpNwNTKVXPHdwpk6WgMMhgKZ5KqAUIrEJqkFsFlmNeBbmAP8I9SvhQ4Bswz1Ev9OaGSRTAqXdTUv+pRP5OLYNTVFxn1GgtYzvA1uBjAiTinwE7gJOJukY5Z2A9jZcbQ30MCETF+76eamTgN0ErpM75MLzDHsa1/0f9LYmfSe4GqAUIrEJpQe4KLKY0TTA9BiRLKANN5c30lKGlOgScesj2JaaGQ5j/gO8Rtr7kRco/R7xckQpoGaCX6um3qTHovUDWA5bsBKe8awmYJWeeX5TRwj+Lr8uoLDFnmY4ofn6+bBG3/gEtSvhY4z9thhFXAWaXskk4Q7I+nC4E/EUHLBKOI9wZS89OeNCLeP5Z/2DzilZmn5TT4Dcnd+00jjSGu2lfEt4hFJPRgfNNz4ItKBz9BI+IlqrsZN8YLxMudB4mOOAF4DfHiPzUPiY4NAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
}

export default CheckInIcon;
