import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4, pt: 6, pb: 1 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ width: 24, height: 24 }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function CustomizedDialogs({
  title,
  open,
  setOpen,
  children,
  maxWidth = 'sm',
  actions = [],
  fullScreen = false,
  sx = {},
  paperSx = {},
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={maxWidth}
      fullWidth
      fullScreen={fullScreen}
      sx={{
        ...sx,
        '& .MuiDialog-paper': { overflowY: 'scroll', ...paperSx },
      }}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        style={{ fontSize: 24 }}
      >
        {title}
      </BootstrapDialogTitle>
      <DialogContent>{children}</DialogContent>
      {actions.length > 0 && (
        <DialogActions>
          {actions.map((action, index) => (
            <Button key={index} onClick={action.function}>
              {action.title}
            </Button>
          ))}
        </DialogActions>
      )}
    </BootstrapDialog>
  );
}
