import styled from '@emotion/styled';
import { Popper, Typography } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { SignupContext } from '../contexts/Signup';

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    'boxSizing': 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default function AreasOfLivesSelect({ forWho, value, setValue, error }) {
  const { organization } = React.useContext(SignupContext);
  return (
    <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
      <Typography
        sx={{
          textAlign: 'start',
          fontSize: 16,
          fontWeight: 500,
          color: '#0B3954',
        }}
      >
        {forWho !== 1
          ? organization === 'Story Catch'
            ? 'What areas of your life would you like to journal about?'
            : 'What areas of your life would you like to journal about?'
          : organization === 'Story Catch'
          ? 'What areas of your lives would you like to journal about?'
          : 'What areas of your lives would you like to journal about?'}
      </Typography>
      <Autocomplete
        multiple
        disableListWrap
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
        id="areas-of-lives-select"
        options={areas}
        PopperComponent={StyledPopper}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip variant="filled" label={option} key={key} {...tagProps} />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            // placeholder="Select all that apply or enter other things"
            helperText="Press enter to add something that is not in this list"
          />
        )}
      />
      {error && (
        <Typography sx={{ color: 'red', textAlign: 'start', fontSize: 14 }}>
          {error}
        </Typography>
      )}
    </Stack>
  );
}

const areas = [
  'Eating',
  'Sleeping',
  'Movement',
  'Work',
  'Social',
  'School',
  'Relationship',
  'Emotional Balance',
  'Religious/Spiritual Practices',
];
