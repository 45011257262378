import React from 'react';
export const SuperHeroIcon = () => {
  return (
    <img
      alt="superHero"
      src={require('./superHero.png')}
      height={24}
      width={24}
    />
  );
};
