import React from 'react';
export const SuperStarIcon = () => {
  return (
    <img
      alt="superStar"
      src={require('./superStar.png')}
      height={24}
      width={24}
    />
  );
};
