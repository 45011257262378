import * as React from 'react';

function AnalyticsIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2.16659C11.3402 2.16359 9.70193 2.54201 8.2116 3.27264C6.72127 4.00327 5.41875 5.06658 4.40459 6.3805C3.39042 7.69441 2.69172 9.22379 2.36242 10.8506C2.03312 12.4774 2.08202 14.1581 2.50536 15.763C2.92869 17.3679 3.71514 18.854 4.80399 20.1068C5.89284 21.3595 7.25498 22.3453 8.78527 22.988C10.3156 23.6307 11.9731 23.9133 13.6299 23.8138C15.2867 23.7142 16.8985 23.2354 18.3408 22.4141H18.4167H18.4708C20.5565 21.2268 22.1889 19.3805 23.1118 17.165C24.0346 14.9496 24.1957 12.4904 23.5697 10.1735C22.9436 7.85666 21.566 5.81323 19.653 4.36404C17.7399 2.91484 15.3998 2.14192 13 2.16659ZM14.0833 4.40909C15.99 4.65088 17.7622 5.51961 19.1213 6.87864C20.4803 8.23767 21.349 10.0099 21.5908 11.9166H14.0833V4.40909ZM13 21.6666C10.8057 21.6516 8.69879 20.8048 7.10443 19.2972C5.51007 17.7895 4.54697 15.7331 4.40949 13.5431C4.27201 11.3531 4.97039 9.19243 6.36369 7.4972C7.75699 5.80196 9.74148 4.69835 11.9167 4.40909V12.9999C11.9209 13.1296 11.9466 13.2577 11.9925 13.3791V13.4658V13.5416L16.3258 20.9841C15.2747 21.434 14.1433 21.6661 13 21.6666ZM18.2325 19.8899L14.8742 14.0833H21.5908C21.445 15.2257 21.0729 16.3277 20.4962 17.3247C19.9196 18.3217 19.15 19.1938 18.2325 19.8899Z"
        fill="#0C3954"
      />
    </svg>
  );
}

export default AnalyticsIcon;
