import { Container, Stack } from '@mui/material';
import React, { useEffect } from 'react';
import DashboardSectionHeader from '../components/DashboardSectionHeader';
import MoodHomeWidget from '../components/MoodHomeWidget';
import useResponsive from '../hooks/useResponsive';

const Journal = () => {
  useEffect(() => {}, []);
  const isDesktop = useResponsive('up', 'md');

  return (
    <Container disableGutters maxWidth={false} sx={{ mb: 3 }}>
      <Stack
        direction={'column'}
        alignItems={'start'}
        spacing={2}
        sx={{ height: '100%', pt: isDesktop ? 0 : 3 }}
      >
        <DashboardSectionHeader
          title="Chat with Your Rare Ally"
          describtion="Start discussing whatever you need to talk about right away"
        />
        <MoodHomeWidget />
      </Stack>
    </Container>
  );
};

export default Journal;
