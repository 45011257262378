import mixpanel from 'mixpanel-browser';

export function track(eventName, parameters) {
  try {
    console.debug('[EVENT_TRACK]', eventName, 'parameters:', parameters);
  } catch (e) {}

  // if (process.env.NODE_ENV === 'development') {
  //   return;
  // }

  try {
    mixpanel.track(eventName, {
      ...(parameters || {}),
      url: window.location.href,
    });
  } catch (e) {
    console.error('failed to send event');
    // TODO: report to sentry
  }
}
