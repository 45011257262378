import { useEffect, useState } from 'react';

export function usePersistedState(key, initialValue, storage) {
  const _localStorage = storage || localStorage;

  const [state, setState] = useState(() => {
    const storedValue = _localStorage.getItem(key);
    return storedValue ? storedValue : initialValue;
  });

  useEffect(() => {
    if (state == null) {
      _localStorage.removeItem(key);
    } else {
      _localStorage.setItem(key, state);
    }
  }, [key, state]);

  return [state, setState];
}

export function usePersistedNumberState(key, initialValue, storage) {
  const _localStorage = storage || localStorage;

  const [state, setState] = useState(() => {
    const storedValue = _localStorage.getItem(key);
    return storedValue ? parseFloat(storedValue) : initialValue;
  });

  useEffect(() => {
    if (state == null) {
      _localStorage.removeItem(key);
    } else {
      _localStorage.setItem(key, state.toString());
    }
  }, [key, state]);

  return [state, setState];
}

export function usePersistedJsonState(key, initialValue, storage) {
  const _localStorage = storage || localStorage;

  const [state, setState] = useState(() => {
    const storedValue = _localStorage.getItem(key);
    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    if (state == null) {
      _localStorage.removeItem(key);
    } else {
      _localStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state]);

  return [state, setState];
}
