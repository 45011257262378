import 'firebase/analytics';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';
import 'firebase/performance';

// const tz = moment.tz.guess();

const config = {
  apiKey: 'AIzaSyCfFw27Exit7VBw3sXRML5szmk0UPq_DwE',
  authDomain: 'knowrare-app.firebaseapp.com',
  projectId: 'knowrare-app',
  storageBucket: 'knowrare-app.appspot.com',
  messagingSenderId: '839881628442',
  appId: '1:839881628442:web:e2bd36b7a8ffe7b281aaa3',
  measurementId: 'G-RBB2839P3Q',
};

firebase.initializeApp(config);

export default firebase;
