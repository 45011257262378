import { FormControl, MenuItem, Select } from '@mui/material';
import React, { useEffect } from 'react';

export default function SelectLanguage() {
  const [lang, setLang] = React.useState('en');

  useEffect(() => {
    if (typeof window !== 'undefined' && localStorage.getItem('wglang')) {
      setLang(localStorage.getItem('wglang'));
    }
  }, []);

  const handleChange = (event) => {
    window.Weglot.switchTo(event.target.value);
    setLang(event.target.value);
    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  return (
    <div
      style={{
        height: 42,
        display: 'flex',
        alignItems: 'center',
        marginTop: -8,
      }}
      id="weglot-exlude"
    >
      <FormControl id="weg-ex" sx={{ pl: 1 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={lang}
          onChange={handleChange}
          disableUnderline
          variant="standard"
          sx={{ fontSize: 14, color: '#929292' }}
        >
          <MenuItem value={'en'} sx={{ fontSize: 14 }}>
            EN
          </MenuItem>
          <MenuItem value={'es'} sx={{ fontSize: 14 }}>
            ES
          </MenuItem>
          <MenuItem value={'de'} sx={{ fontSize: 14 }}>
            DE
          </MenuItem>
          <MenuItem value={'it'} sx={{ fontSize: 14 }}>
            IT
          </MenuItem>
          <MenuItem value={'no'} sx={{ fontSize: 14 }}>
            NO
          </MenuItem>
          <MenuItem value={'fr'} sx={{ fontSize: 14 }}>
            FR
          </MenuItem>
          <MenuItem value={'pl'} sx={{ fontSize: 14 }}>
            PL
          </MenuItem>
          <MenuItem value={'he'} sx={{ fontSize: 14 }}>
            HE
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}
