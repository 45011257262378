import React, { createContext } from 'react';
import IframeDialog from '../components/IframeDialog';

export const IframeContext = createContext();

function IframeContextProvider(props) {
  const [url, setUrl] = React.useState(null);

  return (
    <IframeContext.Provider
      value={{
        url: url,
        setUrl: setUrl,
      }}
    >
      <IframeDialog
        open={url !== null && url !== false}
        setOpen={setUrl}
        url={url}
      />
      <div>{props.children}</div>
    </IframeContext.Provider>
  );
}

export default IframeContextProvider;
