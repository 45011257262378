import { Add } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  ArrowRightIcon,
  DesktopTimePicker,
  TimePicker,
} from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import Lottie from 'lottie-react';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import firebase from '../Firebase';
import { ProfileContext } from '../contexts/Profile';
import { SignupContext } from '../contexts/Signup';
import CustomizedDialogs from './Dialog';
import MedicationsAutocomplete from './MedicationsAutocomplete';

const AddEditMedication = ({ open, setOpen, medication = null }) => {
  const { currentProfile, fetchMedications, role } = useContext(ProfileContext);
  const [dosageAmount, setDosageAmount] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [notificationStatus, setNotificationStatus] = useState('on');
  const [firstDoseTime, setFirstDoseTime] = useState(null);
  const [secondDoseTime, setSecondDoseTime] = useState(null);
  const [thirdDoseTime, setThirdDoseTime] = useState(null);
  const [medicationName, setMedicationName] = useState(null);
  const [addEditLoading, setAddEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState(false);
  const [customMedication, setCustomMedication] = useState(false);
  const [customMedicationName, setCustomMedicationName] = useState(null);

  useEffect(() => {
    if (open && medication) {
      setDosageAmount(medication.data().dosageAmount);
      setFrequency(medication.data().frequency);
      setNotificationStatus(medication.data().notificationStatus);
      setFirstDoseTime(dayjs(medication.data().firstDoseTime));
      setSecondDoseTime(dayjs(medication.data().secondDoseTime));
      setThirdDoseTime(dayjs(medication.data().thirdDoseTime));
      if (medication.data()?.customMedication) {
        setCustomMedication(true);
        setCustomMedicationName(medication.data().medicationName);
      } else {
        setMedicationName(medication.data().medicationName);
      }
    }
  }, [open, medication]);

  const disabledAddEditButton = () => {
    if (deleteLoading) return true;
    if (
      !(!medicationName || !customMedicationName) ||
      !dosageAmount ||
      !frequency ||
      !firstDoseTime
    )
      return true;
    if (frequency === '2xd' && !secondDoseTime) return true;
    if (frequency === '3xd' && !thirdDoseTime) return true;
    return false;
  };

  const handleAddEditMedication = async () => {
    const data = {
      dosageAmount,
      frequency,
      notificationStatus,
      firstDoseTime: firstDoseTime.valueOf(),
      medicationName: customMedication ? customMedicationName : medicationName,
      profileId: currentProfile.id,
      createdBy: firebase.auth().currentUser.uid,
      timezone: moment.tz.guess(),
      customMedication,
    };

    if (frequency === '2xd') {
      data.secondDoseTime = secondDoseTime.valueOf();
    }
    if (frequency === '3xd') {
      data.secondDoseTime = secondDoseTime.valueOf();
      data.thirdDoseTime = thirdDoseTime.valueOf();
    }
    setAddEditLoading(true);
    if (medication) {
      // Update medication
      await firebase
        .firestore()
        .collection('medications')
        .doc(medication.id)
        .update(data);
    } else {
      // Add medication
      await firebase.firestore().collection('medications').add(data);
    }
    setAddEditLoading(false);
    fetchMedications();
    setOpen(false);
  };

  const handleDeleteMedication = async () => {
    if (medication) {
      setDeleteLoading(true);
      await firebase
        .firestore()
        .collection('medications')
        .doc(medication.id)
        .delete();
      setDeleteLoading(false);
      fetchMedications();
      setOpen(false);
    }
  };

  return (
    <CustomizedDialogs
      open={open}
      setOpen={setOpen}
      title={
        <Stack
          direction={'row'}
          spacing={1.5}
          alignItems={'center'}
          justifyContent={'space-between'}
          width={'100%'}
        >
          <Box>{'Add Medication'}</Box>
        </Stack>
      }
      fullScreen
      paperSx={{ bgcolor: '#FDF4F3' }}
    >
      <Stack
        spacing={0}
        rowGap={0}
        direction={'row'}
        sx={{ flexWrap: 'wrap', width: '100%' }}
        justifyContent={'start'}
      >
        <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 1 }}>
          {!customMedication && (
            <>
              <Typography
                sx={{ fontSize: 16, fontWeight: 500, color: '#0B3954' }}
              >
                Medication name
              </Typography>
              <MedicationsAutocomplete
                value={medicationName}
                setValue={setMedicationName}
                disabled={customMedication}
              />
            </>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={customMedication}
                onChange={(e) => setCustomMedication(e.target.checked)}
                size="small"
                sx={{ ml: -1 }}
              />
            }
            label="Add medication not listed"
          />
          {customMedication && (
            <Stack
              direction={'column'}
              spacing={1}
              sx={{ width: '100%', mt: 2 }}
            >
              <Typography
                sx={{ fontSize: 16, fontWeight: 500, color: '#0B3954' }}
              >
                Name of medication
              </Typography>
              <TextField
                value={customMedicationName}
                onChange={(e) => setCustomMedicationName(e.target.value)}
                sx={{
                  '& .MuiInputBase-input': {
                    position: 'relative',
                    backgroundColor: 'white',
                    border: '1px solid',
                    borderRadius: 1,
                    borderColor: '#C4C4C4',
                    fontSize: 16,
                    padding: '10px 12px',
                  },
                }}
              />
            </Stack>
          )}
        </Stack>
        <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 2 }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, color: '#0B3954' }}>
            Dosage Amount (Mg)
          </Typography>
          <TextField
            value={dosageAmount}
            onChange={(e) => setDosageAmount(e.target.value)}
            placeholder="i.e. 10mg, 50mg, etc"
            sx={{
              '& .MuiInputBase-input': {
                position: 'relative',
                backgroundColor: 'white',
                border: '1px solid',
                borderColor: '#C4C4C4',
                fontSize: 16,
                padding: '10px 12px',
                borderRadius: 1,
              },
            }}
          />
        </Stack>
        <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 2 }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, color: '#0B3954' }}>
            How often do you take this?
          </Typography>
          <TextField
            select
            placeholder="1x Day, 2x Day, 3x Day"
            value={frequency}
            onChange={(e) => setFrequency(e.target.value)}
            sx={{
              '& .MuiInputBase-input': {
                position: 'relative',
                backgroundColor: 'white',
                border: '1px solid',
                borderColor: '#C4C4C4',
                fontSize: 16,
                padding: '10px 12px',
              },
            }}
          >
            {[
              { value: '1xd', label: '1x Day' },
              { value: '2xd', label: '2x Day' },
              { value: '3xd', label: '3x Day' },
            ].map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 2 }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, color: '#0B3954' }}>
            Time of first dose:
          </Typography>
          <DesktopTimePicker
            value={firstDoseTime}
            onAccept={(v) => setFirstDoseTime(dayjs(v.$d))}
            minutesStep={30}
            timeSteps={{ minutes: 30 }}
            hide
            open={openTimePicker}
            onOpen={() => setOpenTimePicker(true)}
            onClose={() => setOpenTimePicker(false)}
            slotProps={{
              textField: {
                onClick: () => setOpenTimePicker(true),
                variant: 'outlined',
                fullWidth: true,
                sx: {
                  'position': 'relative',
                  'backgroundColor': 'white',
                  'border': '0.5px solid',
                  'borderColor': '#979797',
                  'fontSize': 16,
                  'padding': '0x',
                  'borderRadius': 1,
                  'color': '#979797',
                  '& .MuiInputBase-input': {
                    position: 'relative',
                    backgroundColor: 'white',
                    borderWidth: 0,
                    borderColor: '#979797',
                    fontSize: 16,
                    padding: '10px 12px',
                    borderRadius: 1,
                  },
                },
              },
            }}
          />
        </Stack>
        {(frequency === '2xd' || frequency === '3xd') && (
          <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 2 }}>
            <Typography
              sx={{ fontSize: 16, fontWeight: 500, color: '#0B3954' }}
            >
              Time of second dose:
            </Typography>
            <TimePicker
              value={secondDoseTime}
              onAccept={(v) => setSecondDoseTime(dayjs(v.$d))}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                  sx: {
                    'position': 'relative',
                    'backgroundColor': 'white',
                    'border': '0.5px solid',
                    'borderColor': '#979797',
                    'fontSize': 16,
                    'padding': '0x',
                    'borderRadius': 1,
                    'color': '#979797',
                    '& .MuiInputBase-input': {
                      position: 'relative',
                      backgroundColor: 'white',
                      borderWidth: 0,
                      borderColor: '#979797',
                      fontSize: 16,
                      padding: '10px 12px',
                      borderRadius: 1,
                    },
                  },
                },
              }}
            />
          </Stack>
        )}
        {frequency === '3xd' && (
          <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 2 }}>
            <Typography
              sx={{ fontSize: 16, fontWeight: 500, color: '#0B3954' }}
            >
              Time of third dose:
            </Typography>
            <TimePicker
              value={thirdDoseTime}
              onAccept={(v) => setThirdDoseTime(dayjs(v.$d))}
              slotProps={{
                textField: {
                  variant: 'outlined',
                  fullWidth: true,
                  sx: {
                    'position': 'relative',
                    'backgroundColor': 'white',
                    'border': '0.5px solid',
                    'borderColor': '#979797',
                    'fontSize': 16,
                    'padding': '0x',
                    'borderRadius': 1,
                    'color': '#979797',
                    '& .MuiInputBase-input': {
                      position: 'relative',
                      backgroundColor: 'white',
                      borderWidth: 0,
                      borderColor: '#979797',
                      fontSize: 16,
                      padding: '10px 12px',
                      borderRadius: 1,
                    },
                  },
                },
              }}
            />
          </Stack>
        )}
        <Divider />
        <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 3 }}>
          <Typography sx={{ fontSize: 16, fontWeight: 500, color: '#0B3954' }}>
            Medication Reminder/Notifcations
          </Typography>
          <Typography sx={{ fontSize: 14, fontWeight: 500, color: '#0B3954' }}>
            Get reminded about when to take this
          </Typography>
          <TextField
            select
            value={notificationStatus}
            onChange={(e) => setNotificationStatus(e.target.value)}
            sx={{
              '& .MuiInputBase-input': {
                position: 'relative',
                backgroundColor: 'white',
                border: '1px solid',
                borderColor: '#C4C4C4',
                fontSize: 16,
                padding: '10px 12px',
                color: notificationStatus === 'on' ? 'green' : 'red',
                fontWeight: 'bold',
              },
            }}
          >
            {[
              { value: 'on', label: 'ON' },
              { value: 'off', label: 'OFF' },
            ].map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                sx={{
                  color: option.value === 'on' ? 'green' : 'red',
                  fontWeight: 'bold',
                }}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Stack>
        <Stack direction={'column'} spacing={2} sx={{ width: '100%', mt: 3 }}>
          <LoadingButton
            fullWidth
            variant="contained"
            size="large"
            onClick={handleAddEditMedication}
            loading={addEditLoading}
            disabled={disabledAddEditButton()}
          >
            {medication ? 'Update Medication' : 'Add Medication'}
          </LoadingButton>
          {medication && (
            <LoadingButton
              fullWidth
              variant="contained"
              size="large"
              color="error"
              onClick={handleDeleteMedication}
              loading={deleteLoading}
              disabled={addEditLoading}
            >
              Delete Medication
            </LoadingButton>
          )}
        </Stack>
      </Stack>
    </CustomizedDialogs>
  );
};

const MedicationChip = ({ type, text }) => {
  return (
    <Stack
      direction={'row'}
      spacing={1.5}
      sx={{
        bgcolor: 'white',
        textAlign: 'center',
        borderRadius: 1,
        border: '1px solid #000000',
        p: 1,
        pt: 0.5,
        pb: 0.5,
      }}
    >
      {type === 'time' ? (
        <img
          src={require('../assets/icons/time.png')}
          style={{ width: 16, height: 16 }}
        />
      ) : type === 'timesPerDay' ? (
        <img
          src={require('../assets/icons/timesPerDay.png')}
          style={{ width: 16, height: 16 }}
        />
      ) : null}
      <Box sx={{ fontSize: 12 }}>{text}</Box>
    </Stack>
  );
};

const MedicationCard = ({ medication, openEditMedicationDialog }) => {
  return (
    <Stack
      direction={'column'}
      justifyContent={'start'}
      alignItems={'start'}
      sx={{
        bgcolor: 'white',
        borderRadius: 2,
        boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
        p: 2,
        width: '100%',
      }}
      spacing={2}
      onClick={() => openEditMedicationDialog(medication)}
    >
      <Stack
        spacing={0}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        sx={{ width: '100%' }}
      >
        <Stack spacing={0}>
          <Box sx={{ fontSize: 16, fontWeight: 500, color: '#0B3954' }}>
            {medication.data().medicationName}
          </Box>
          <Box
            sx={{
              fontSize: 12,
              fontWeight: 500,
              color: '#5F5F5F',
              textAlign: 'start',
            }}
          >
            {medication.data().dosageAmount}
          </Box>
        </Stack>
        <IconButton onClick={() => openEditMedicationDialog(medication)}>
          <ArrowRightIcon />
        </IconButton>
      </Stack>
      <Stack
        direction={'row'}
        justifyContent={'center'}
        alignItems={'center'}
        sx={{ flex: 1, flexFlow: 'wrap', gap: 1, justifyContent: 'start' }}
        // spacing={2}
      >
        <MedicationChip
          type={'timesPerDay'}
          text={`${medication.data().frequency.split('x')[0]}x Day`}
        />
        {(medication.data().frequency === '1xd' ||
          medication.data().frequency === '2xd' ||
          medication.data().frequency === '3xd') && (
          <MedicationChip
            type={'time'}
            text={dayjs(medication.data().firstDoseTime).format('hh:mm a')}
          />
        )}
        {(medication.data().frequency === '2xd' ||
          medication.data().frequency === '3xd') && (
          <MedicationChip
            type={'time'}
            text={dayjs(medication.data().secondDoseTime).format('hh:mm a')}
          />
        )}
        {medication.data().frequency === '3xd' && (
          <MedicationChip
            type={'time'}
            text={dayjs(medication.data().thirdDoseTime).format('hh:mm a')}
          />
        )}
      </Stack>
    </Stack>
  );
};

const ManageMedications = () => {
  const navigate = useNavigate();

  const { medications } = React.useContext(ProfileContext);
  const { afterSignup } = React.useContext(SignupContext);
  const [openAddEditMedicationDialog, setOpenAddEditMedicationDialog] =
    React.useState(false);
  const [medication, setMedication] = React.useState(null);

  const openEditMedicationDialog = (medication) => {
    setOpenAddEditMedicationDialog(true);
    setMedication(medication);
  };

  useEffect(() => {
    if (!openAddEditMedicationDialog) {
      setMedication(null);
    }
  }, [openAddEditMedicationDialog]);

  return (
    <Stack
      direction={'column'}
      spacing={2}
      sx={{ width: '100%', minHeight: 'calc(100vh - 110px)', flexGrow: 1 }}
    >
      {openAddEditMedicationDialog && (
        <AddEditMedication
          open={openAddEditMedicationDialog}
          setOpen={setOpenAddEditMedicationDialog}
          medication={medication}
        />
      )}
      {medications?.length === 0 && (
        <Stack
          sx={{ width: '100%', mt: 6 }}
          justifyContent={'center'}
          alignItems={'center'}
          gap={5}
        >
          <Typography sx={{ fontWeight: '600', fontSize: 24 }}>
            Add medications
          </Typography>

          <Lottie
            animationData={require('../assets/lotties/medication-blank-state.json')}
            style={{
              height: 220,
              width: 220,
            }}
            loop={true}
          />
          <Typography sx={{ fontSize: 24 }}>
            Get notified daily with reminders to take your medications
          </Typography>
        </Stack>
      )}
      <Stack
        spacing={0}
        rowGap={2}
        direction={'column'}
        sx={{ flexWrap: 'wrap', mt: 2, width: '100%' }}
        justifyContent={'start'}
      >
        {[...medications].map((medication, _index) => (
          <MedicationCard
            key={_index}
            medication={medication}
            openEditMedicationDialog={openEditMedicationDialog}
          />
        ))}
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <Button
        variant="contained"
        onClick={() => setOpenAddEditMedicationDialog(true)}
        fullWidth
        size="large"
        startIcon={<Add />}
      >
        Add medications
      </Button>
      {afterSignup && (
        <Button
          variant="contained"
          onClick={() => navigate('/dashboard/share-profile')}
          fullWidth
          size="large"
          sx={
            medications?.length > 0
              ? {}
              : {
                  'bgcolor': '#AAAAAA',
                  ':focus': {
                    bgcolor: '#AAAAAA',
                  },
                  ':hover': {
                    bgcolor: '#AAAAAA',
                  },
                }
          }
        >
          {medications?.length > 0 ? 'Next' : 'Skip for Now'}
        </Button>
      )}
    </Stack>
  );
};

export default ManageMedications;
