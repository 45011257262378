import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormLabel from '@mui/material/FormLabel';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import OnboardingLayoutOld from '../components/OnboardingLayoutOld';
import { AuthContext } from '../contexts/Auth';
import { ProfilesContext } from '../contexts/Profiles';
import { SignupContext } from '../contexts/Signup';
import { track } from '../utils/analytics';

const FORM = {
  width: '100%',
};

const SignupStep2 = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const { profiles } = useContext(ProfilesContext);

  const {
    needHelpOrSupport,
    setNeedHelpOrSupport,
    loading,
    getName,
    createProfile,
  } = useContext(SignupContext);

  const navigate = useNavigate();

  const canContinue = () => {
    if (Object.values(needHelpOrSupport).includes(true)) return true;
    if (needHelpOrSupport.other !== null && needHelpOrSupport.other !== '')
      return true;
    return false;
  };

  const handleChange = (event) => {
    setNeedHelpOrSupport({
      ...needHelpOrSupport,
      [event.target.name]: event.target.checked,
    });
  };

  useEffect(() => {
    if (isAuthenticated && profiles && profiles.length > 0) {
      navigate('/dashboard/home');
    }
  }, [isAuthenticated, profiles, navigate]);

  return (
    <OnboardingLayoutOld>
      <Stack direction={'column'} spacing={2} sx={FORM}>
        <Button
          startIcon={<ArrowBack />}
          sx={{ width: 'fit-content' }}
          onClick={() => navigate('/signup/step1')}
        >
          Back
        </Button>
        <Typography sx={{ fontSize: 32, fontWeight: 600, color: 'gray.800' }}>
          Get the Help You Need
        </Typography>
        <Typography sx={{ fontSize: 16, fontWeight: 400, color: 'gray.800' }}>
          {`Hi ${getName()}, where do you need the most support?`}
        </Typography>
        <div></div>
        <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
          <FormLabel
            component="legend"
            sx={{ textAlign: 'start', fontStyle: 'italic' }}
          >
            Check all that apply
          </FormLabel>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={needHelpOrSupport.emotionalSupport}
                  onChange={handleChange}
                  name="emotionalSupport"
                />
              }
              label="Emotional support"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={needHelpOrSupport.findingTreatments}
                  onChange={handleChange}
                  name="findingTreatments"
                />
              }
              label="Finding treatments"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={needHelpOrSupport.managingDailyLife}
                  onChange={handleChange}
                  name="managingDailyLife"
                />
              }
              label="Managing daily life"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={needHelpOrSupport.findingDoctorOrGeneticist}
                  onChange={handleChange}
                  name="findingDoctorOrGeneticist"
                />
              }
              label="Finding a doctor or geneticist"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={needHelpOrSupport.gettingDiagnosis}
                  onChange={handleChange}
                  name="gettingDiagnosis"
                />
              }
              label="Getting a diagnosis"
            />
          </FormGroup>
        </FormControl>
        <TextField
          label="Other"
          fullWidth
          value={needHelpOrSupport.other}
          type={'text'}
          onChange={(e) => {
            setNeedHelpOrSupport({
              ...needHelpOrSupport,
              other: e.target.value,
            });
            track('signup_step_2_selected_help_option', {
              ...needHelpOrSupport,
              other: e.target.value,
            });
          }}
        />
        <LoadingButton
          onClick={() => {
            if (needHelpOrSupport.findingTreatments) {
              navigate('/signup/step3');
              track('signup_step_2_next_button_click');
            } else {
              createProfile();
              track('signup_step_2_finish_button_click');
            }
          }}
          variant="contained"
          size="large"
          disabled={!canContinue()}
          loading={loading}
        >
          Next
        </LoadingButton>
      </Stack>
    </OnboardingLayoutOld>
  );
};

export default SignupStep2;
