import { CircularProgress, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useResponsive from '../hooks/useResponsive';

const Learn = () => {
  useEffect(() => {}, []);
  const isDesktop = useResponsive('up', 'md');

  const [eventDialogIsOpen, setEventDialogIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  return (
    <Container disableGutters maxWidth={false} sx={{ mb: 3 }}>
      {loading && (
        <div
          style={{
            position: 'fixed',
            left: 0,
            top: 0,
            width: '100vw',
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )}
      <iframe
        style={{
          width: '100%',
          height: isDesktop ? 'calc(100vh - 64px)' : 'calc(100vh - 184px)',
          marginTop: isDesktop ? -24 : 56,
          border: 'none',
        }}
        src="https://knowrare.com/blog"
        onLoad={() => setLoading(false)}
      />
    </Container>
  );
};

export default Learn;
