import { Box, Stack, Typography } from '@mui/material';
import React, { useContext } from 'react';
import { ProfileContext } from '../../contexts/Profile';
import { EVENTS } from '../AddOrEditEventDialog';

const CardFooter = ({ event, fromDialog = false }) => {
  const { customEvents } = useContext(ProfileContext);

  const getEventById = (id = '123') => {
    return [...EVENTS, ...customEvents].find((e) => e.id === id);
  };

  return (
    <Stack
      direction={'row'}
      spacing={1.5}
      alignItems={'center'}
      style={{ marginTop: 0 }}
      sx={{
        width: '100%',
        display: 'flex',
        pt: 2,
        borderTop: '1px solid #E0E0E0',
      }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={1}
        sx={{ flexWrap: 'wrap', rowGap: 1 }}
      >
        {event.data().emoji && (
          <Box
            sx={{
              height: 26,
              width: 26,
              bgcolor: '#EBEBEB',
              borderRadius: 0.5,
              fontSize: 16,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {event.data().emoji}
          </Box>
        )}
        {event.data().events.map((e, i) => (
          <Box
            key={i}
            sx={{
              // bgcolor: getEventById(e)?.color,
              bgcolor: '#EBEBEB',
              pl: 1.5,
              pr: 1.5,
              pt: 0.5,
              pb: 0.5,
              borderRadius: 0.5,
              height: 26,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{
                // color: getEventById(e)?.textColor,
                color: '#0C3954',
                fontWeight: 600,
                fontSize: 10,
              }}
            >
              {getEventById(e)?.name}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default CardFooter;
