import { AddCircleOutline } from '@mui/icons-material';
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import DashboardSectionHeader from '../components/DashboardSectionHeader';
import FilterJournalEventsDialog from '../components/FilterJournalEventsDialog';
import JournalItems from '../components/JournalItems';
import { EventsContext } from '../contexts/EventsContext';
import useResponsive from '../hooks/useResponsive';
import { track } from '../utils/analytics';

const Journal = () => {
  useEffect(() => {}, []);
  const isDesktop = useResponsive('up', 'md');

  const { events, eventDialogIsOpen, setEventDialogIsOpen, openEventDialog } =
    useContext(EventsContext);

  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [selectedEvents, setSelectedEvents] = React.useState([]);

  return (
    <Container disableGutters maxWidth={false} sx={{ mb: 3 }}>
      <FilterJournalEventsDialog
        open={openFilterDialog}
        setOpen={setOpenFilterDialog}
        selectedEvents={selectedEvents}
        setSelectedEvents={setSelectedEvents}
      />
      <Stack
        direction={'column'}
        alignItems={'start'}
        spacing={2}
        sx={{ height: '100%', pt: isDesktop ? 0 : 3 }}
      >
        <DashboardSectionHeader
          title="Journal Entries"
          describtion="Here are all the latest entries about what’s happening in your life"
          action={
            <Button
              variant="outlined"
              sx={{
                borderRadius: 3,
              }}
              onClick={() => setOpenFilterDialog(true)}
            >
              Filter Topics
            </Button>
          }
        />
        {events && events.length === 0 && (
          <Grid
            container
            rowSpacing={!isDesktop ? 2 : 0}
            columnSpacing={isDesktop ? 2 : 0}
            disableGutters
            sx={{
              width: '100%',
              mt: 2,
              pl: isDesktop ? 0 : 2,
              pr: isDesktop ? 0 : 2,
            }}
          >
            <Grid item sm={12} md={4} xl={4} xs={12}>
              <Card
                sx={{
                  width: '100%',
                  height: 231,
                  bgcolor: 'primary.main',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  cursor: 'pointer',
                  // mt: 2,
                }}
                elevation={4}
                onClick={() => {
                  setEventDialogIsOpen(true);
                  track('add_journal_button_click');
                }}
              >
                <AddCircleOutline
                  sx={{ width: 32, height: 32, color: 'white' }}
                />
                <Typography
                  sx={{ color: 'white', fontSize: 16, fontWeight: 600, mt: 1 }}
                >
                  Add to
                </Typography>
                <Typography
                  sx={{ color: 'white', fontSize: 16, fontWeight: 600 }}
                >
                  Journal
                </Typography>
              </Card>
            </Grid>
            <Grid item sm={12} md={8} xl={8} xs={12}>
              <Card
                elevation={4}
                sx={{
                  height: isDesktop ? 231 : 'auto',
                  width: '100%',
                  flexGrow: 1,
                  p: isDesktop ? 0 : 2,
                }}
              >
                <Stack
                  direction={isDesktop ? 'row' : 'column'}
                  sx={{ width: '100%' }}
                  alignItems="center"
                  justifyContent={'space-between'}
                  flexDirection={isDesktop ? 'row' : 'column-reverse'}
                >
                  <Stack
                    direction={'column'}
                    sx={{ p: 4, maxWidth: 400 }}
                    spacing={1}
                  >
                    <Typography
                      sx={{
                        textAlign: isDesktop ? 'start' : 'center',
                        fontSize: 16,
                        fontWeight: 600,
                      }}
                    >
                      Create your first journal entry
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: isDesktop ? 'start' : 'center',
                        fontSize: 14,
                        fontWeight: 400,
                      }}
                    >
                      There are not yet any journal entries, you can start one
                      by clicking on the button “Add to Journal”
                    </Typography>
                  </Stack>
                  <img
                    src={require(isDesktop
                      ? '../assets/images/blankStateMobile.png'
                      : '../assets/images/blankStateDesktop.png')}
                    style={{ height: isDesktop ? 231 : 180 }}
                    alt=""
                  />
                </Stack>
              </Card>
            </Grid>
          </Grid>
        )}
        <JournalItems
          events={events}
          setEventDialogIsOpen={setEventDialogIsOpen}
          selectedEvents={selectedEvents}
        />
      </Stack>
    </Container>
  );
};

export default Journal;
