import firebase from '../Firebase';

const db = firebase.firestore();

const getEvents = async (profileId) => {
  try {
    const events = await db
      .collection('events')
      .where('profileId', '==', profileId)
      .orderBy('submittedAt', 'desc')
      .get();
    return events.docs;
  } catch (error) {
    console.log('error', error);
    return [];
  }
};

const createNewCustomEvent = async (profileId, type) => {
  try {
    return db
      .collection('customEvents')
      .add({
        profileId,
        type,
        createdAt: firebase.firestore.Timestamp.now(),
      })
      .then((res) => res)
      .catch((err) => console.log('err', err));
  } catch (error) {
    console.log('error', error);
  }
};

const getCustomEvents = async (id) => {
  try {
    return (
      await db.collection('customEvents').where('profileId', '==', id).get()
    ).docs;
  } catch (error) {
    console.log('error', error);
  }
};

export { createNewCustomEvent, getCustomEvents, getEvents };
