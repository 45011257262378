import { Stack, Typography } from '@mui/material';
import * as React from 'react';
import CustomizedDialogs from './Dialog';

const BIO = `Tali is an educator, advocate, makeup artist, and founding board member of the Israel Chapter of the Dravet Syndrome Foundation. She lives in Givat Shmuel, Israel with her husband and 4 daughters. Tali's youngest daughter, Sarena, was diagnosed with Dravet Syndrome at a year old. Since then Tali has become an advocate for Dravet Syndrome Awareness. When Tali is not fighting for Sarena and her other girls, or supporting other Rare parents in their journey, she can be found browsing the aisles of Sephora or Ulta.${''}`;

export default function BioDialog({ open, setOpen }) {
  return (
    <>
      <CustomizedDialogs
        title={`Tali's Bio${''}`}
        open={open}
        setOpen={setOpen}
      >
        <Stack spacing={2}>
          <Typography>{BIO}</Typography>
        </Stack>
      </CustomizedDialogs>
    </>
  );
}
