import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import * as React from 'react';
import { EventsContext } from '../contexts/EventsContext';
import { ProfileContext } from '../contexts/Profile';
import CustomizedDialogs from './Dialog';

// eslint-disable-next-line react/prop-types
export default function FilterJournalEventsDialog({ open, setOpen }) {
  const { customEvents } = React.useContext(ProfileContext);
  const { setSelectedEvents, events } = React.useContext(EventsContext);
  const [tempSelectedEvents, setTempSelectedEvents] = React.useState([]);

  const onEventClick = (id) => {
    const array = tempSelectedEvents;
    if (array.includes(id)) {
      const index = array.indexOf(id);
      if (index !== -1) {
        array.splice(index, 1);
      }
    } else {
      array.push(id);
    }
    setTempSelectedEvents([...array]);
  };

  const getEventTags = () => {
    const uniqueEvents = [];
    events.forEach((event) => {
      const eventsIds = event.data().events;
      eventsIds?.forEach((eventId) => {
        if (!uniqueEvents.includes(eventId)) {
          uniqueEvents.push(eventId);
        }
      });
    });
    const eventTags = uniqueEvents?.map((id) => {
      return [...EVENTS, ...customEvents].find((e) => e.id === id);
    });
    return eventTags.filter((e) => e);
  };

  return (
    <>
      <CustomizedDialogs
        title={'Choose Topics to Filter'}
        open={open}
        setOpen={setOpen}
      >
        <Stack direction={'column'} spacing={1}>
          <Stack
            spacing={0}
            rowGap={1.5}
            direction={'row'}
            sx={{ flexWrap: 'wrap' }}
            justifyContent={'start'}
          >
            {getEventTags().map((event, _index) => (
              <Button
                variant="contained"
                onClick={() => onEventClick(event.id)}
                sx={{
                  'bgcolor': tempSelectedEvents.includes(event.id)
                    ? event.color
                    : 'transparent',
                  'border': `2px solid ${event.color}`,
                  'boxShadow': 'none',
                  'color': tempSelectedEvents.includes(event.id)
                    ? event.textColor
                    : 'black',
                  'borderRadius': 5,
                  'mr': 1.5,
                  ':hover': {
                    bgcolor: event.color,
                    color: event.textColor,
                  },
                }}
                key={event.id}
              >
                {event.name}
              </Button>
            ))}
          </Stack>
          <div></div>
          <div></div>
          <Stack direction={'column'} spacing={2}>
            <LoadingButton
              variant="contained"
              sx={{ bgcolor: 'primary.main', color: 'white' }}
              onClick={() => {
                setSelectedEvents(tempSelectedEvents);
                setOpen(false);
              }}
            >
              Filter Topics
            </LoadingButton>
            <LoadingButton
              variant="default"
              sx={{ color: 'primary.main' }}
              onClick={() => {
                setSelectedEvents([]);
                setTempSelectedEvents([]);
                setOpen(false);
              }}
            >
              Clear Filter
            </LoadingButton>
          </Stack>
        </Stack>
      </CustomizedDialogs>
    </>
  );
}

export const EVENTS = [
  {
    id: 'hs1zIKDX',
    type: 'pain',
    name: 'Pain',
    textColor: 'black',
    icon: require('../assets/optionsIcons/painIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/painIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearPainIcon.png'),
    color: '#F5CAC3',
  },
  {
    id: 'hhPAPZbT',
    type: 'mood',
    name: 'Mood',
    textColor: 'black',
    icon: require('../assets/optionsIcons/moodIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/moodIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearMoodIcon.png'),
    color: '#F9DFDB',
  },
  {
    id: 'YerSkutN',
    type: 'symptoms',
    name: 'Symptoms',
    textColor: 'black',
    icon: require('../assets/optionsIcons/symptomsIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/symptomsIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearSymptomsIcon.png'),
    color: '#F9DFDB',
  },
  {
    id: 'jv7AyZRE',
    type: 'procedures',
    name: 'Procedures',
    textColor: 'white',
    icon: require('../assets/optionsIcons/proceduresIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/proceduresIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearProceduresIcon.png'),
    color: '#8963BA',
  },
  {
    id: 'ibkHXKme',
    type: 'hospitalVisit',
    name: 'Hospital Visit',
    textColor: 'white',
    icon: require('../assets/optionsIcons/hospitalVisitIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/hospitalizationIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearHospitalIcon.png'),
    color: '#8963BA',
  },
  {
    id: 'hZS4yw4R',
    type: 'doctorVisit',
    name: 'Doctor Visit',
    textColor: 'white',
    icon: require('../assets/optionsIcons/doctorVisitIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/doctorVisitIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearDoctorIcon.png'),
    color: '#A182C8',
  },
  {
    id: 'oHNP8mRN',
    type: 'medications',
    name: 'Medications',
    textColor: 'white',
    icon: require('../assets/optionsIcons/medicationIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/medIssueIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearMedicationIcon.png'),
    color: '#B8A1D6',
  },
  {
    id: 'Q7dWiUqG',
    type: 'note',
    name: 'Note',
    textColor: 'black',
    icon: require('../assets/optionsIcons/noteIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/noteIcon.png'),
    clearIconWithBackground: require('../assets/optionsIcons/clearIconsWithBackground/clearNoteIcon.png'),
    color: '#A5D1EE',
  },
];

export const ASSESSMENTS_IDS = ['C7NnGZyA', 'FMkEt9ok'];

export const ASSESSMENTS = [
  {
    id: 'C7NnGZyA',
    type: 'emotionalHealth',
    name: 'Emotional Health',
    icon: require('../assets/optionsIcons/symptomsIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/symptomsIcon.png'),
    color: '#F4FF72',
    totalScore: 27,
  },
  {
    id: 'FMkEt9ok',
    type: 'fatigue',
    name: 'Fatigue',
    icon: require('../assets/optionsIcons/medIssueIcon.png'),
    clearIcon: require('../assets/optionsIcons/clearIcons/medIssueIcon.png'),
    color: '#CEF5E3',
    totalScore: 52,
  },
];
