import { AddRounded, Edit } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import React, { useState } from 'react';
import { ProfileContext } from '../contexts/Profile';
import AddCustomTagDialog from './AddCustomTagDialog';
import CustomizedDialogs from './Dialog';

const LABEL = {
  fontSize: 16,
  color: 'black',
  fontWeight: 600,
};

const EditCustomTagsDialog = ({ open, setOpen }) => {
  const { fetchCustomEvents, customEvents } = React.useContext(ProfileContext);
  const [openNewTagDialog, setOpenNewTagDialog] = React.useState(false);
  const [tag, setTag] = useState(null);

  React.useEffect(() => {
    if (openNewTagDialog === false) {
      fetchCustomEvents();
    }
  }, [openNewTagDialog]);

  return (
    <CustomizedDialogs open={open} setOpen={setOpen} title={'Edit custom tags'}>
      <AddCustomTagDialog
        open={openNewTagDialog}
        setOpen={setOpenNewTagDialog}
        tag={tag}
      />
      <Stack
        spacing={0}
        rowGap={1.5}
        direction={'row'}
        sx={{ flexWrap: 'wrap' }}
        justifyContent={'start'}
      >
        {[...customEvents].map((event, _index) => (
          <Button
            variant="contained"
            sx={{
              'bgcolor': 'transparent',
              'border': `2px solid ${event.color}`,
              'boxShadow': 'none',
              'color': 'black',
              'borderRadius': 5,
              'mr': 1.5,
              ':hover': {
                bgcolor: event.color,
                color: event.textColor,
              },
            }}
            key={event.id}
            onClick={() => {
              setTag(event);
              setOpenNewTagDialog(true);
            }}
          >
            <Stack
              direction={'row'}
              spacing={1}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <div>{event.name}</div>
              <Edit sx={{ width: 16, height: 16 }} />
            </Stack>
          </Button>
        ))}
        <Button
          variant="contained"
          onClick={() => setOpenNewTagDialog(true)}
          sx={{
            'bgcolor': 'transparent',
            'border': '2px solid #000000',
            'boxShadow': 'none',
            'color': 'black',
            'borderRadius': 5,
            'mr': 1.5,
            ':hover': {
              bgcolor: 'black',
              color: 'white',
            },
          }}
        >
          <AddRounded />
        </Button>
      </Stack>
    </CustomizedDialogs>
  );
};

export default EditCustomTagsDialog;
