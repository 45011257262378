import { Card, Stack } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { ProfileContext } from '../contexts/Profile';
import useResponsive from '../hooks/useResponsive';
import AddOrEditEventDialog from './AddOrEditEventDialog';
import EventHeader from './EventHeader';
import NewEventHeader from './NewEventHeader';
import { PostSurveyDialog } from './SurveyCard';

const EventCard = ({ event, list = false }) => {
  const isDesktop = useResponsive('up', 'md');

  const { role } = useContext(ProfileContext);

  const [openEditEventDialog, setOpenEditEventDialog] = useState(false);
  const [openPostSurveyDialog, setOpenPostSurveyDialog] = useState(false);

  useEffect(() => {}, []);

  return (
    <>
      <PostSurveyDialog
        open={openPostSurveyDialog}
        setOpen={setOpenPostSurveyDialog}
        event={event || null}
      />
      {event.data().format === 'new' && openEditEventDialog && (
        <AddOrEditEventDialog
          open={openEditEventDialog}
          setOpen={setOpenEditEventDialog}
          event={event}
        />
      )}
      <Card
        sx={{
          width: list ? '100%' : isDesktop ? 327 : '100%',
          height: 231,
          p: 2,
          mr: list ? 0 : 2,
          cursor: 'pointer',
          borderRadius: 3,
        }}
        onClick={() => {
          if (role === 'viewer') {
            alert('You are only a viewer of this profile');
          } else {
            if (event.data().format === 'new') {
              setOpenEditEventDialog(true);
            } else {
              setOpenPostSurveyDialog(true);
            }
          }
        }}
        elevation={4}
      >
        {event.data().format === 'new' ? (
          <Stack direction={'row'} spacing={4} alignItems={'center'}>
            <NewEventHeader event={event} />
          </Stack>
        ) : (
          <Stack direction={'row'} spacing={4} alignItems={'center'}>
            <EventHeader event={event} />
          </Stack>
        )}
      </Card>
    </>
  );
};

export default EventCard;
