import { Box, Stack } from '@mui/material';
import React from 'react';
import { ASSESSMENTS, ASSESSMENTS_IDS, EVENTS } from '../AddOrEditEventDialog';

const CardFooter = ({ event, fromDialog = false }) => {
  const getAssesmentName = (item) => {
    let SEARCH_SOURCE = EVENTS;
    if (ASSESSMENTS_IDS.includes(item.data().formId))
      SEARCH_SOURCE = ASSESSMENTS;
    for (let index = 0; index < SEARCH_SOURCE.length; index++) {
      const event = SEARCH_SOURCE[index];
      if (event.id === item.data().formId) {
        return event.name;
      }
    }
  };

  const getSurveyScore = (event) => {
    if (!event) return 0;
    if (ASSESSMENTS_IDS.includes(event.data().formId)) {
      return event?.data()?.response.form_response.calculated.score;
    }
  };

  return (
    <Stack
      direction={'row'}
      spacing={1.5}
      alignItems={'center'}
      sx={{
        width: '100%',
        display: 'flex',
        pt: 2,
        borderTop: '1px solid #E0E0E0',
      }}
      style={{ marginTop: 0 }}
    >
      <Stack
        direction={'row'}
        alignItems={'center'}
        spacing={1}
        sx={{ flexWrap: 'wrap', rowGap: 1 }}
      >
        <Box
          sx={{
            height: 26,
            bgcolor: '#EBEBEB',
            borderRadius: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#0C3954',
            fontWeight: 600,
            fontSize: 10,
            pl: 1.5,
            pr: 1.5,
            pt: 0.5,
            pb: 0.5,
          }}
        >
          {getSurveyScore(event)}
        </Box>
        <Box
          sx={{
            height: 26,
            bgcolor: '#EBEBEB',
            borderRadius: 0.5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#0C3954',
            fontWeight: 600,
            fontSize: 10,
            pl: 1.5,
            pr: 1.5,
            pt: 0.5,
            pb: 0.5,
          }}
        >
          {getAssesmentName(event)}
        </Box>
      </Stack>
    </Stack>
  );
};

export default CardFooter;
