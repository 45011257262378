import { LoadingButton } from '@mui/lab';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import firebase from '../Firebase';
import { AuthContext } from '../contexts/Auth';
import { ProfileContext } from '../contexts/Profile';
import { ProfilesContext } from '../contexts/Profiles';
import { SnackbarContext } from '../contexts/Snackbar';
import { track } from '../utils/analytics';
import Conditions from './Conditions';
import ConditionsStoryCatch from './ConditionsStoryCatch';
import CustomizedDialogs from './Dialog';

const LABEL = {
  fontSize: 16,
  color: 'black',
  fontWeight: 600,
};

const EditProfileDialog = ({ open, setOpen }) => {
  const { currentUser } = useContext(AuthContext);
  const { setProfiles, getProfiles } = useContext(ProfilesContext);
  const { setCurrentProfile, currentProfile } = useContext(ProfileContext);
  const { show } = useContext(SnackbarContext);
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState(null);
  const [conditions, setConditions] = useState(null);
  const [age, setAge] = useState(null);
  const [sex, setSex] = useState(null);
  const [name, setName] = useState(null);
  const [allowSave, setAllowSave] = useState(false);
  const [phone, setPhone] = useState('');
  const [organization, setOrganization] = useState('');

  useEffect(() => {
    if (currentProfile) {
      if (
        phone !== currentProfile.data().phone ||
        // location.description !== currentProfile.data().location.description ||
        JSON.stringify(conditions) !==
          JSON.stringify(currentProfile.data().conditions) ||
        name !== currentProfile.data().name ||
        age !== currentProfile.data()?.age ||
        sex !== currentProfile.data()?.sex ||
        organization !== currentProfile.data()?.organization
      ) {
        setAllowSave(true);
      } else {
        setAllowSave(false);
      }
    }
  }, [location, conditions, name, phone, sex, age, organization]);

  useEffect(() => {
    if (currentProfile) {
      // setLocation(currentProfile.data().location);
      setConditions(currentProfile.data().conditions || []);
      setName(currentProfile.data().name);
      setSex(currentProfile.data()?.sex);
      setAge(currentProfile.data()?.age);
      setOrganization(currentProfile.data()?.organization);
      setPhone(currentProfile.data().phone || '');
    } else {
      getProfiles(currentUser);
    }
  }, [currentProfile]);

  const updateProfile = async (newData) => {
    track('update_profile_button_click');
    try {
      if (setLoading) setLoading(true);
      await firebase
        .firestore()
        .collection('profiles')
        .doc(currentProfile.id)
        .update({
          ...newData,
          lastUpdated: firebase.firestore.Timestamp.now(),
        });
      const profiles = (
        await firebase
          .firestore()
          .collection('profiles')
          .where('createdBy', '==', currentUser.uid)
          .get()
      ).docs;
      setProfiles(profiles);
      setCurrentProfile(profiles[0]);
      if (setLoading) setLoading(false);
      show('Profile updated successfully', 'success');
    } catch (error) {
      setLoading(false);
      alert(`updateProfile - ${error.message}`);
    }
  };

  const onChangePhone = (e) => {
    setPhone(
      e.replace(/-/g, '').replace('(', '').replace(')', '').replace(/ /g, ''),
    );
  };

  return (
    <CustomizedDialogs open={open} setOpen={setOpen} title={'Edit profile'}>
      <Stack
        spacing={2}
        direction={'column'}
        justifyContent={'start'}
        sx={{ mt: 2, pb: 2 }}
      >
        <TextField
          onChange={(e) => setName(e.target.value)}
          value={name}
          label="Full name"
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel id="sex-select-label">
            What is your biological sex?
          </InputLabel>
          <Select
            labelId="sex-select-label"
            id="sex-select"
            value={sex}
            label="What is your biological sex?"
            onChange={(e) => setSex(e.target.value)}
            sx={{ textAlign: 'start' }}
          >
            <MenuItem value={'male'}>Male</MenuItem>
            <MenuItem value={'female'}>Female</MenuItem>
            <MenuItem value={'none'}>Prefer not to say</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="How old are you?"
          value={age}
          type="number"
          onChange={(e) => setAge(e.target.value)}
        />
        {/* <LocationTextField value={location} setValue={setLocation} /> */}
        {organization === 'Story Catch' ? (
          <ConditionsStoryCatch
            value={conditions || []}
            setValue={setConditions}
          />
        ) : (
          <Conditions value={conditions || []} setValue={setConditions} />
        )}
        {/* <MuiPhoneNumber
          style={{ width: '100%' }}
          name="phone"
          data-cy="user-phone"
          defaultCountry={'us'}
          disableAreaCodes={true}
          label={'Phone number'}
          value={phone}
          variant={'outlined'}
          onChange={onChangePhone}
        /> */}
        <FormControl fullWidth>
          <InputLabel id="sex-select-label">
            Are you signing up through a organization? (Optional)
          </InputLabel>
          <Select
            labelId="sex-select-label"
            id="sex-select"
            value={organization}
            label="Are you signing up through a organization? (Optional)"
            onChange={(e) => setOrganization(e.target.value)}
            sx={{ textAlign: 'start' }}
          >
            <MenuItem value={''}>None</MenuItem>
            <MenuItem value={'Story Catch'}>SCP Testing</MenuItem>
          </Select>
        </FormControl>
        <LoadingButton
          onClick={() =>
            updateProfile({
              sex,
              age: age || '',
              conditions,
              name,
              phone,
              organization: organization || '',
            })
          }
          variant="contained"
          loading={loading}
          disabled={!allowSave}
        >
          Update profile
        </LoadingButton>
      </Stack>
    </CustomizedDialogs>
  );
};

export default EditProfileDialog;
