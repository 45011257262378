import { ArrowBackIosNew } from '@mui/icons-material';
import {
  AppBar,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';
import ProgressBar from '../components/ProgressBar';
import { ProfileContext } from '../contexts/Profile';

const Progress = () => {
  const { level } = useContext(ProfileContext);

  return (
    <Container
      disableGutters
      maxWidth={'lg'}
      sx={{
        p: 3,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AppBar position="fixed" sx={{ height: 56, bgcolor: 'white' }}>
        <Toolbar>
          <IconButton
            size="small"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              window.history.back();
            }}
            sx={{ mr: 2, display: { sm: 'none' }, color: 'black' }}
          >
            <ArrowBackIosNew />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: 'start',
              color: 'black',
            }}
          >
            Your Progress
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack
        direction={'column'}
        alignItems={'start'}
        spacing={2}
        sx={{ width: '100%', pt: 8 }}
      >
        <ProgressBar level={level} />
      </Stack>
    </Container>
  );
};

export default Progress;
