import { LoadingButton } from '@mui/lab';
import {
  Box,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import AreasOfLivesSelect from '../components/AreasOfLivesSelect';
import Conditions from '../components/Conditions';
import ConditionsStoryCatch from '../components/ConditionsStoryCatch';
import OnboardingLayoutOld from '../components/OnboardingLayoutOld';
import { AuthContext } from '../contexts/Auth';
import { ProfilesContext } from '../contexts/Profiles';
import { SignupContext } from '../contexts/Signup';

const FORM = {
  width: '100%',
};

const SignupStep1 = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const { profiles, creatingProfile } = useContext(ProfilesContext);

  const {
    forWho,
    setForWho,
    name,
    setName,
    setConditions,
    conditions,
    relationship,
    setRelationship,
    createProfile,
    loading,
    sex,
    setSex,
    age,
    setAge,
    setAfterSignup,
    areasToJournal,
    setAreasToJournal,
    birthDate,
    setBirthDate,
    organization,
  } = useContext(SignupContext);

  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);

  const [invalidName, setInvalidName] = useState(null);
  const [invalidRelationship, setInvalidRelationship] = useState(null);
  const [invalidConditions, setInvalidConditions] = useState(null);
  const [invalidAreasToJournal, setInvalidAreasToJournal] = useState(null);
  const [invalidSex, setInvalidSex] = useState(null);
  const [invalidAge, setInvalidAge] = useState(null);

  const [openDatePicker, setOpenDatePicker] = useState(false);

  const restErrors = () => {
    setInvalidName(null);
    setInvalidRelationship(null);
    setInvalidConditions(null);
    setInvalidAreasToJournal(null);
    setInvalidSex(null);
    setInvalidAge(null);
  };

  const canContinue = () => {
    let dataIsValid = true;
    if (forWho === 1 && name.length < 3) {
      dataIsValid = false;
      setInvalidName('Please enter full name');
    }
    if (forWho === 1 && relationship === null) {
      dataIsValid = false;
      setInvalidRelationship('Please select your relationship to them');
    }
    if (conditions === null) {
      dataIsValid = false;
      setInvalidConditions('Please select at least one condition');
    }
    if (areasToJournal.length === 0) {
      dataIsValid = false;
      setInvalidAreasToJournal('Please select at least one area to journal');
    }
    if (sex === null) {
      dataIsValid = false;
      setInvalidSex('Please select a biological sex');
    }
    if (birthDate === null) {
      dataIsValid = false;
      setInvalidAge('Please enter a valid birth date');
    }
    return dataIsValid;
  };

  const isInitialMount = React.useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      // Skip the first render
      isInitialMount.current = false;
    } else {
      // Run on state change, but not on the initial load
      restErrors();
      canContinue();
    }
  }, [name, relationship, conditions, areasToJournal, sex, birthDate]);

  useEffect(() => {
    if (
      isAuthenticated &&
      profiles &&
      profiles.length > 0 &&
      !creatingProfile
    ) {
      setAfterSignup(true);
      navigate('/dashboard/medications');
    }
  }, [isAuthenticated, profiles, navigate, creatingProfile]);

  return (
    <OnboardingLayoutOld>
      <Stack direction={'column'} spacing={2} sx={FORM}>
        <Typography sx={{ fontSize: 32, fontWeight: 600, color: 'gray.800' }}>
          Start your story now
        </Typography>
        <Typography sx={{ fontSize: 18, fontWeight: 500, color: 'gray.800' }}>
          {forWho === 0
            ? 'Create your profile'
            : 'Create a profile for someone you care about'}
        </Typography>
        <div></div>
        <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
          <Typography
            sx={{
              textAlign: 'start',
              fontSize: 16,
              fontWeight: 500,
              color: '#0B3954',
            }}
          >
            Who is this for?
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={forWho}
              onChange={(e) => setForWho(e.target.value)}
              sx={{ textAlign: 'start' }}
            >
              <MenuItem value={0}>Myself</MenuItem>
              <MenuItem value={1}>Someone I care about</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        {forWho === 1 && (
          <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{
                textAlign: 'start',
                fontSize: 16,
                fontWeight: 500,
                color: '#0B3954',
              }}
            >
              Name of the person you care about?
            </Typography>
            <TextField value={name} onChange={(e) => setName(e.target.value)} />
            {invalidName && (
              <Typography
                sx={{ color: 'red', textAlign: 'start', fontSize: 14 }}
              >
                {invalidName}
              </Typography>
            )}
          </Stack>
        )}
        {forWho === 1 && (
          <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
            <Typography
              sx={{
                textAlign: 'start',
                fontSize: 16,
                fontWeight: 500,
                color: '#0B3954',
              }}
            >
              What is your relationship to them?
            </Typography>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={relationship}
                onChange={(e) => setRelationship(e.target.value)}
                sx={{ textAlign: 'start' }}
              >
                <MenuItem value={'family'}>Family</MenuItem>
                <MenuItem value={'friend'}>Friend</MenuItem>
                <MenuItem value={'doctor'}>Doctor</MenuItem>
              </Select>
            </FormControl>
            {invalidRelationship && (
              <Typography
                sx={{ color: 'red', textAlign: 'start', fontSize: 14 }}
              >
                {invalidRelationship}
              </Typography>
            )}
          </Stack>
        )}
        <AreasOfLivesSelect
          forWho={forWho}
          value={areasToJournal || []}
          setValue={setAreasToJournal}
          error={invalidAreasToJournal}
        />
        {organization === 'Story Catch' ? (
          <ConditionsStoryCatch
            forWho={forWho}
            value={conditions || []}
            setValue={setConditions}
            error={invalidConditions}
          />
        ) : (
          <Conditions
            forWho={forWho}
            value={conditions || []}
            setValue={setConditions}
            error={invalidConditions}
          />
        )}
        <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
          <Typography
            sx={{
              textAlign: 'start',
              fontSize: 16,
              fontWeight: 500,
              color: '#0B3954',
            }}
          >
            {forWho === 1
              ? 'What is their biological sex?'
              : 'What is your biological sex?'}
          </Typography>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sex}
              onChange={(e) => setSex(e.target.value)}
              sx={{ textAlign: 'start' }}
            >
              <MenuItem value={'male'}>Male</MenuItem>
              <MenuItem value={'female'}>Female</MenuItem>
              <MenuItem value={'none'}>Prefer not to say</MenuItem>
            </Select>
          </FormControl>
          {invalidSex && (
            <Typography sx={{ color: 'red', textAlign: 'start', fontSize: 14 }}>
              {invalidSex}
            </Typography>
          )}
        </Stack>
        <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
          <Typography
            sx={{
              textAlign: 'start',
              fontSize: 16,
              fontWeight: 500,
              color: '#0B3954',
            }}
          >
            {forWho === 1 ? 'Birth date' : 'Birth date'}
          </Typography>
          {/* <TextField
            value={age}
            type="number"
            onChange={(e) => setAge(e.target.value)}
          /> */}
          <DesktopDatePicker
            value={birthDate}
            onAccept={(v) => setBirthDate(v)}
            hide
            open={openDatePicker}
            onOpen={() => setOpenDatePicker(true)}
            onClose={() => setOpenDatePicker(false)}
            slotProps={{
              textField: {
                onClick: () => setOpenDatePicker(true),
                variant: 'outlined',
                fullWidth: true,
              },
            }}
          />
          {invalidAge && (
            <Typography sx={{ color: 'red', textAlign: 'start', fontSize: 14 }}>
              {invalidAge}
            </Typography>
          )}
        </Stack>
        <LoadingButton
          onClick={() => {
            restErrors();
            if (!canContinue()) return;
            createProfile();
          }}
          variant="contained"
          size="large"
          loading={loading}
        >
          Finish Signup
        </LoadingButton>
        <Stack
          direction={'column'}
          spacing={1}
          sx={{
            p: 2,
            pl: 3,
            pr: 3,
            mt: 3,
            bgcolor: '#CFF5E3',
            textAlign: 'start',
          }}
        >
          <Box sx={{ fontWeight: 600, fontSize: 16 }}>
            Private and secure data
          </Box>
          <Box sx={{ fontSize: 14 }}>
            All data you enter on healthstory is <b>private</b> and{' '}
            <b>not shared</b> with anyone else. Read our{' '}
            <u>
              <a
                style={{ color: 'black' }}
                href="https://www.healthstoryai.com/privacy"
              >
                privacy policy
              </a>
            </u>{' '}
            for more information.
          </Box>
        </Stack>
      </Stack>
    </OnboardingLayoutOld>
  );
};

export default SignupStep1;
