import { AddRounded, EditRounded } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Stack, TextField } from '@mui/material';
import * as React from 'react';
import { HexColorPicker } from 'react-colorful';
import firebase from '../Firebase';
import { AuthContext } from '../contexts/Auth';
import { ProfileContext } from '../contexts/Profile';
import CustomizedDialogs from './Dialog';

// eslint-disable-next-line react/prop-types
export default function AddCustomTagDialog({ open, setOpen, tag = null }) {
  const { currentUser } = React.useContext(AuthContext);
  const { currentProfile } = React.useContext(ProfileContext);
  const [color, setColor] = React.useState('#aabbcc');
  const [loading, setLoading] = React.useState(false);
  const [customTag, setCustomTag] = React.useState('');

  React.useEffect(() => {
    if (tag) {
      setColor(tag.color);
      setCustomTag(tag.name);
    }
  }, [tag]);

  const addCustomTag = async () => {
    setLoading(true);
    await firebase
      .firestore()
      .collection('customEvents')
      .add({
        createdBy: currentUser.uid,
        profileId: currentProfile?.id,
        createdAt: firebase.firestore.Timestamp.now(),
        type: customTag,
        name: customTag,
        color: color,
        textColor: getContrastColor(color),
      });
    setCustomTag('');
    setOpen(false);
    setLoading(false);
  };

  const editCustomTag = async () => {
    setLoading(true);
    await firebase
      .firestore()
      .collection('customEvents')
      .doc(tag.id)
      .update({
        createdBy: currentUser.uid,
        profileId: currentProfile?.id,
        createdAt: firebase.firestore.Timestamp.now(),
        type: customTag,
        name: customTag,
        color: color,
        textColor: getContrastColor(color),
      });
    setCustomTag('');
    setOpen(false);
    setLoading(false);
  };

  const deleteCustomTag = async () => {
    await firebase.firestore().collection('customEvents').doc(tag.id).delete();
    setCustomTag('');
    setOpen(false);
  };

  const getContrastColor = (hexColor) => {
    // Remove the '#' symbol if present
    const color = hexColor.replace('#', '');

    // Extract the RGB components
    const red = parseInt(color.substr(0, 2), 16);
    const green = parseInt(color.substr(2, 2), 16);
    const blue = parseInt(color.substr(4, 2), 16);

    // Calculate the contrast using the formula
    const contrast = (red * 299 + green * 587 + blue * 114) / 1000;

    // Determine the appropriate text color based on the contrast
    return contrast >= 128 ? '#000000' : '#FFFFFF';
  };

  return (
    <>
      <CustomizedDialogs title={'Add custom tag'} open={open} setOpen={setOpen}>
        <Stack direction={'column'} spacing={3} sx={{ mt: 1 }}>
          <TextField
            label="Custom tag"
            value={customTag}
            onChange={(e) => setCustomTag(e.target.value)}
          />
          <HexColorPicker color={color} onChange={setColor} />
          <LoadingButton
            variant="contained"
            startIcon={tag ? <EditRounded /> : <AddRounded />}
            size="large"
            disabled={customTag.length === 0}
            onClick={() => (tag ? editCustomTag() : addCustomTag())}
            loading={loading}
          >
            {tag ? 'Edit custom tag' : 'Add custom tag'}
          </LoadingButton>
          {tag && (
            <LoadingButton sx={{ color: 'red' }} onClick={deleteCustomTag}>
              Delete custom tag
            </LoadingButton>
          )}
        </Stack>
      </CustomizedDialogs>
    </>
  );
}
