import { Card, Divider, Stack, Typography } from '@mui/material';
import moment from 'moment-timezone';
import React, { useContext } from 'react';
import { EventsContext } from '../../contexts/EventsContext';
import { WalkthroughContext } from '../../contexts/Walkthrough';

const CARD = {
  p: 3,
  bgcolor: '#CFF5E3',
  position: 'relative',
  borderRadius: 3,
  minHeight: { xs: 'fit-content', md: 224 },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
};

const TITLE = {
  fontWeight: 600,
  fontSize: 16,
  color: '#0C3954',
  textAlign: 'center',
};

const SUBTITLE = {
  fontWeight: 500,
  fontSize: 16,
  color: '#0C3954',
  mt: -1,
  textAlign: 'center',
};

const VALUE = {
  fontWeight: 600,
  fontSize: { xs: 36, md: 52 },
  color: '#0C3954',
};

const BG = { position: 'absolute', top: 0, right: 0, width: 100 };

const Stats = () => {
  const { events } = useContext(EventsContext);
  const { run } = useContext(WalkthroughContext);

  const countEventsFromStartOfMonth = () => {
    if (run) return 12;
    if (!events) return '-';
    // Get the current date
    const currentDate = moment();

    // Get the start of the current month
    const startOfMonth = currentDate.startOf('month');

    // Filter events created from the start of the current month
    const eventsFromStartOfMonth = events.filter((event) => {
      const eventDate = moment(event.data().submittedAt.seconds * 1000);
      return eventDate.isSameOrAfter(startOfMonth);
    });

    // Return the count of events
    return eventsFromStartOfMonth.length;
  };

  return (
    <Card sx={CARD}>
      <Stack
        direction={'row'}
        justifyContent={'space-around'}
        alignItems={'center'}
      >
        <Stack
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ mr: -5 }}
          spacing={0}
        >
          <Typography sx={VALUE}>{countEventsFromStartOfMonth()}</Typography>
          <Stack direction={'column'} alignItems={'center'}>
            <Typography sx={TITLE}>{moment().format('MMM')}</Typography>
            <Typography sx={SUBTITLE}>Entries</Typography>
          </Stack>
        </Stack>
        <Divider orientation="vertical" flexItem />
        <Stack
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          sx={{ ml: -5 }}
          spacing={0}
        >
          <Typography sx={VALUE}>{run ? 52 : events.length}</Typography>
          <Stack direction={'column'} alignItems={'center'}>
            <Typography sx={TITLE}>Total</Typography>
            <Typography sx={SUBTITLE}>Entries</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default Stats;
