import { Box, Hidden, Stack, Typography } from '@mui/material';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import useResponsive from '../hooks/useResponsive';
import { ASSESSMENTS, ASSESSMENTS_IDS, EVENTS } from './AddOrEditEventDialog';
import Mood1 from './icons/Mood1';
import Mood2 from './icons/Mood2';
import Mood3 from './icons/Mood3';
import Mood4 from './icons/Mood4';
import Mood5 from './icons/Mood5';

const EventHeader = ({ event, fromDialog = false }) => {
  const isDesktop = useResponsive('up', 'md');
  const [openEventDialog, setOpenEventDialog] = useState(false);
  useEffect(() => {}, []);

  const getBackgroundColorBaseOnFormID = (formId) => {
    let SEARCH_SOURCE = EVENTS;
    if (ASSESSMENTS_IDS.includes(formId)) SEARCH_SOURCE = ASSESSMENTS;
    // if (ASSESSMENTS_IDS.includes(formId)) return '';
    for (let index = 0; index < SEARCH_SOURCE.length; index++) {
      const event = SEARCH_SOURCE[index];
      if (event.id === formId) {
        return event.color;
      }
    }
  };

  const getEventTextColor = (formId) => {
    let SEARCH_SOURCE = EVENTS;
    if (ASSESSMENTS_IDS.includes(formId)) SEARCH_SOURCE = ASSESSMENTS;
    for (let index = 0; index < SEARCH_SOURCE.length; index++) {
      const event = SEARCH_SOURCE[index];
      if (event.id === formId) {
        return event.textColor || 'black';
      }
    }
  };

  const getAssesmentName = (item) => {
    let SEARCH_SOURCE = EVENTS;
    if (ASSESSMENTS_IDS.includes(item.data().formId))
      SEARCH_SOURCE = ASSESSMENTS;
    for (let index = 0; index < SEARCH_SOURCE.length; index++) {
      const event = SEARCH_SOURCE[index];
      if (event.id === item.data().formId) {
        return event.name;
      }
    }
  };

  const getSurveyScore = (event) => {
    let SEARCH_SOURCE = EVENTS;
    if (ASSESSMENTS_IDS.includes(item.data().formId))
      SEARCH_SOURCE = ASSESSMENTS;
    return event?.data().response.form_response.calculated.score;
  };

  const getTotalScoreForAssesment = (item) => {
    let SEARCH_SOURCE = EVENTS;
    if (ASSESSMENTS_IDS.includes(item.data().formId))
      SEARCH_SOURCE = ASSESSMENTS;
    for (let index = 0; index < SEARCH_SOURCE.length; index++) {
      const event = SEARCH_SOURCE[index];
      if (event.id === item.data().formId) {
        return event.totalScore;
      }
    }
  };

  const getName = (formId) => {
    // if (ASSESSMENTS_IDS.includes(event.data().formId))
    //   return getAssesmentName(event);
    if (ASSESSMENTS_IDS.includes(event.data().formId)) return 'Survey';
    for (let index = 0; index < EVENTS.length; index++) {
      const event = EVENTS[index];
      if (event.id === formId) {
        return event.name;
      }
    }
  };

  const getTitle = (item) => {
    if (ASSESSMENTS_IDS.includes(event.data().formId))
      return getAssesmentName(event);
    if (item.data().formId === 'KA1Dl1qR') {
      const text = item.data().response.form_response.hidden.type;
      const emoji = item.data().answers[0].choice.label.split(' ')[0];
      return emoji + text;
    } else {
      const text = item.data().answers[0].text;
      let emoji = '';
      if (item.data().answers[1].type === 'choice') {
        if (item.data().answers[1].choice.label === '😔 terrible') {
          emoji += '😔 ';
        } else if (item.data().answers[1].choice.label === '😕 bad') {
          emoji += '😕 ';
        } else if (item.data().answers[1].choice.label === '😐 ok') {
          emoji += '😐  ';
        } else if (item.data().answers[1].choice.label === '🙂 good') {
          emoji += '🙂 ';
        } else if (item.data().answers[1].choice.label === '😀 great') {
          emoji += '😀 ';
        }
      }
      // return emoji + text;
      if (text.length > 90 && !fromDialog) {
        return `${text.slice(90)}...`;
      }
      return text;
    }
  };

  const getMode = (item = event) => {
    if (item.data().formId === 'KA1Dl1qR') {
      return <></>;
    } else {
      if (item.data().answers[1].type === 'choice') {
        if (item.data().answers[1].choice.label === '😔 terrible') {
          return <Mood1 size={18} />;
        } else if (item.data().answers[1].choice.label === '😕 bad') {
          return <Mood2 size={18} />;
        } else if (item.data().answers[1].choice.label === '😐 ok') {
          return <Mood3 size={18} />;
        } else if (item.data().answers[1].choice.label === '🙂 good') {
          return <Mood4 size={18} />;
        } else if (item.data().answers[1].choice.label === '😀 great') {
          return <Mood5 size={18} />;
        }
      }
      return <></>;
    }
  };

  const getDescription = (item) => {
    let text = '';
    if (ASSESSMENTS_IDS.includes(item.data().formId)) {
      return `You scored 
      ${item.data().response.form_response.calculated.score}
      / ${getTotalScoreForAssesment(item)}`;
    }
    if (item.data().formId === 'KA1Dl1qR') {
      if (item.data().answers.length > 1) {
        if (item.data().answers[1].type === 'text') {
          text = item.data().answers[1].text;
        }
      }
    } else {
      if (item.data().answers.length > 2) {
        if (item.data().answers[2].type === 'text') {
          text = item.data().answers[2].text;
        }
      }
    }
    if (text.length > 90 && !fromDialog) {
      return `${text.slice(90)}...`;
    }
    return text;
  };

  return (
    <Stack
      direction={'column'}
      spacing={1}
      alignItems={'start'}
      sx={{ width: '100%' }}
    >
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        sx={{ width: '100%', display: 'flex', height: 32 }}
      >
        {getMode()}
        <Typography sx={{ textAlign: 'left', fontSize: 14 }}>
          {moment(event.data().submittedAt.seconds * 1000).format('D MMM, YY')}
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        <Box
          sx={{
            pl: 1,
            pr: 1,
            pt: 0.5,
            pb: 0.5,
            bgcolor: getBackgroundColorBaseOnFormID(event.data().formId),
            borderRadius: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              color: getEventTextColor(event.data().formId),
            }}
          >
            {getName(event.data().formId)}
          </Typography>
        </Box>
      </Stack>
      <Typography
        sx={{
          textAlign: 'left',
          color: '#0B3954',
          fontSize: 24,
          fontWeight: 600,
        }}
      >
        {getTitle(event)}
      </Typography>
      {true ? (
        <Typography
          sx={{
            textAlign: 'left',
            color: '#0B3954',
            fontSize: 16,
            fontWeight: 500,
            maxWidth: 480,
          }}
        >
          {getDescription(event)}
        </Typography>
      ) : (
        <Hidden smDown>
          <Typography
            sx={{
              textAlign: 'left',
              color: '#0B3954',
              fontSize: 16,
              fontWeight: 500,
              maxWidth: 480,
            }}
          >
            {getDescription(event)}
          </Typography>
        </Hidden>
      )}
    </Stack>
  );
};

export default EventHeader;
