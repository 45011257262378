import { Container, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import React from 'react';

const BACKGROUND = {
  width: '100vw',
  height: '100vh',
  bgcolor: 'white',
  position: 'fixed',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  top: 0,
  left: 0,
  zIndex: 9999999,
};

const Loader = () => {
  return (
    <Container sx={BACKGROUND} maxWidth={false} disableGutters>
      <Lottie
        animationData={require('../assets/lotties/initialLoading.json')}
        loop={true}
        width={280}
        height={280}
        style={{ width: 280, height: 280 }}
      />
      <Typography variant="h6" sx={{ mt: 2 }}>
        Opening Healthstory
      </Typography>
    </Container>
  );
};

export default Loader;
