import { Stack, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React, { useEffect } from 'react';
import useResponsive from '../hooks/useResponsive';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip
    placement="bottom"
    arrow
    {...props}
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: theme.palette.common.white,
    // color: 'rgba(0, 0, 0, 0.87)',
    // boxShadow: theme.shadows[1],
    // fontSize: 11,
    textAlign: 'left',
  },
}));

const DashboardSectionHeader = ({ title, action, describtion = '' }) => {
  useEffect(() => {}, []);
  const isDesktop = useResponsive('up', 'md');
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <Stack
      direction={'row'}
      spacing={0.5}
      alignItems={'center'}
      justifyContent={'start'}
      sx={{
        width: '100%',
        pl: !isDesktop ? 2 : 0,
        pr: !isDesktop ? 2 : 0,
        mt: !isDesktop ? 2 : 0,
      }}
    >
      <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
        <Stack
          direction={'row'}
          spacing={1}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{ width: '100%' }}
        >
          <Typography
            sx={{ fontSize: 18, fontWeight: 600, textAlign: 'start' }}
          >
            {title}
          </Typography>
          {action && (
            <Stack sx={{ flexGrow: 1, alignItems: 'end' }}>{action}</Stack>
          )}
        </Stack>
        <Typography sx={{ fontSize: 16, fontWeight: 400, textAlign: 'start' }}>
          {describtion}
        </Typography>
      </Stack>
      {/* <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <LightTooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={handleTooltipClose}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={describtion}
          >
            <IconButton onClick={handleTooltipOpen}>
              <InfoOutlined sx={{ width: 16, height: 16 }} />
            </IconButton>
          </LightTooltip>
        </div>
      </ClickAwayListener> */}
      {/* <Stack sx={{ flexGrow: 1, alignItems: 'end' }}>{action}</Stack> */}
    </Stack>
  );
};

export default DashboardSectionHeader;
