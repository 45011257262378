import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
import useResponsive from '../hooks/useResponsive';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(0),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 4 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon style={{ width: 24, height: 24 }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function IframeDialog({
  title,
  open,
  setOpen,
  url,
  children,
  actions = [],
}) {
  const isDesktop = useResponsive('up', 'md');
  const [loading, setLoading] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    setLoading(true);
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      fullWidth
      fullScreen
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={handleClose}
        style={{ fontSize: 24 }}
      >
        {title}
      </BootstrapDialogTitle>
      <DialogContent>
        {loading && (
          <div
            style={{
              position: 'fixed',
              left: 0,
              top: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CircularProgress sx={{ mt: 12 }} />
          </div>
        )}
        <iframe
          style={{
            width: '100%',
            height: isDesktop ? 'calc(100vh - 64px)' : 'calc(100vh - 64px)',
            marginTop: isDesktop ? 0 : 0,
            border: 'none',
          }}
          src={url}
          onLoad={() => setLoading(false)}
        />
      </DialogContent>
    </BootstrapDialog>
  );
}
