import { ArrowBackIosNew } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { AuthContext } from '../contexts/Auth';
import { ProfilesContext } from '../contexts/Profiles';
import firebase from '../Firebase';
import { emailIsValid, passwordIsValid } from '../utils/validator';

const AcceptInvitation = () => {
  const navigate = useNavigate();

  const { invitationId } = useParams();

  const { isAuthenticated } = useContext(AuthContext);
  const { getProfiles } = useContext(ProfilesContext);

  const [profileInvitation, setProfileInvitation] = useState();
  const [loadingAccept, setLoadingAccept] = useState(false);
  const [loadingDecline, setLoadingDecline] = useState(false);

  useEffect(() => {
    if (isAuthenticated && invitationId) {
      getInvitation();
    }
  }, [isAuthenticated, invitationId]);

  useEffect(() => {
    if (profileInvitation) {
      if (profileInvitation.data()?.status === 'accepted') {
        getProfiles();
        navigate('/dashboard/home');
      }
    }
  }, [profileInvitation]);

  const getInvitation = async () => {
    const invitation = await firebase
      .firestore()
      .collection('profileInvitations')
      .doc(invitationId)
      .get();
    setProfileInvitation(invitation);
  };

  const changeInvitationStatus = async (status) => {
    if (status === 'accepted') {
      setLoadingAccept(true);
    } else {
      setLoadingDecline(true);
    }
    const data = {
      status,
      grantedTo: firebase.auth().currentUser.uid,
    };
    await firebase
      .firestore()
      .collection('profileInvitations')
      .doc(invitationId)
      .update(data);
    getInvitation();
    getProfiles();
    setLoadingAccept(false);
    setLoadingDecline(false);
  };

  return (
    <Container
      disableGutters
      maxWidth={'lg'}
      sx={{
        p: 3,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AppBar position="fixed" sx={{ height: 56 }}>
        <Toolbar>
          <IconButton
            size="small"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              window.history.back();
            }}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <ArrowBackIosNew />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: 'start',
              color: 'white',
            }}
          >
            Profile Invitation
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ pt: 9 }}></Box>
      {!isAuthenticated && <Login />}
      {profileInvitation && (
        <>
          <Stack
            direction={'column'}
            alignItems={'start'}
            spacing={2}
            sx={{ width: '100%', pt: 6 }}
          >
            <Typography sx={{ fontSize: 18, textAlign: 'start' }}>
              {profileInvitation.data()?.profileName} has invited you to be an{' '}
              {profileInvitation.data()?.role} of his profile.
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <Stack direction={'column'} gap={2}>
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              onClick={() => changeInvitationStatus('accepted')}
              loading={loadingAccept}
              disabled={loadingDecline}
            >
              Accept
            </LoadingButton>
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              color="error"
              onClick={() => changeInvitationStatus('declined')}
              loading={loadingDecline}
              disabled={loadingAccept}
            >
              Decline
            </LoadingButton>
          </Stack>
        </>
      )}
    </Container>
  );
};

export default AcceptInvitation;

const Login = ({ invitation }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState('Login');

  useEffect(() => {
    clear();
  }, []);

  const clear = () => {
    setEmail('');
    setPassword('');
    setError('');
  };

  const handleLogin = async () => {
    setError('');
    setLoading(true);
    // check if email is valid
    if (!emailIsValid(email)) {
      setLoading(false);
      setError('Invalid email');
      return;
    }
    if (!passwordIsValid(password)) {
      setLoading(false);
      setError('Password is not valid');
      return;
    }
    let res;
    if (loginOrSignup === 'Login') {
      res = await firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .catch((e, message) => {
          setError(e.message);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      res = await firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .catch((e, message) => {
          setError(e.message);
        })
        .finally(() => {
          setLoading(false);
        })
        .then((userCredential) => {
          const user = userCredential.user; // Get the user object
          const uid = user.uid; // Extract the UID from the user object
          firebase
            .firestore()
            .collection('users')
            .doc(uid)
            .set({ signupCompleted: true });
        });
    }
    // if login is successful
    if (res) {
      // if invitation is present
      if (invitation) {
        await firebase
          .firestore()
          .collection('profileInvitations')
          .doc(invitation.id)
          .update({ grantedTo: res.user.uid });
      }
    }
  };

  return (
    <>
      <Stack
        direction={'row'}
        sx={{ width: '100%' }}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        <Typography sx={{ fontSize: 18, fontWeight: 600, textAlign: 'start' }}>
          You need to be logged in to accept an invitation.
        </Typography>
      </Stack>
      <Stack
        sx={{ width: '100%', textAlign: 'start', mt: 3 }}
        alignItems={'start'}
        spacing={2}
      >
        <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 2 }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              color: '#0B3954',
              textAlign: 'start',
            }}
          >
            Email
          </Typography>
          <TextField
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="User email"
            disabled={invitation}
            sx={{
              '& .MuiInputBase-input': {
                position: 'relative',
                backgroundColor: 'white',
                border: '1px solid',
                borderColor: '#C4C4C4',
                fontSize: 16,
                padding: '10px 12px',
                borderRadius: 1,
              },
            }}
          />
        </Stack>
        <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 2 }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              color: '#0B3954',
              textAlign: 'start',
            }}
          >
            Password
          </Typography>
          <TextField
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{
              '& .MuiInputBase-input': {
                position: 'relative',
                backgroundColor: 'white',
                border: '1px solid',
                borderColor: '#C4C4C4',
                fontSize: 16,
                padding: '10px 12px',
                borderRadius: 1,
              },
            }}
          />
        </Stack>
        {loginOrSignup === 'Login' ? (
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}
          >
            <Typography sx={{ fontSize: 16 }}>
              Don&apos;t have an account?
            </Typography>
            <Button onClick={() => setLoginOrSignup('Signup')} size="medium">
              Signup
            </Button>
          </Stack>
        ) : (
          <Stack
            direction={'row'}
            justifyContent={'center'}
            alignItems={'center'}
            spacing={1}
          >
            <Typography sx={{ fontSize: 16 }}>
              Already have an account?
            </Typography>
            <Button onClick={() => setLoginOrSignup('Login')} size="medium">
              Login
            </Button>
          </Stack>
        )}
        <LoadingButton
          fullWidth
          variant="contained"
          onClick={handleLogin}
          loading={loading}
          disabled={loading || !email || !password}
        >
          {loginOrSignup}
        </LoadingButton>
        <p
          style={{
            textAlign: 'start',
            marginTop: 16,
            fontSize: 14,
          }}
        >
          By signing in I accept the{' '}
          <a
            target="_blank"
            href="https://healthstoryai.com/terms"
            rel="noreferrer"
          >
            Terms & Conditions
          </a>{' '}
          and the{' '}
          <a
            target="_blank"
            href="https://healthstoryai.com/privacy"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
        <Typography sx={{ color: 'red' }}>{error}</Typography>
      </Stack>
    </>
  );
};
