/* eslint-disable quotes */
import { LoadingButton } from '@mui/lab';
import { Stack, Typography } from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import { AuthContext } from '../../contexts/Auth';
import { ProfileContext } from '../../contexts/Profile';
import CustomizedDialogs from '../Dialog';

// eslint-disable-next-line react/prop-types
export default function DeleteAccountDialog({ open, setOpen }) {
  const { logout, currentUser } = React.useContext(AuthContext);
  const { currentProfile } = React.useContext(ProfileContext);

  const [loading, setLoading] = React.useState(false);

  const deleteAccount = async () => {
    // Delete account
    setLoading(true);
    const data = {
      uid: currentUser.uid,
      profileId: currentProfile.id,
    };
    await axios.post(
      'https://us-central1-knowrare-app.cloudfunctions.net/deleteAccount',
      // 'https://us-central1-knowrare-app.cloudfunctions.net/test',
      // 'http://127.0.0.1:5001/knowrare-app/us-central1/deleteAccount',
      data,
      {
        headers: {
          'Accept': 'application/json',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': 'true',
        },
      },
    );
    setLoading(false);
    setOpen(false);
    logout();
  };

  return (
    <CustomizedDialogs open={open} setOpen={setOpen}>
      <Stack
        direction={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        spacing={4}
      >
        <Stack
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
        >
          <Typography sx={{ fontSize: 22, fontWeight: 600, color: '#0C3954' }}>
            Are you sure want to delete your account?
          </Typography>
          <Typography sx={{ fontSize: 18, mt: 1 }}>
            You will not be able to recover your data.
          </Typography>
        </Stack>
        <LoadingButton
          variant="contained"
          fullWidth
          size="large"
          sx={{
            bgcolor: 'red',
            borderRadius: 30,
            fontSize: 18,
            height: 52,
            color: 'white',
            fontWeight: 600,
            textTransform: 'capitalize',
          }}
          onClick={() => deleteAccount()}
          loading={loading}
        >
          Delete my account
        </LoadingButton>
      </Stack>
    </CustomizedDialogs>
  );
}
