import moment from 'moment-timezone';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import firebase from '../Firebase';
import { AuthContext } from './Auth';
import { ProfilesContext } from './Profiles';

export const ProfileContext = createContext();

function ProfileContextProvider(props) {
  const { isAuthenticated, currentUser } = useContext(AuthContext);
  const { profiles, getProfiles, profilesAccess } = useContext(ProfilesContext);

  const [currentProfile, setCurrentProfile] = useState(null);
  const [role, setRole] = useState('viewer');

  const [customEvents, setCustomEvents] = useState([]);
  const [medications, setMedications] = useState([]);
  const [symptoms, setSymptoms] = useState([]);
  const [procedures, setProcedures] = useState([]);
  const [level, setLevel] = useState('starter');

  const [expoPushToken, setExpoPushToken] = useState();

  const [loading, setLoading] = useState(null);

  const organization = useMemo(
    () => (currentProfile ? currentProfile.data()?.organization : null),
    [currentProfile],
  );

  const resetContext = () => {
    setCurrentProfile(null);
    setRole('viewer');
    setCustomEvents([]);
    setMedications([]);
    setSymptoms([]);
    setProcedures([]);
    setLevel('starter');
    setExpoPushToken(null);
    setLoading(null);
  };

  useEffect(() => {
    if (profiles && profiles.length > 0) {
      setCurrentProfile(profiles[0]);
    }
  }, [profiles]);

  useEffect(() => {
    if (isAuthenticated && currentUser) {
      if (currentProfile.data().createdBy === currentUser.uid) {
        setRole('owner');
      } else {
        for (let index = 0; index < profilesAccess.length; index++) {
          const profileAccess = profilesAccess[index];
          if (
            profileAccess.data().profileId === currentProfile.id &&
            profileAccess.data().role === 'admin'
          ) {
            setRole('admin');
            break;
          } else {
            setRole('viewer');
          }
        }
      }
    }
  }, [currentProfile]);

  useEffect(() => {
    if (isAuthenticated && currentProfile) {
      fetchMedications();
    }
  }, [currentProfile, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated && currentProfile && role === 'viewer') {
      const timezone = moment.tz.guess();
      firebase
        .firestore()
        .collection('profiles')
        .doc(currentProfile.id)
        .update({
          timezone,
        });
    }
  }, [currentProfile, isAuthenticated, role]);

  useEffect(() => {
    if (
      isAuthenticated &&
      currentProfile &&
      expoPushToken &&
      role === 'owner' &&
      expoPushToken.length > 0
    ) {
      // update expo push token
      const expoPushTokens = currentProfile.data()?.expoPushTokens || [];
      if (!expoPushTokens.includes(expoPushToken)) {
        expoPushTokens.push(expoPushToken);
      }
      firebase
        .firestore()
        .collection('profiles')
        .doc(currentProfile.id)
        .update({
          expoPushTokens,
        });
    }
  }, [expoPushToken, currentProfile, isAuthenticated, role]);

  const fetchMedications = async () => {
    const _medications = await firebase
      .firestore()
      .collection('medications')
      .where('profileId', '==', currentProfile.id)
      .get();
    setMedications(_medications.docs);
  };

  const updateProfile = async (newData) => {
    try {
      if (setLoading) setLoading(true);
      await firebase
        .firestore()
        .collection('profiles')
        .doc(currentProfile.id)
        .update({
          ...newData,
          lastUpdated: firebase.firestore.Timestamp.now(),
        });
      getProfiles();
      if (setLoading) setLoading(false);
      return;
    } catch (error) {
      setLoading(false);
      alert(`updateProfile - ${error.message}`);
      return;
    }
  };

  const fetchCustomEvents = async () => {
    if (!currentProfile) return;
    const events = await firebase
      .firestore()
      .collection('customEvents')
      .where('profileId', '==', currentProfile.id)
      .get();
    setCustomEvents(
      events.docs.map((eventDoc) => ({
        id: eventDoc.id,
        ...eventDoc.data(),
      })) || [],
    );
  };

  useEffect(() => {
    if (currentProfile) {
      fetchCustomEvents();
      fetchMedications();
    }
  }, [currentProfile]);

  return (
    <ProfileContext.Provider
      value={{
        isAuthenticated,
        currentProfile,
        setCurrentProfile,
        loading,
        setLoading,
        medications,
        setMedications,
        updateProfile,
        fetchCustomEvents,
        customEvents,
        level,
        setLevel,
        setCustomEvents,
        setExpoPushToken,
        expoPushToken,
        fetchMedications,
        role,
        symptoms,
        setSymptoms,
        procedures,
        setProcedures,
        organization,
        resetContext,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
}

export default ProfileContextProvider;
