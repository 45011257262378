import { Button } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/Auth';

const Dashboard = () => {
  const { logout } = useContext(AuthContext);

  useEffect(() => {}, []);

  return (
    <>
      Dashboard
      <Button onClick={logout}>Logout</Button>
    </>
  );
};

export default Dashboard;
