import { Stack, Typography } from '@mui/material';
import moment from 'moment-timezone';
import React from 'react';
import { ASSESSMENTS, ASSESSMENTS_IDS, EVENTS } from '../AddOrEditEventDialog';

const CardHeader = ({ event }) => {
  const getAssesmentName = (item) => {
    let SEARCH_SOURCE = EVENTS;
    if (ASSESSMENTS_IDS.includes(item.data().formId))
      SEARCH_SOURCE = ASSESSMENTS;
    for (let index = 0; index < SEARCH_SOURCE.length; index++) {
      const event = SEARCH_SOURCE[index];
      if (event.id === item.data().formId) {
        return event.name;
      }
    }
  };

  const getTotalScoreForAssesment = (event) => {
    let SEARCH_SOURCE = EVENTS;
    if (ASSESSMENTS_IDS.includes(event.data().formId))
      SEARCH_SOURCE = ASSESSMENTS;
    for (let index = 0; index < SEARCH_SOURCE.length; index++) {
      const e = SEARCH_SOURCE[index];
      if (e.id === event.data().formId) {
        return e.totalScore;
      }
    }
  };

  const getSurveyScore = (event) => {
    if (!event) return 0;
    if (ASSESSMENTS_IDS.includes(event.data().formId)) {
      return event?.data()?.response.form_response.calculated.score;
    }
  };
  return (
    <Stack
      direction={'column'}
      spacing={1}
      alignItems={'start'}
      sx={{ width: '100%', height: '100%' }}
    >
      <Stack
        direction={'row'}
        spacing={1.5}
        alignItems={'center'}
        sx={{ width: '100%', display: 'flex', height: 32 }}
      >
        <img
          src={require('../../assets/icons/assesment.png')}
          alt="assesment"
          width={32}
          height={32}
        />
        <Typography
          sx={{ textAlign: 'left', fontSize: 18, fontWeight: 600, flexGrow: 1 }}
        >
          Survey
        </Typography>
        <Typography sx={{ textAlign: 'right', fontSize: 14 }}>
          {moment(event.data().submittedAt.seconds * 1000).format('D MMM, YY')}
        </Typography>
      </Stack>
      <Typography
        sx={{
          textAlign: 'left',
          color: '#0B3954',
          fontSize: 16,
          fontWeight: 500,
          maxWidth: 480,
          pt: 1.5,
          pb: 1.5,
        }}
      >
        You scored {getSurveyScore(event)}/{getTotalScoreForAssesment(event)},
        indicating a{' '}
        <b>
          {getSurveyScore(event) / getTotalScoreForAssesment(event) > 0.5
            ? 'high'
            : 'low'}
        </b>{' '}
        level of {getAssesmentName(event)?.toLowerCase()} impacting your
        condition
      </Typography>
    </Stack>
  );
};

export default CardHeader;
