import * as React from 'react';

function ProfileIcon(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* stroke="#02111B" */}
      <path
        d="M20.5833 22.75V20.5833C20.5833 19.4341 20.1268 18.3319 19.3141 17.5192C18.5015 16.7065 17.3993 16.25 16.25 16.25H9.75001C8.60073 16.25 7.49853 16.7065 6.68588 17.5192C5.87322 18.3319 5.41667 19.4341 5.41667 20.5833V22.75"
        stroke="#0C3954"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 11.9167C15.3932 11.9167 17.3333 9.97657 17.3333 7.58333C17.3333 5.1901 15.3932 3.25 13 3.25C10.6068 3.25 8.66667 5.1901 8.66667 7.58333C8.66667 9.97657 10.6068 11.9167 13 11.9167Z"
        stroke="#0C3954"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ProfileIcon;
