import { KeyboardArrowRight } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';

const ProfileItem = ({ title, icon, onClick }) => {
  return (
    <Stack
      direction={'column'}
      spacing={2}
      alignItems={'start'}
      sx={{
        mt: 3,
        bgcolor: 'white',
        borderRadius: 3,
        boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
        ml: 2,
        mr: 2,
        p: 3,
      }}
      onClick={onClick}
    >
      <Stack
        direction={'row'}
        justifyContent={'start'}
        alignItems={'center'}
        spacing={2}
        sx={{ width: '100%' }}
      >
        <img
          src={require(`../assets/icons/${icon}.png`)}
          width={32}
          height={32}
          alt={'level'}
        />
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: 18,
            flexGrow: 1,
            textAlign: 'start',
          }}
        >
          {title}
        </Typography>
        <KeyboardArrowRight />
      </Stack>
    </Stack>
  );
};

export default ProfileItem;
