import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { HeroIcon } from './icons/badges/HeroIcon';
import { LegendIcon } from './icons/badges/LegendIcon';
import { StarIcon } from './icons/badges/StarIcon';
import { StarterIcon } from './icons/badges/StarterIcon';
import { SuperHeroIcon } from './icons/badges/SuperHeroIcon';
import { SuperStarIcon } from './icons/badges/SuperStarIcon';

const LABEL = {
  fontSize: 16,
  color: 'black',
  fontWeight: 600,
};

const ProgressBar = ({ level }) => {
  const getIcon = (l) => {
    if (l === 'starter') return <StarterIcon />;
    if (l === 'star') return <StarIcon />;
    if (l === 'superStar') return <SuperStarIcon />;
    if (l === 'hero') return <HeroIcon />;
    if (l === 'superHero') return <SuperHeroIcon />;
    if (l === 'legend') return <LegendIcon />;
  };

  const getLabel = (l) => {
    switch (l) {
      case 'starter':
        return <Typography sx={LABEL}>Starter</Typography>;
      case 'star':
        return <Typography sx={LABEL}>Star</Typography>;
      case 'superStar':
        return <Typography sx={LABEL}>Super Star</Typography>;
      case 'hero':
        return <Typography sx={LABEL}>Hero</Typography>;
      case 'superHero':
        return <Typography sx={LABEL}>Super Hero</Typography>;
      case 'legend':
        return <Typography sx={LABEL}>Legend</Typography>;
      default:
        return <Typography sx={LABEL}>Starter</Typography>;
    }
  };

  const getLevelDescription = (l) => {
    switch (l) {
      case 'starter':
        return '0 entries to unlock';
      case 'star':
        return '10 entries to unlock';
      case 'superStar':
        return '25 entries to unlock';
      case 'hero':
        return '50 entries to unlock';
      case 'superHero':
        return '100 entries to unlock';
      case 'legend':
        return '500 entries to unlock';
      default:
        return '10 entries to unlock';
    }
  };

  const getBadgeLine = (l, currentLevel) => {
    return (
      <Stack
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}
        spacing={1}
        sx={{ width: '100%' }}
      >
        <Box sx={{ width: 90, textAlign: 'start' }}>{getLabel(l)}</Box>
        <Typography
          sx={{
            textAlign: 'start',
            fontSize: 14,
            color: currentLevel ? '#5F5F5F' : '#E6E6E6',
          }}
        >
          {currentLevel ? 'Current level' : getLevelDescription(l)}
        </Typography>
        <Box
          sx={{
            borderRadius: 4,
            bgcolor: currentLevel ? '#86E7B8' : '#E6E6E6',
            width: 36,
            height: 36,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {getIcon(l)}
        </Box>
      </Stack>
    );
  };

  return (
    <Stack
      direction={'column'}
      justifyContent={'center'}
      alignItems={'center'}
      spacing={1}
      sx={{ width: '100%', p: 2 }}
    >
      {getBadgeLine('starter', level === 'starter')}
      {getBadgeLine('star', level === 'star')}
      {getBadgeLine('superStar', level === 'superStar')}
      {getBadgeLine('hero', level === 'hero')}
      {getBadgeLine('superHero', level === 'superHero')}
      {getBadgeLine('legend', level === 'legend')}
    </Stack>
  );
};

export default ProgressBar;
