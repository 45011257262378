import { Button, Card, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useResponsive from '../hooks/useResponsive';
import { track } from '../utils/analytics';
import BioDialog from './BioDialog';

const MoodHomeWidget = () => {
  useEffect(() => {}, []);
  const isDesktop = useResponsive('up', 'md');
  const [openBioDialog, setOpenBioDialog] = useState(false);

  return (
    <Card
      sx={{
        flexGrow: 1,
        minHeight: isDesktop ? 231 : '100%',
        bgcolor: '#FDF4F3',
        p: 3,
        pl: 5,
        pr: 5,
      }}
      elevation={4}
    >
      <BioDialog open={openBioDialog} setOpen={setOpenBioDialog} />
      <Stack
        direction={isDesktop ? 'row' : 'column'}
        spacing={4}
        alignItems={'center'}
      >
        <img src={require('../assets/images/bio.png')} alt="" height={172} />
        <Stack direction={'column'} spacing={2} alignItems={'start'}>
          <Typography
            sx={{
              textAlign: 'left',
              color: '#0B3954',
              fontSize: 24,
              fontWeight: 500,
              maxWidth: 600,
            }}
          >
            {
              'Hi! I am Tali, one of the allies at Know Rare! Need a shoulder to lean on to get through the tough days? I’m here to help.'
            }
          </Typography>
          <Typography sx={{ textAlign: 'left', color: '#0B3954' }}>
            {'Need expert advice to get through tough days? I`m here to help.'}
          </Typography>
          <Stack direction={'row'} spacing={2}>
            <Button
              variant="contained"
              sx={{ borderRadius: 3, background: 'black' }}
              onClick={() => {
                track('chat-started', { from: 'home' });
              }}
            >
              Chat now
            </Button>
            <Button
              sx={{ color: 'black' }}
              onClick={() => {
                setOpenBioDialog(true);
                track('open_bio_button_click');
              }}
            >
              See Tali&apos;s Bio
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Card>
  );
};

export default MoodHomeWidget;
