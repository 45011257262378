import { Card, Stack } from '@mui/material';
import React from 'react';

// Utility function to format date strings
const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

// The main component
const Test = () => {
  // Parse the FHIR data
  const parseFHIRData = (fhirData) => {
    const events = [];

    fhirData.forEach((entry) => {
      const { resource, retrievedAt } = entry;

      if (resource.resourceType === 'Observation') {
        if (
          resource.category &&
          resource.category[0].coding[0].code === 'vital-signs'
        ) {
          if (resource.code.coding[0].display === 'Blood Pressure') {
            const systolic = resource.component.find(
              (comp) =>
                comp.code.coding[0].display === 'Systolic Blood Pressure',
            );
            const diastolic = resource.component.find(
              (comp) =>
                comp.code.coding[0].display === 'Diastolic Blood Pressure',
            );
            events.push({
              type: 'Vital Signs',
              title: 'Blood Pressure',
              retrievedAt,
              details: [
                {
                  label: 'Systolic',
                  value: systolic.valueQuantity.value,
                  unit: systolic.valueQuantity.unit,
                },
                {
                  label: 'Diastolic',
                  value: diastolic.valueQuantity.value,
                  unit: diastolic.valueQuantity.unit,
                },
              ],
            });
          } else if (
            resource.code.coding[0].display.includes('Pain severity')
          ) {
            events.push({
              type: 'Vital Signs',
              title: 'Pain severity scale',
              retrievedAt,
              details: [
                {
                  label: 'Pain Severity',
                  value: resource.valueQuantity.value,
                  unit: resource.valueQuantity.unit,
                },
              ],
            });
          }
        }
      } else if (resource.resourceType === 'Encounter') {
        events.push({
          type: 'Dr. Visit',
          title: resource.type[0].text.replace(' (procedure)', ''),
          retrievedAt,
          details: [
            {
              label: 'Service Provider',
              value: resource.serviceProvider.display,
            },
            {
              label: 'Doctor',
              value: resource.participant
                ? resource.participant[0].individual.display
                : 'Unknown',
            },
          ],
        });
      } else if (resource.resourceType === 'MedicationAdministration') {
        events.push({
          type: 'Treatment',
          title: resource.medicationCodeableConcept.text,
          retrievedAt,
          details: [],
        });
      } else if (resource.resourceType === 'Immunization') {
        events.push({
          type: 'Vaccinations',
          title: resource.vaccineCode.text,
          retrievedAt,
          details: [],
        });
      } else if (resource.resourceType === 'DiagnosticReport') {
        resource.result.forEach((res) => {
          events.push({
            type: 'Labs',
            title: res.display,
            retrievedAt,
            details: [],
          });
        });
      }
    });

    return events;
  };

  const events = parseFHIRData(data.fhirData);

  return (
    <Stack direction={'column'} gap={2} sx={{ p: 2 }}>
      {events.map((event, index) => (
        <Card key={index} className="card" elevation={2}>
          <h3>
            {event.type}: {event.title}
          </h3>
          <p>
            <strong>Retrieved At:</strong> {formatDate(event.retrievedAt)}
          </p>
          {event.details.map((detail, i) => (
            <p key={i}>
              <strong>{detail.label}:</strong> {detail.value} {detail.unit}
            </p>
          ))}
        </Card>
      ))}
    </Stack>
  );
};

export default Test;

const data = {
  fhirVersion: 'R4',
  fhirPortalType: 'TEST',
  fhirData: [
    {
      retrievedAt: '2024-02-29T18:28:22.426Z',
      resource: {
        address: [
          {
            city: 'Bedford',
            country: 'US',
            extension: [
              {
                extension: [
                  {
                    url: 'latitude',
                    valueDecimal: 42.59689331043008,
                  },
                  {
                    url: 'longitude',
                    valueDecimal: -71.27101729479918,
                  },
                ],
                url: 'http://hl7.org/fhir/StructureDefinition/geolocation',
              },
            ],
            line: ['460 Robel Vale Apt 83'],
            postalCode: '01730',
            state: 'Massachusetts',
          },
        ],
        birthDate: '2019-09-06',
        communication: [
          {
            language: {
              coding: [
                {
                  code: 'en-US',
                  display: 'English',
                  system: 'urn:ietf:bcp:47',
                },
              ],
              text: 'English',
            },
          },
        ],
        extension: [
          {
            extension: [
              {
                url: 'ombCategory',
                valueCoding: {
                  code: '2106-3',
                  display: 'White',
                  system: 'urn:oid:2.16.840.1.113883.6.238',
                },
              },
              {
                url: 'text',
                valueString: 'White',
              },
            ],
            url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-race',
          },
          {
            extension: [
              {
                url: 'ombCategory',
                valueCoding: {
                  code: '2186-5',
                  display: 'Not Hispanic or Latino',
                  system: 'urn:oid:2.16.840.1.113883.6.238',
                },
              },
              {
                url: 'text',
                valueString: 'Not Hispanic or Latino',
              },
            ],
            url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity',
          },
          {
            url: 'http://hl7.org/fhir/StructureDefinition/patient-mothersMaidenName',
            valueString: 'Deann Brekke',
          },
          {
            url: 'http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex',
            valueCode: 'F',
          },
          {
            url: 'http://hl7.org/fhir/StructureDefinition/patient-birthPlace',
            valueAddress: {
              city: 'Peabody',
              country: 'US',
              state: 'Massachusetts',
            },
          },
        ],
        gender: 'female',
        id: '470bbce1-f80a-4038-8d47-341290f59282',
        identifier: [
          {
            system: 'https://github.com/synthetichealth/synthea',
            value: 'b9a32653-9fde-401f-bb32-9932e680c456',
          },
          {
            system: 'http://hospital.smarthealthit.org',
            type: {
              coding: [
                {
                  code: 'MR',
                  display: 'Medical Record Number',
                  system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
                },
              ],
              text: 'Medical Record Number',
            },
            value: 'b9a32653-9fde-401f-bb32-9932e680c456',
          },
          {
            system: 'http://hl7.org/fhir/sid/us-ssn',
            type: {
              coding: [
                {
                  code: 'SS',
                  display: 'Social Security Number',
                  system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
                },
              ],
              text: 'Social Security Number',
            },
            value: '999-62-8542',
          },
        ],
        maritalStatus: {
          coding: [
            {
              code: 'S',
              display: 'Never Married',
              system: 'http://terminology.hl7.org/CodeSystem/v3-MaritalStatus',
            },
          ],
          text: 'Never Married',
        },
        multipleBirthBoolean: false,
        name: [
          {
            family: 'Haag',
            given: ['Letha'],
            use: 'official',
          },
        ],
        resourceType: 'Patient',
        telecom: [
          {
            system: 'phone',
            use: 'home',
            value: '555-543-1687',
          },
        ],
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.298Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'LAB',
                display: 'Laboratory',
                system: 'http://terminology.hl7.org/CodeSystem/v2-0074',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '58410-2',
              display:
                'Complete blood count (hemogram) panel - Blood by Automated count',
              system: 'http://loinc.org',
            },
          ],
          text: 'Complete blood count (hemogram) panel - Blood by Automated count',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: '8665ba2c-5cf6-4b7c-826d-389f07ef3f6f',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'DiagnosticReport',
        result: [
          {
            display: 'Erythrocytes [#/volume] in Blood by Automated count',
            reference: 'Observation/c230f591-dee9-4d49-81cb-02b2623e99f2',
          },
          {
            display: 'Hemoglobin [Mass/volume] in Blood',
            reference: 'Observation/e95e6413-e9ff-4c80-97f2-932d311d4f97',
          },
          {
            display: 'Hematocrit [Volume Fraction] of Blood by Automated count',
            reference: 'Observation/3377e296-71e4-4095-b459-92873f255ea4',
          },
          {
            display: 'MCV [Entitic volume] by Automated count',
            reference: 'Observation/f66eacd7-af86-4588-a1af-f3f80f701595',
          },
          {
            display: 'MCH [Entitic mass] by Automated count',
            reference: 'Observation/fc1b999a-6404-4ef8-ae18-8a9dbff8258f',
          },
          {
            display: 'MCHC [Mass/volume] by Automated count',
            reference: 'Observation/8a96c7f7-4211-4893-9342-0da266d736dc',
          },
          {
            display:
              'Erythrocyte distribution width [Entitic volume] by Automated count',
            reference: 'Observation/4c5f22cb-3a22-47a4-9b4f-41cbf54a2939',
          },
          {
            display: 'Platelets [#/volume] in Blood by Automated count',
            reference: 'Observation/362e3465-ab64-46cc-a337-b46770281416',
          },
          {
            display:
              'Platelet distribution width [Entitic volume] in Blood by Automated count',
            reference: 'Observation/e88196b8-431b-4395-b817-4c510176cd59',
          },
          {
            display:
              'Platelet mean volume [Entitic volume] in Blood by Automated count',
            reference: 'Observation/01c4cc82-7cd2-4aae-ab10-c59d8f9e15e1',
          },
        ],
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.317Z',
      resource: {
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: '0e421aab-e5ce-4128-b78e-75d303b6da22',
        occurrenceDateTime: '2019-09-06T16:51:28-04:00',
        patient: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        primarySource: true,
        resourceType: 'Immunization',
        status: 'completed',
        vaccineCode: {
          coding: [
            {
              code: '08',
              display: 'Hep B, adolescent or pediatric',
              system: 'http://hl7.org/fhir/sid/cvx',
            },
          ],
          text: 'Hep B, adolescent or pediatric',
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.317Z',
      resource: {
        id: 'd0b6b338-beed-47a9-8041-bdf065fd3c0a',
        occurrenceDateTime: '2019-01-31T04:09:40-05:00',
        patient: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        primarySource: true,
        resourceType: 'Immunization',
        status: 'completed',
        vaccineCode: {
          coding: [
            {
              code: '08',
              display: 'Hep B, adolescent or pediatric',
              system: 'http://hl7.org/fhir/sid/cvx',
            },
          ],
          text: 'Hep B, adolescent or pediatric',
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.443Z',
      resource: {
        context: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        id: '514c5c5e-b4a5-4d51-bfd6-808dbc09b682',
        medicationCodeableConcept: {
          coding: [
            {
              code: '1736854',
              display: 'Cisplatin 50 MG Injection',
              system: 'http://www.nlm.nih.gov/research/umls/rxnorm',
            },
          ],
          text: 'Cisplatin 50 MG Injection',
        },
        resourceType: 'MedicationAdministration',
        status: 'completed',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'vital-signs',
                display: 'vital-signs',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '8302-2',
              display: 'Body Height',
              system: 'http://loinc.org',
            },
          ],
          text: 'Body Height',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: 'b0486c29-f404-4b8e-8da0-80aaba61dbbc',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: 'cm',
          system: 'http://unitsofmeasure.org',
          unit: 'cm',
          value: 52.82852501906687,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'laboratory',
                display: 'laboratory',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '785-6',
              display: 'MCH [Entitic mass] by Automated count',
              system: 'http://loinc.org',
            },
          ],
          text: 'MCH [Entitic mass] by Automated count',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: 'fc1b999a-6404-4ef8-ae18-8a9dbff8258f',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: 'pg',
          system: 'http://unitsofmeasure.org',
          unit: 'pg',
          value: 30.152225059357296,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'laboratory',
                display: 'laboratory',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '786-4',
              display: 'MCHC [Mass/volume] by Automated count',
              system: 'http://loinc.org',
            },
          ],
          text: 'MCHC [Mass/volume] by Automated count',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: '8a96c7f7-4211-4893-9342-0da266d736dc',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: 'g/dL',
          system: 'http://unitsofmeasure.org',
          unit: 'g/dL',
          value: 34.710175226030636,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'laboratory',
                display: 'laboratory',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '21000-5',
              display:
                'Erythrocyte distribution width [Entitic volume] by Automated count',
              system: 'http://loinc.org',
            },
          ],
          text: 'Erythrocyte distribution width [Entitic volume] by Automated count',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: '4c5f22cb-3a22-47a4-9b4f-41cbf54a2939',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: 'fL',
          system: 'http://unitsofmeasure.org',
          unit: 'fL',
          value: 44.926903202368834,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'laboratory',
                display: 'laboratory',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '777-3',
              display: 'Platelets [#/volume] in Blood by Automated count',
              system: 'http://loinc.org',
            },
          ],
          text: 'Platelets [#/volume] in Blood by Automated count',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: '362e3465-ab64-46cc-a337-b46770281416',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: '10*3/uL',
          system: 'http://unitsofmeasure.org',
          unit: '10*3/uL',
          value: 188.96892931739285,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'laboratory',
                display: 'laboratory',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '32207-3',
              display:
                'Platelet distribution width [Entitic volume] in Blood by Automated count',
              system: 'http://loinc.org',
            },
          ],
          text: 'Platelet distribution width [Entitic volume] in Blood by Automated count',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: 'e88196b8-431b-4395-b817-4c510176cd59',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: 'fL',
          system: 'http://unitsofmeasure.org',
          unit: 'fL',
          value: 494.4697653803923,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'laboratory',
                display: 'laboratory',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '32623-1',
              display:
                'Platelet mean volume [Entitic volume] in Blood by Automated count',
              system: 'http://loinc.org',
            },
          ],
          text: 'Platelet mean volume [Entitic volume] in Blood by Automated count',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: '01c4cc82-7cd2-4aae-ab10-c59d8f9e15e1',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: 'fL',
          system: 'http://unitsofmeasure.org',
          unit: 'fL',
          value: 11.735890703067405,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'survey',
                display: 'survey',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '72166-2',
              display: 'Tobacco smoking status NHIS',
              system: 'http://loinc.org',
            },
          ],
          text: 'Tobacco smoking status NHIS',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: '39f1b84c-cb6e-4beb-a734-28ced66bb87c',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueCodeableConcept: {
          coding: [
            {
              code: '266919005',
              display: 'Never smoker',
              system: 'http://snomed.info/sct',
            },
          ],
          text: 'Never smoker',
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'vital-signs',
                display: 'vital-signs',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '72514-3',
              display:
                'Pain severity - 0-10 verbal numeric rating [Score] - Reported',
              system: 'http://loinc.org',
            },
          ],
          text: 'Pain severity - 0-10 verbal numeric rating [Score] - Reported',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: 'b3318a8a-70fa-4b32-bbc5-9fb5bdd1bde6',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: '{score}',
          system: 'http://unitsofmeasure.org',
          unit: '{score}',
          value: 3.2387325950126864,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'vital-signs',
                display: 'vital-signs',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '29463-7',
              display: 'Body Weight',
              system: 'http://loinc.org',
            },
          ],
          text: 'Body Weight',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: 'c2ff6b0a-c3d8-4c5b-bad4-4a87fd76cce9',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: 'kg',
          system: 'http://unitsofmeasure.org',
          unit: 'kg',
          value: 3.675766220938448,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'vital-signs',
                display: 'vital-signs',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '77606-2',
              display: 'Weight-for-length Per age and sex',
              system: 'http://loinc.org',
            },
          ],
          text: 'Weight-for-length Per age and sex',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: '5d567a92-32ab-4c22-b368-b57c1cecdb0a',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: '%',
          system: 'http://unitsofmeasure.org',
          unit: '%',
          value: 7.3170067706392565,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'vital-signs',
                display: 'vital-signs',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '55284-4',
              display: 'Blood Pressure',
              system: 'http://loinc.org',
            },
          ],
          text: 'Blood Pressure',
        },
        component: [
          {
            code: {
              coding: [
                {
                  code: '8462-4',
                  display: 'Diastolic Blood Pressure',
                  system: 'http://loinc.org',
                },
              ],
              text: 'Diastolic Blood Pressure',
            },
            valueQuantity: {
              code: 'mm[Hg]',
              system: 'http://unitsofmeasure.org',
              unit: 'mm[Hg]',
              value: 73.38252157427284,
            },
          },
          {
            code: {
              coding: [
                {
                  code: '8480-6',
                  display: 'Systolic Blood Pressure',
                  system: 'http://loinc.org',
                },
              ],
              text: 'Systolic Blood Pressure',
            },
            valueQuantity: {
              code: 'mm[Hg]',
              system: 'http://unitsofmeasure.org',
              unit: 'mm[Hg]',
              value: 113.65962061979717,
            },
          },
        ],
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: 'a5e6bc27-97ee-43c5-9017-18b09c8d5479',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'laboratory',
                display: 'laboratory',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '789-8',
              display: 'Erythrocytes [#/volume] in Blood by Automated count',
              system: 'http://loinc.org',
            },
          ],
          text: 'Erythrocytes [#/volume] in Blood by Automated count',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: 'c230f591-dee9-4d49-81cb-02b2623e99f2',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'preliminary',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: '10*6/uL',
          system: 'http://unitsofmeasure.org',
          unit: '10*6/uL',
          value: 3,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'laboratory',
                display: 'laboratory',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '718-7',
              display: 'Hemoglobin [Mass/volume] in Blood',
              system: 'http://loinc.org',
            },
          ],
          text: 'Hemoglobin [Mass/volume] in Blood',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: 'e95e6413-e9ff-4c80-97f2-932d311d4f97',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: 'g/dL',
          system: 'http://unitsofmeasure.org',
          unit: 'g/dL',
          value: 16.96388985165132,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'laboratory',
                display: 'laboratory',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '4544-3',
              display:
                'Hematocrit [Volume Fraction] of Blood by Automated count',
              system: 'http://loinc.org',
            },
          ],
          text: 'Hematocrit [Volume Fraction] of Blood by Automated count',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: '3377e296-71e4-4095-b459-92873f255ea4',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: '%',
          system: 'http://unitsofmeasure.org',
          unit: '%',
          value: 38.7039118621519,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.346Z',
      resource: {
        category: [
          {
            coding: [
              {
                code: 'laboratory',
                display: 'laboratory',
                system:
                  'http://terminology.hl7.org/CodeSystem/observation-category',
              },
            ],
          },
        ],
        code: {
          coding: [
            {
              code: '787-2',
              display: 'MCV [Entitic volume] by Automated count',
              system: 'http://loinc.org',
            },
          ],
          text: 'MCV [Entitic volume] by Automated count',
        },
        effectiveDateTime: '2019-09-06T16:51:28-04:00',
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: 'f66eacd7-af86-4588-a1af-f3f80f701595',
        issued: '2019-09-06T16:51:28.918-04:00',
        resourceType: 'Observation',
        status: 'final',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        valueQuantity: {
          code: 'fL',
          system: 'http://unitsofmeasure.org',
          unit: 'fL',
          value: 83.3712460584981,
        },
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.438Z',
      resource: {
        class: {
          code: 'AMB',
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
        },
        id: '1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        period: {
          end: '2019-09-06T17:21:28-04:00',
          start: '2019-09-06T16:51:28-04:00',
        },
        resourceType: 'Encounter',
        serviceProvider: {
          display: 'LAHEY PHYSICIAN COMMUNITY ORGANIZATION I INC',
        },
        status: 'finished',
        subject: {
          display: 'Letha Haag',
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
        type: [
          {
            coding: [
              {
                code: '410620009',
                display: 'Well child visit (procedure)',
                system: 'http://snomed.info/sct',
              },
            ],
            text: 'Well child visit (procedure)',
          },
        ],
      },
    },
    {
      retrievedAt: '2024-02-29T18:28:22.433Z',
      resource: {
        code: {
          coding: [
            {
              code: '162676008',
              display: 'Brief general examination (procedure)',
              system: 'http://snomed.info/sct',
            },
          ],
          text: 'Brief general examination (procedure)',
        },
        encounter: {
          reference: 'Encounter/1098cfe8-d2da-4d96-9faf-ccdcdb558b80',
        },
        id: '25c52db0-8849-4b0f-81a0-60f3eea44b22',
        performedPeriod: {
          end: '2019-09-06T17:21:28-04:00',
          start: '2019-09-06T16:51:28-04:00',
        },
        resourceType: 'Procedure',
        status: 'completed',
        subject: {
          reference: 'Patient/470bbce1-f80a-4038-8d47-341290f59282',
        },
      },
    },
  ],
};

// {
//   "fhirVersion": "R4",
//   "fhirPortalType": "TEST",
//   "fhirData": [
//     {
//       "retrievedAt": "2024-02-29T18:28:43.594Z",
//       "resource": {
//         "address": [
//           {
//             "city": "Worcester",
//             "country": "US",
//             "extension": [
//               {
//                 "extension": [
//                   {
//                     "url": "latitude",
//                     "valueDecimal": 42.34739614448436
//                   },
//                   {
//                     "url": "longitude",
//                     "valueDecimal": -71.8373692543662
//                   }
//                 ],
//                 "url": "http://hl7.org/fhir/StructureDefinition/geolocation"
//               }
//             ],
//             "line": [
//               "161 D'Amore Stravenue"
//             ],
//             "postalCode": "01545",
//             "state": "Massachusetts"
//           }
//         ],
//         "birthDate": "2019-07-02",
//         "communication": [
//           {
//             "language": {
//               "coding": [
//                 {
//                   "code": "fr-FR",
//                   "display": "French (France)",
//                   "system": "urn:ietf:bcp:47"
//                 }
//               ],
//               "text": "French (France)"
//             }
//           }
//         ],
//         "extension": [
//           {
//             "extension": [
//               {
//                 "url": "ombCategory",
//                 "valueCoding": {
//                   "code": "2106-3",
//                   "display": "White",
//                   "system": "urn:oid:2.16.840.1.113883.6.238"
//                 }
//               },
//               {
//                 "url": "text",
//                 "valueString": "White"
//               }
//             ],
//             "url": "http://hl7.org/fhir/us/core/StructureDefinition/us-core-race"
//           },
//           {
//             "extension": [
//               {
//                 "url": "ombCategory",
//                 "valueCoding": {
//                   "code": "2186-5",
//                   "display": "Not Hispanic or Latino",
//                   "system": "urn:oid:2.16.840.1.113883.6.238"
//                 }
//               },
//               {
//                 "url": "text",
//                 "valueString": "Not Hispanic or Latino"
//               }
//             ],
//             "url": "http://hl7.org/fhir/us/core/StructureDefinition/us-core-ethnicity"
//           },
//           {
//             "url": "http://hl7.org/fhir/StructureDefinition/patient-mothersMaidenName",
//             "valueString": "Phillis Lockman"
//           },
//           {
//             "url": "http://hl7.org/fhir/us/core/StructureDefinition/us-core-birthsex",
//             "valueCode": "F"
//           },
//           {
//             "url": "http://hl7.org/fhir/StructureDefinition/patient-birthPlace",
//             "valueAddress": {
//               "city": "Longueuil",
//               "country": "CA",
//               "state": "Quebec"
//             }
//           }
//         ],
//         "gender": "female",
//         "id": "6df25cc5-ea04-46d4-a992-7297c60f708d",
//         "identifier": [
//           {
//             "system": "https://github.com/synthetichealth/synthea",
//             "value": "8ccf09f3-07c3-4d93-9389-48574072ebc7"
//           },
//           {
//             "system": "http://hospital.smarthealthit.org",
//             "type": {
//               "coding": [
//                 {
//                   "code": "MR",
//                   "display": "Medical Record Number",
//                   "system": "http://terminology.hl7.org/CodeSystem/v2-0203"
//                 }
//               ],
//               "text": "Medical Record Number"
//             },
//             "value": "8ccf09f3-07c3-4d93-9389-48574072ebc7"
//           },
//           {
//             "system": "http://hl7.org/fhir/sid/us-ssn",
//             "type": {
//               "coding": [
//                 {
//                   "code": "SS",
//                   "display": "Social Security Number",
//                   "system": "http://terminology.hl7.org/CodeSystem/v2-0203"
//                 }
//               ],
//               "text": "Social Security Number"
//             },
//             "value": "999-80-2569"
//           }
//         ],
//         "maritalStatus": {
//           "coding": [
//             {
//               "code": "S",
//               "display": "Never Married",
//               "system": "http://terminology.hl7.org/CodeSystem/v3-MaritalStatus"
//             }
//           ],
//           "text": "Never Married"
//         },
//         "multipleBirthBoolean": false,
//         "name": [
//           {
//             "family": "Cartwright",
//             "given": [
//               "Gabriella"
//             ],
//             "use": "official"
//           }
//         ],
//         "resourceType": "Patient",
//         "telecom": [
//           {
//             "system": "phone",
//             "use": "home",
//             "value": "555-215-9450"
//           }
//         ]
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.461Z",
//       "resource": {
//         "abatementDateTime": "2018-08-28T07:43:20-04:00",
//         "clinicalStatus": {
//           "coding": [
//             {
//               "code": "resolved",
//               "system": "http://terminology.hl7.org/CodeSystem/condition-clinical"
//             }
//           ]
//         },
//         "code": {
//           "coding": [
//             {
//               "code": "444814009",
//               "display": "Viral sinusitis (disorder)",
//               "system": "http://snomed.info/sct"
//             }
//           ],
//           "text": "Viral sinusitis (disorder)"
//         },
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "34a59a10-c27e-4f6e-8709-da38054cd310",
//         "onsetDateTime": "2018-08-07T07:43:20-04:00",
//         "recordedDate": "2019-07-02T21:56:28-04:00",
//         "resourceType": "Condition",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "verificationStatus": {
//           "coding": [
//             {
//               "code": "confirmed",
//               "system": "http://terminology.hl7.org/CodeSystem/condition-ver-status"
//             }
//           ]
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.467Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "LAB",
//                 "display": "Laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/v2-0074"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "58410-2",
//               "display": "Complete blood count (hemogram) panel - Blood by Automated count",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Complete blood count (hemogram) panel - Blood by Automated count"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "b4e4c900-9296-4611-903c-3a5e93fb72eb",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "DiagnosticReport",
//         "result": [
//           {
//             "display": "Leukocytes [#/volume] in Blood by Automated count",
//             "reference": "Observation/66be4397-263d-47de-a90b-5948b91c7459"
//           },
//           {
//             "display": "Erythrocytes [#/volume] in Blood by Automated count",
//             "reference": "Observation/d0b4867e-eab8-4a84-8b7a-e58c7c49e9aa"
//           },
//           {
//             "display": "Hemoglobin [Mass/volume] in Blood",
//             "reference": "Observation/ac3d2bc2-aa3c-4dfe-8adb-7684d70e716f"
//           },
//           {
//             "display": "Hematocrit [Volume Fraction] of Blood by Automated count",
//             "reference": "Observation/94011e3d-3e89-441e-8c73-f23c637f271d"
//           },
//           {
//             "display": "MCV [Entitic volume] by Automated count",
//             "reference": "Observation/4d20d48e-7c3b-4112-8e44-f54cb9fc9c9e"
//           },
//           {
//             "display": "MCH [Entitic mass] by Automated count",
//             "reference": "Observation/5c8a0168-67b4-4759-b3e9-43e414c9e61f"
//           },
//           {
//             "display": "MCHC [Mass/volume] by Automated count",
//             "reference": "Observation/ce9738b6-788d-4000-85bb-6e6cf2089fe0"
//           },
//           {
//             "display": "Erythrocyte distribution width [Entitic volume] by Automated count",
//             "reference": "Observation/519523b9-c080-4ef9-8df3-88306db5272e"
//           },
//           {
//             "display": "Platelets [#/volume] in Blood by Automated count",
//             "reference": "Observation/bafdc119-1a16-48d4-92e1-9006a8e0b07e"
//           },
//           {
//             "display": "Platelet distribution width [Entitic volume] in Blood by Automated count",
//             "reference": "Observation/e9d7e0a9-cd8a-4ec9-be68-9ec158a7f3a8"
//           },
//           {
//             "display": "Platelet mean volume [Entitic volume] in Blood by Automated count",
//             "reference": "Observation/7d472262-4baf-4d67-a58b-419fb97b4a76"
//           }
//         ],
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.472Z",
//       "resource": {
//         "authoredOn": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "f9a3668b-4f85-46cc-bf75-56de6b17ab17",
//         "intent": "order",
//         "medicationCodeableConcept": {
//           "coding": [
//             {
//               "code": "562251",
//               "display": "Amoxicillin 250 MG / Clavulanate 125 MG Oral Tablet",
//               "system": "http://www.nlm.nih.gov/research/umls/rxnorm"
//             }
//           ],
//           "text": "Amoxicillin 250 MG / Clavulanate 125 MG Oral Tablet"
//         },
//         "resourceType": "MedicationRequest",
//         "status": "active",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "vital-signs",
//                 "display": "vital-signs",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "8302-2",
//               "display": "Body Height",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Body Height"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "6dc453a3-eba2-499a-9eaf-dcfe88a49e70",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "cm",
//           "system": "http://unitsofmeasure.org",
//           "unit": "cm",
//           "value": 53.73669546458164
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "laboratory",
//                 "display": "laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "785-6",
//               "display": "MCH [Entitic mass] by Automated count",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "MCH [Entitic mass] by Automated count"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "5c8a0168-67b4-4759-b3e9-43e414c9e61f",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "pg",
//           "system": "http://unitsofmeasure.org",
//           "unit": "pg",
//           "value": 31.961404800615536
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "laboratory",
//                 "display": "laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "786-4",
//               "display": "MCHC [Mass/volume] by Automated count",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "MCHC [Mass/volume] by Automated count"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "ce9738b6-788d-4000-85bb-6e6cf2089fe0",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "g/dL",
//           "system": "http://unitsofmeasure.org",
//           "unit": "g/dL",
//           "value": 35.73794879441851
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "laboratory",
//                 "display": "laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "21000-5",
//               "display": "Erythrocyte distribution width [Entitic volume] by Automated count",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Erythrocyte distribution width [Entitic volume] by Automated count"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "519523b9-c080-4ef9-8df3-88306db5272e",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "fL",
//           "system": "http://unitsofmeasure.org",
//           "unit": "fL",
//           "value": 41.12467438718957
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "laboratory",
//                 "display": "laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "777-3",
//               "display": "Platelets [#/volume] in Blood by Automated count",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Platelets [#/volume] in Blood by Automated count"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "bafdc119-1a16-48d4-92e1-9006a8e0b07e",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "preliminary",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "10*3/uL",
//           "system": "http://unitsofmeasure.org",
//           "unit": "10*3/uL",
//           "value": 150
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "laboratory",
//                 "display": "laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "32207-3",
//               "display": "Platelet distribution width [Entitic volume] in Blood by Automated count",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Platelet distribution width [Entitic volume] in Blood by Automated count"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "e9d7e0a9-cd8a-4ec9-be68-9ec158a7f3a8",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "fL",
//           "system": "http://unitsofmeasure.org",
//           "unit": "fL",
//           "value": 276.3752998623521
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "laboratory",
//                 "display": "laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "32623-1",
//               "display": "Platelet mean volume [Entitic volume] in Blood by Automated count",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Platelet mean volume [Entitic volume] in Blood by Automated count"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "7d472262-4baf-4d67-a58b-419fb97b4a76",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "fL",
//           "system": "http://unitsofmeasure.org",
//           "unit": "fL",
//           "value": 12.016514975314271
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "survey",
//                 "display": "survey",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "72166-2",
//               "display": "Tobacco smoking status NHIS",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Tobacco smoking status NHIS"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "5ae77673-cf23-4fb4-942b-65e9b933431e",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueCodeableConcept": {
//           "coding": [
//             {
//               "code": "266919005",
//               "display": "Never smoker",
//               "system": "http://snomed.info/sct"
//             }
//           ],
//           "text": "Never smoker"
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "vital-signs",
//                 "display": "vital-signs",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "8302-2",
//               "display": "Body Height",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Body Height"
//         },
//         "effectiveDateTime": "2019-08-06T21:56:28-04:00",
//         "encounter": {
//           "reference": "8774d0fb-63da-4664-a17e-b177dafa413a"
//         },
//         "id": "02bfa7b7-9b7e-4596-9fe9-f0246fd90978",
//         "issued": "2019-08-06T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "cm",
//           "system": "http://unitsofmeasure.org",
//           "unit": "cm",
//           "value": 57.290706927762265
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "vital-signs",
//                 "display": "vital-signs",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "72514-3",
//               "display": "Pain severity - 0-10 verbal numeric rating [Score] - Reported",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Pain severity - 0-10 verbal numeric rating [Score] - Reported"
//         },
//         "effectiveDateTime": "2019-08-06T21:56:28-04:00",
//         "encounter": {
//           "reference": "8774d0fb-63da-4664-a17e-b177dafa413a"
//         },
//         "id": "3f4283ce-12e7-4fdb-9bf8-5147ac2d09f2",
//         "issued": "2019-08-06T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "{score}",
//           "system": "http://unitsofmeasure.org",
//           "unit": "{score}",
//           "value": 0.5823783713617785
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "vital-signs",
//                 "display": "vital-signs",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "29463-7",
//               "display": "Body Weight",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Body Weight"
//         },
//         "effectiveDateTime": "2019-08-06T21:56:28-04:00",
//         "encounter": {
//           "reference": "8774d0fb-63da-4664-a17e-b177dafa413a"
//         },
//         "id": "274b99ee-5217-48ff-ab99-4cb70934e45e",
//         "issued": "2019-08-06T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "kg",
//           "system": "http://unitsofmeasure.org",
//           "unit": "kg",
//           "value": 4.245194164367047
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "vital-signs",
//                 "display": "vital-signs",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "29463-7",
//               "display": "Body Weight",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Body Weight"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "c1776449-e653-4af1-9f49-2fffb57bd1be",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "kg",
//           "system": "http://unitsofmeasure.org",
//           "unit": "kg",
//           "value": 3.5327275881802835
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "vital-signs",
//                 "display": "vital-signs",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "77606-2",
//               "display": "Weight-for-length Per age and sex",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Weight-for-length Per age and sex"
//         },
//         "effectiveDateTime": "2019-08-06T21:56:28-04:00",
//         "encounter": {
//           "reference": "8774d0fb-63da-4664-a17e-b177dafa413a"
//         },
//         "id": "93070cae-6374-4771-a4ce-4c82952e7150",
//         "issued": "2019-08-06T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "%",
//           "system": "http://unitsofmeasure.org",
//           "unit": "%",
//           "value": 0.6033996863385913
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "vital-signs",
//                 "display": "vital-signs",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "55284-4",
//               "display": "Blood Pressure",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Blood Pressure"
//         },
//         "component": [
//           {
//             "code": {
//               "coding": [
//                 {
//                   "code": "8462-4",
//                   "display": "Diastolic Blood Pressure",
//                   "system": "http://loinc.org"
//                 }
//               ],
//               "text": "Diastolic Blood Pressure"
//             },
//             "valueQuantity": {
//               "code": "mm[Hg]",
//               "system": "http://unitsofmeasure.org",
//               "unit": "mm[Hg]",
//               "value": 83.76871989589561
//             }
//           },
//           {
//             "code": {
//               "coding": [
//                 {
//                   "code": "8480-6",
//                   "display": "Systolic Blood Pressure",
//                   "system": "http://loinc.org"
//                 }
//               ],
//               "text": "Systolic Blood Pressure"
//             },
//             "valueQuantity": {
//               "code": "mm[Hg]",
//               "system": "http://unitsofmeasure.org",
//               "unit": "mm[Hg]",
//               "value": 132.67269819175678
//             }
//           }
//         ],
//         "effectiveDateTime": "2019-08-06T21:56:28-04:00",
//         "encounter": {
//           "reference": "8774d0fb-63da-4664-a17e-b177dafa413a"
//         },
//         "id": "bbdd5842-9f81-4419-99fb-c0d39171f01d",
//         "issued": "2019-08-06T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "survey",
//                 "display": "survey",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "72166-2",
//               "display": "Tobacco smoking status NHIS",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Tobacco smoking status NHIS"
//         },
//         "effectiveDateTime": "2019-08-06T21:56:28-04:00",
//         "encounter": {
//           "reference": "8774d0fb-63da-4664-a17e-b177dafa413a"
//         },
//         "id": "1ac1e8c1-b82f-4c1a-b4fb-85c05cdf64fc",
//         "issued": "2019-08-06T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueCodeableConcept": {
//           "coding": [
//             {
//               "code": "266919005",
//               "display": "Never smoker",
//               "system": "http://snomed.info/sct"
//             }
//           ],
//           "text": "Never smoker"
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "vital-signs",
//                 "display": "vital-signs",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "77606-2",
//               "display": "Weight-for-length Per age and sex",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Weight-for-length Per age and sex"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "b893cc74-3f08-46eb-8c44-6ac5b8d4f3b1",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "%",
//           "system": "http://unitsofmeasure.org",
//           "unit": "%",
//           "value": 0.38431297838648504
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "vital-signs",
//                 "display": "vital-signs",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "55284-4",
//               "display": "Blood Pressure",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Blood Pressure"
//         },
//         "component": [
//           {
//             "code": {
//               "coding": [
//                 {
//                   "code": "8462-4",
//                   "display": "Diastolic Blood Pressure",
//                   "system": "http://loinc.org"
//                 }
//               ],
//               "text": "Diastolic Blood Pressure"
//             },
//             "valueQuantity": {
//               "code": "mm[Hg]",
//               "system": "http://unitsofmeasure.org",
//               "unit": "mm[Hg]",
//               "value": 78.17831403944147
//             }
//           },
//           {
//             "code": {
//               "coding": [
//                 {
//                   "code": "8480-6",
//                   "display": "Systolic Blood Pressure",
//                   "system": "http://loinc.org"
//                 }
//               ],
//               "text": "Systolic Blood Pressure"
//             },
//             "valueQuantity": {
//               "code": "mm[Hg]",
//               "system": "http://unitsofmeasure.org",
//               "unit": "mm[Hg]",
//               "value": 121.86853043597904
//             }
//           }
//         ],
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "190ec130-a93c-4b58-a2e5-14d3c7c548ba",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "laboratory",
//                 "display": "laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "6690-2",
//               "display": "Leukocytes [#/volume] in Blood by Automated count",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Leukocytes [#/volume] in Blood by Automated count"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "66be4397-263d-47de-a90b-5948b91c7459",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "10*3/uL",
//           "system": "http://unitsofmeasure.org",
//           "unit": "10*3/uL",
//           "value": 7.766198470140598
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "laboratory",
//                 "display": "laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "789-8",
//               "display": "Erythrocytes [#/volume] in Blood by Automated count",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Erythrocytes [#/volume] in Blood by Automated count"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "d0b4867e-eab8-4a84-8b7a-e58c7c49e9aa",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "10*6/uL",
//           "system": "http://unitsofmeasure.org",
//           "unit": "10*6/uL",
//           "value": 5.495288885085869
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "laboratory",
//                 "display": "laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "718-7",
//               "display": "Hemoglobin [Mass/volume] in Blood",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Hemoglobin [Mass/volume] in Blood"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "ac3d2bc2-aa3c-4dfe-8adb-7684d70e716f",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "g/dL",
//           "system": "http://unitsofmeasure.org",
//           "unit": "g/dL",
//           "value": 15.795931531260962
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "laboratory",
//                 "display": "laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "4544-3",
//               "display": "Hematocrit [Volume Fraction] of Blood by Automated count",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "Hematocrit [Volume Fraction] of Blood by Automated count"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "94011e3d-3e89-441e-8c73-f23c637f271d",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "final",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "%",
//           "system": "http://unitsofmeasure.org",
//           "unit": "%",
//           "value": 41.92023318608107
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.479Z",
//       "resource": {
//         "category": [
//           {
//             "coding": [
//               {
//                 "code": "laboratory",
//                 "display": "laboratory",
//                 "system": "http://terminology.hl7.org/CodeSystem/observation-category"
//               }
//             ]
//           }
//         ],
//         "code": {
//           "coding": [
//             {
//               "code": "787-2",
//               "display": "MCV [Entitic volume] by Automated count",
//               "system": "http://loinc.org"
//             }
//           ],
//           "text": "MCV [Entitic volume] by Automated count"
//         },
//         "effectiveDateTime": "2019-07-02T21:56:28-04:00",
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "4d20d48e-7c3b-4112-8e44-f54cb9fc9c9e",
//         "issued": "2019-07-02T21:56:28.086-04:00",
//         "resourceType": "Observation",
//         "status": "preliminary",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "valueQuantity": {
//           "code": "fL",
//           "system": "http://unitsofmeasure.org",
//           "unit": "fL",
//           "value": 81
//         }
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.604Z",
//       "resource": {
//         "class": {
//           "code": "AMB",
//           "system": "http://terminology.hl7.org/CodeSystem/v3-ActCode"
//         },
//         "id": "69fd313d-d6a3-49ee-a7e8-cb800a1de1bf",
//         "period": {
//           "end": "2019-07-02T22:26:28-04:00",
//           "start": "2019-07-02T21:56:28-04:00"
//         },
//         "resourceType": "Encounter",
//         "status": "finished",
//         "subject": {
//           "display": "Gabriella Cartwright",
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "type": [
//           {
//             "coding": [
//               {
//                 "code": "410620009",
//                 "display": "Well child visit (procedure)",
//                 "system": "http://snomed.info/sct"
//               }
//             ],
//             "text": "Well child visit (procedure)"
//           }
//         ]
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.604Z",
//       "resource": {
//         "class": {
//           "code": "AMB",
//           "system": "http://terminology.hl7.org/CodeSystem/v3-ActCode"
//         },
//         "id": "8774d0fb-63da-4664-a17e-b177dafa413a",
//         "participant": [
//           {
//             "individual": {
//               "display": "Dr. Jacques Zemlak",
//               "reference": "Practitioner/0000016d-3a85-4cca-0000-000000008a66"
//             }
//           }
//         ],
//         "period": {
//           "end": "2019-08-06T22:11:28-04:00",
//           "start": "2019-08-06T21:56:28-04:00"
//         },
//         "resourceType": "Encounter",
//         "serviceProvider": {
//           "display": "PCP67912",
//           "reference": "Organization/6cd92968-eb86-3d27-b3cf-05a3987d2cba"
//         },
//         "status": "finished",
//         "subject": {
//           "display": "Gabriella Cartwright",
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         },
//         "type": [
//           {
//             "coding": [
//               {
//                 "code": "410620009",
//                 "display": "Well child visit (procedure)",
//                 "system": "http://snomed.info/sct"
//               }
//             ],
//             "text": "Well child visit (procedure)"
//           }
//         ]
//       }
//     },
//     {
//       "retrievedAt": "2024-02-29T18:28:43.599Z",
//       "resource": {
//         "code": {
//           "coding": [
//             {
//               "code": "117015009",
//               "display": "Throat culture (procedure)",
//               "system": "http://snomed.info/sct"
//             }
//           ],
//           "text": "Throat culture (procedure)"
//         },
//         "encounter": {
//           "reference": "Encounter/69fd313d-d6a3-49ee-a7e8-cb800a1de1bf"
//         },
//         "id": "e3acda21-0de3-4c4c-bc3d-d52885df4794",
//         "performedPeriod": {
//           "end": "2019-07-02T22:26:28-04:00",
//           "start": "2019-07-02T21:56:28-04:00"
//         },
//         "resourceType": "Procedure",
//         "status": "completed",
//         "subject": {
//           "reference": "Patient/6df25cc5-ea04-46d4-a992-7297c60f708d"
//         }
//       }
//     }
//   ]
// }
