import { ArrowBack } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Stack, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router';
import LocationTextField from '../components/LocationTextField';
import Medications from '../components/Medications';
import OnboardingLayoutOld from '../components/OnboardingLayoutOld';
import Procedures from '../components/Procedures';
import Symptoms from '../components/Symptoms';
import { AuthContext } from '../contexts/Auth';
import { ProfilesContext } from '../contexts/Profiles';
import { SignupContext } from '../contexts/Signup';
import { track } from '../utils/analytics';

const FORM = {
  width: '100%',
};

const SignupStep3 = () => {
  const { isAuthenticated } = useContext(AuthContext);

  const {
    location,
    setLocation,
    setBirthDate,
    loading,
    birthDate,
    createProfile,
    medications,
    setMedications,
    symptoms,
    setSymptoms,
    procedures,
    setProcedures,
  } = useContext(SignupContext);

  const { profiles } = useContext(ProfilesContext);

  const navigate = useNavigate();

  const canContinue = () => {
    if (birthDate === null) return false;
    if (medications.length > 0) return true;
    return false;
  };

  useEffect(() => {
    if (isAuthenticated && profiles && profiles.length > 0) {
      navigate('/dashboard/home');
    }
  }, [isAuthenticated, profiles, navigate]);

  return (
    <OnboardingLayoutOld>
      <Stack direction={'column'} spacing={2} sx={FORM}>
        <Button
          startIcon={<ArrowBack />}
          sx={{ width: 'fit-content' }}
          onClick={() => navigate('/signup/step2')}
        >
          Back
        </Button>
        <Typography sx={{ fontSize: 32, fontWeight: 600, color: 'gray.800' }}>
          Speak About New Treatment Options Now
        </Typography>
        <Typography sx={{ fontSize: 16, fontWeight: 400, color: 'gray.800' }}>
          {'Answer these questions to help our care team better assist you'}
        </Typography>
        <div></div>
        <Medications setValue={setMedications} value={medications} />
        <Symptoms setValue={setSymptoms} value={symptoms} />
        <Procedures setValue={setProcedures} value={procedures} />
        <TextField
          label="Date of birth *"
          fullWidth
          type={'date'}
          InputLabelProps={{ shrink: true }}
          onChange={(e) => {
            setBirthDate(e.target.value);
            track('signup_step_3_birth_date_selected', {
              birthDate: e.target.value,
            });
          }}
        />
        <LocationTextField value={location} setValue={setLocation} />
        <p style={{ fontSize: 11, fontStyle: 'italic', textAlign: 'left' }}>
          All personal data submitted is encrypted and is not shared for any
          other purposes other than helping you find potential new treatment
          options. For more information on this, please see our privacy policy{' '}
          <a
            target="_blank"
            href="https://knowrare.com/privacy"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </p>
        <div></div>
        <LoadingButton
          onClick={() => {
            createProfile(true);
            track('signup_step_3_submit_button_click');
          }}
          variant="contained"
          size="large"
          disabled={!canContinue()}
          loading={loading}
        >
          Submit
        </LoadingButton>
        <LoadingButton
          sx={{ width: '100%', color: 'black', fontWeight: 500 }}
          size="large"
          loading={loading}
          onClick={() => {
            createProfile();
            track('signup_step_3_finish_button_click');
          }}
        >
          Skip
        </LoadingButton>
      </Stack>
    </OnboardingLayoutOld>
  );
};

export default SignupStep3;
