export const medicationsList = [
  { name: 'Ligand' },
  { name: 'asenapine' },
  { name: 'apomorphine' },
  { name: 'aripiprazole' },
  { name: 'bromocriptine' },
  { name: 'buspirone' },
  { name: 'cabergoline' },
  { name: 'clozapine' },
  { name: 'eletriptan' },
  { name: 'naratriptan' },
  { name: 'olanzapine' },
  { name: 'quetiapine' },
  { name: 'rizatriptan' },
  { name: 'sumatriptan' },
  { name: 'ziprasidone' },
  { name: 'zolmitriptan' },
  { name: 'chlorpromazine' },
  { name: 'haloperidol' },
  { name: 'pimozide' },
  { name: 'pindolol' },
  { name: 'risperidone' },
  { name: 'sertindole' },
  { name: 'thioridazine' },
  { name: 'yohimbine' },
  { name: 'dihydroergotamine' },
  { name: 'oxymetazoline' },
  { name: 'methysergide' },
  { name: 'mianserin' },
  { name: 'ergotamine' },
  { name: 'agomelatine' },
  { name: 'amitriptyline' },
  { name: 'amoxapine' },
  { name: 'duloxetine' },
  { name: 'fluoxetine' },
  { name: 'fluphenazine' },
  { name: 'loxapine' },
  { name: 'molindone' },
  { name: 'perphenazine' },
  { name: 'thiothixene' },
  { name: 'trazodone' },
  { name: 'trifluoperazine' },
  { name: 'melatonin' },
  { name: 'cisapride' },
  { name: 'metoclopramide' },
  { name: 'tropisetron' },
  { name: 'cyproheptadine' },
  { name: 'bethanechol' },
  { name: 'carbachol' },
  { name: 'pilocarpine' },
  { name: 'N-methyl scopolamine' },
  { name: 'atropine' },
  { name: 'darifenacin' },
  { name: 'ipratropium' },
  { name: 'pirenzepine' },
  { name: 'propantheline' },
  { name: 'scopolamine' },
  { name: 'dexetimide' },
  { name: 'dicyclomine' },
  { name: 'imipramine' },
  { name: 'oxybutynin' },
  { name: 'clidinium' },
  { name: 'tiotropium' },
  { name: 'caffeine' },
  { name: 'theophylline' },
  { name: '(-)-adrenaline' },
  { name: 'methoxamine' },
  { name: 'phenylephrine' },
  { name: 'tamsulosin' },
  { name: 'silodosin' },
  { name: 'phentolamine' },
  { name: 'prazosin' },
  { name: '(-)-noradrenaline' },
  { name: 'clonidine' },
  { name: 'brimonidine (UK14304)' },
  { name: 'dexmedetomidine' },
  { name: 'guanfacine' },
  { name: 'dobutamine' },
  { name: 'isoprenaline' },
  { name: 'atenolol' },
  { name: 'betaxolol' },
  { name: 'bupranolol' },
  { name: 'carvedilol' },
  { name: 'metoprolol' },
  { name: 'nadolol' },
  { name: 'practolol' },
  { name: 'ephedrine' },
  { name: 'fenoterol' },
  { name: 'salbutamol' },
  { name: 'salmeterol' },
  { name: 'terbutaline' },
  { name: 'alprenolol' },
  { name: 'propranolol' },
  { name: 'timolol' },
  { name: 'bunolol' },
  { name: 'candesartan' },
  { name: 'eprosartan' },
  { name: 'irbesartan' },
  { name: 'losartan' },
  { name: 'olmesartan' },
  { name: 'telmisartan' },
  { name: 'chenodeoxycholic acid' },
  { name: 'icatibant' },
  { name: 'calcitonin' },
  { name: 'maraviroc' },
  { name: 'plerixafor' },
  { name: 'fenoldopam' },
  { name: 'dopamine' },
  { name: 'rotigotine' },
  { name: 'flupentixol' },
  { name: 'pramipexole' },
  { name: '(-)-sulpiride' },
  { name: 'amisulpride' },
  { name: 'domperidone' },
  { name: '17&beta;-estradiol' },
  { name: 'fulvestrant' },
  { name: 'tamoxifen' },
  { name: 'cyclosporin A' },
  { name: 'rosiglitazone' },
  { name: 'baclofen' },
  { name: 'liraglutide' },
  { name: 'exendin-4' },
  { name: 'glucagon' },
  { name: 'FSH' },
  { name: 'LH' },
  { name: 'leuprolide' },
  { name: 'abarelix' },
  { name: 'cetrorelix' },
  { name: '(<i>R</i>)-cetirizine' },
  { name: 'cetirizine' },
  { name: 'diphenhydramine' },
  { name: 'doxepin' },
  { name: 'mepyramine' },
  { name: 'triprolidine' },
  { name: 'cimetidine' },
  { name: 'ranitidine' },
  { name: 'ramelteon' },
  { name: '(-)-pentazocine' },
  { name: 'fentanyl' },
  { name: 'morphine' },
  { name: 'nalmefene' },
  { name: 'nalorphine' },
  { name: 'naloxone' },
  { name: 'naltrexone' },
  { name: 'nalbuphine' },
  { name: 'buprenorphine' },
  { name: 'codeine' },
  { name: 'ticagrelor' },
  { name: 'cangrelor' },
  { name: 'PGE<sub>1</sub>' },
  { name: 'PGE<sub>2</sub>' },
  { name: 'iloprost' },
  { name: 'PGI<sub>2</sub>' },
  { name: 'misoprostol (methyl ester)' },
  { name: 'bimatoprost' },
  { name: 'latanoprost (free acid form)' },
  { name: 'latanoprost (isopropyl ester)' },
  { name: 'pasireotide' },
  { name: 'lanreotide' },
  { name: 'vapreotide' },
  { name: 'TRH' },
  { name: 'dexamfetamine' },
  { name: 'vasopressin' },
  { name: 'oxytocin' },
  { name: 'conivaptan' },
  { name: 'tolvaptan' },
  { name: 'cocaine' },
  { name: 'ondansetron' },
  { name: 'alosetron' },
  { name: 'diltiazem' },
  { name: 'granisetron' },
  { name: 'nitrendipine' },
  { name: 'colchicine' },
  { name: 'progesterone' },
  { name: 'clomipramine' },
  { name: 'desipramine' },
  { name: 'halothane' },
  { name: 'maprotiline' },
  { name: 'nortriptyline' },
  { name: 'verapamil' },
  { name: 'fingolimod' },
  { name: 'glibenclamide' },
  { name: 'fampridine' },
  { name: 'amiloride' },
  { name: '&Delta;<sup>9</sup>-tetrahydrocannabinol' },
  { name: '(-)-menthol' },
  { name: 'capsaicin' },
  { name: 'NO' },
  { name: 'nimodipine' },
  { name: 'propafenone' },
  { name: 'sibutramine' },
  { name: 'mefenamic acid' },
  { name: 'retigabine' },
  { name: 'astemizole' },
  { name: 'dofetilide' },
  { name: 'terfenadine' },
  { name: 'lidocaine' },
  { name: 'phenytoin' },
  { name: 'mexiletine' },
  { name: 'triiodothyronine' },
  { name: 'T<sub>4</sub>' },
  { name: 'tretinoin' },
  { name: 'alitretinoin' },
  { name: 'tamibarotene' },
  { name: 'clofibrate' },
  { name: 'bezafibrate' },
  { name: 'troglitazone' },
  { name: 'pioglitazone' },
  { name: 'mesalazine' },
  { name: 'ibuprofen' },
  { name: 'diclofenac' },
  { name: 'lovastatin' },
  { name: 'dexamethasone' },
  { name: 'paclitaxel' },
  { name: 'calcipotriol' },
  { name: '1,25-dihydroxyvitamin D3' },
  { name: 'paricalcitol' },
  { name: 'diethylstilbestrol' },
  { name: 'mifepristone' },
  { name: 'bexarotene' },
  { name: 'estrone' },
  { name: 'raloxifene' },
  { name: 'estriol' },
  { name: 'adenosine' },
  { name: 'fluoxymesterone' },
  { name: 'bicalutamide' },
  { name: 'nilutamide' },
  { name: 'cyproterone acetate' },
  { name: 'prednisolone' },
  { name: 'cortisol' },
  { name: 'triamcinolone' },
  { name: 'fludrocortisone' },
  { name: 'drospirenone' },
  { name: 'spironolactone' },
  { name: 'eplerenone' },
  { name: 'dydrogesterone' },
  { name: 'medroxyprogesterone' },
  { name: 'norethisterone' },
  { name: 'levonorgestrel' },
  { name: 'suvorexant' },
  { name: 'celecoxib' },
  { name: 'rofecoxib' },
  { name: 'valdecoxib' },
  { name: 'etoricoxib' },
  { name: 'lumiracoxib' },
  { name: 'lorcaserin' },
  { name: 'atorvastatin' },
  { name: 'cerivastatin' },
  { name: 'fluvastatin' },
  { name: 'pravastatin' },
  { name: 'rosuvastatin' },
  { name: 'simvastatin' },
  { name: 'ibandronic acid' },
  { name: 'alendronate' },
  { name: 'risedronate' },
  { name: 'zoledronic acid' },
  { name: 'cinacalcet' },
  { name: 'zafirlukast' },
  { name: 'montelukast' },
  { name: 'diazepam' },
  { name: 'obeticholic acid' },
  { name: 'ciprofibrate' },
  { name: 'gemfibrozil' },
  { name: 'procaterol' },
  { name: 'formoterol' },
  { name: 'aprepitant' },
  { name: 'bosentan' },
  { name: 'sufentanil' },
  { name: 'pranlukast' },
  { name: 'secretin' },
  { name: 'goserelin' },
  { name: 'nafarelin' },
  { name: 'TSH' },
  { name: 'valsartan' },
  { name: 'sitaxsentan' },
  { name: 'ambrisentan' },
  { name: 'mecamylamine' },
  { name: 'pancuronium' },
  { name: 'succinylcholine' },
  { name: 'vorapaxar' },
  { name: 'amantadine' },
  { name: 'aspirin' },
  { name: 'clomiphene' },
  { name: 'cyclothiazide' },
  { name: 'dantrolene' },
  { name: 'flumazenil' },
  { name: 'flunitrazepam' },
  { name: 'flurbiprofen' },
  { name: 'heparin' },
  { name: 'ketamine' },
  { name: 'lubiprostone' },
  { name: 'nafamostat' },
  { name: 'omeprazole' },
  { name: 'triamterene' },
  { name: 'zolpidem' },
  { name: 'probenecid' },
  { name: 'teriparatide' },
  { name: 'canagliflozin' },
  { name: 'dapagliflozin' },
  { name: 'digoxin' },
  { name: 'sildenafil' },
  { name: 'empagliflozin' },
  { name: 'paroxetine' },
  { name: 'nomifensine' },
  { name: 'gemcitabine' },
  { name: 'ketoprofen' },
  { name: 'mazindol' },
  { name: 'sertraline' },
  { name: 'fludarabine' },
  { name: 'pentostatin' },
  { name: 'dipyridamole' },
  { name: 'reboxetine' },
  { name: 'aliskiren' },
  { name: 'methotrexate' },
  { name: 'tiagabine' },
  { name: 'fexofenadine' },
  { name: 'fenoprofen' },
  { name: 'vigabatrin' },
  { name: 'reserpine' },
  { name: 'tetrabenazine' },
  { name: 'chlorothiazide' },
  { name: 'hydrochlorothiazide' },
  { name: 'bumetanide' },
  { name: 'metolazone' },
  { name: 'furosemide' },
  { name: 'miglustat' },
  { name: 'miglitol' },
  { name: 'adalimumab' },
  { name: 'crizotinib' },
  { name: 'erlotinib' },
  { name: 'gefitinib' },
  { name: 'growth hormone 1' },
  { name: 'imiquimod' },
  { name: 'infliximab' },
  { name: 'insulin' },
  { name: 'linaclotide' },
  { name: 'pertuzumab' },
  { name: 'trastuzumab' },
  { name: 'methyltyrosine' },
  { name: 'anastrozole' },
  { name: 'captopril' },
  { name: 'carbidopa' },
  { name: 'cilastatin' },
  { name: 'eflornithine' },
  { name: 'fasudil' },
  { name: 'letrozole' },
  { name: 'Li<sup>+</sup>' },
  { name: 'metyrapone' },
  { name: 'milrinone' },
  { name: 'naproxen' },
  { name: 'sapropterin' },
  { name: 'orlistat' },
  { name: 'tranylcypromine' },
  { name: 'zileuton' },
  { name: 'adapalene' },
  { name: 'guanabenz' },
  { name: 'methadone' },
  { name: 'varenicline' },
  { name: 'esomeprazole' },
  { name: 'sulpiride' },
  { name: 'degarelix' },
  { name: 'regadenoson' },
  { name: 'quizartinib' },
  { name: 'axitinib' },
  { name: 'selumetinib' },
  { name: 'afatinib' },
  { name: 'tofacitinib' },
  { name: 'dasatinib' },
  { name: 'neratinib' },
  { name: 'imatinib' },
  { name: 'ruxolitinib' },
  { name: 'lapatinib' },
  { name: 'nilotinib' },
  { name: 'pazopanib' },
  { name: 'midostaurin' },
  { name: 'bosutinib' },
  { name: 'sorafenib' },
  { name: 'sunitinib' },
  { name: 'fedratinib' },
  { name: 'vandetanib' },
  { name: 'treprostinil' },
  { name: 'sufinpyrazone' },
  { name: 'cabozantinib' },
  { name: 'everolimus' },
  { name: 'ponatinib' },
  { name: 'regorafenib' },
  { name: 'temsirolimus' },
  { name: 'vemurafenib' },
  { name: 'nintedanib' },
  { name: 'sirolimus' },
  { name: 'tivozanib' },
  { name: 'clemastine' },
  { name: 'sitagliptin' },
  { name: 'vildagliptin' },
  { name: 'saxagliptin' },
  { name: 'linagliptin' },
  { name: 'alogliptin' },
  { name: 'enalapril' },
  { name: 'enalaprilat' },
  { name: 'ramipril' },
  { name: 'ramiprilat' },
  { name: 'quinaprilat' },
  { name: 'lisinopril' },
  { name: 'perindopril' },
  { name: 'perindoprilat' },
  { name: 'benazepril' },
  { name: 'benazeprilat' },
  { name: 'imidapril' },
  { name: 'imidaprilat' },
  { name: 'argatroban' },
  { name: 'rivaroxaban' },
  { name: 'apixaban' },
  { name: 'bortezomib' },
  { name: 'camostat' },
  { name: 'sivelestat' },
  { name: 'trandolapril' },
  { name: 'trandolaprilat' },
  { name: 'fosinopril' },
  { name: 'desirudin' },
  { name: 'cilazapril' },
  { name: 'cilazaprilat' },
  { name: 'zofenopril' },
  { name: 'doxycycline' },
  { name: 'lepirudin' },
  { name: 'bivalirudin' },
  { name: 'mogamulizumab' },
  { name: 'dabrafenib' },
  { name: 'trametinib' },
  { name: 'aprotinin' },
  { name: 'moexipril' },
  { name: 'tranexamic acid' },
  { name: '6-aminocaproic acid' },
  { name: 'spirapril' },
  { name: 'spiraprilat' },
  { name: 'abciximab' },
  { name: 'eptifibatide' },
  { name: 'tirofiban' },
  { name: 'natalizumab' },
  { name: 'efalizumab' },
  { name: 'physostigmine' },
  { name: 'donepezil' },
  { name: 'rivastigmine' },
  { name: 'selegiline' },
  { name: 'rasagiline' },
  { name: 'tolcapone' },
  { name: 'entacapone' },
  { name: 'methimazole' },
  { name: 'propylthiouracil' },
  { name: 'ketorolac' },
  { name: 'tacrine' },
  { name: 'fluticasone' },
  { name: 'idelalisib' },
  { name: 'alirocumab' },
  { name: 'alemtuzumab' },
  { name: 'bevacizumab' },
  { name: 'brentuximab vedotin' },
  { name: 'canakinumab' },
  { name: 'certolizumab pegol' },
  { name: 'gemtuzumab ozogamicin' },
  { name: 'golimumab' },
  { name: 'ibritumomab tiuxetan' },
  { name: 'ofatumumab' },
  { name: 'ranibizumab' },
  { name: 'rituximab' },
  { name: 'tositumomab' },
  { name: 'digitoxin' },
  { name: 'pimecrolimus' },
  { name: 'tacrolimus' },
  { name: 'vincristine' },
  { name: 'aflibercept' },
  { name: 'alefacept' },
  { name: 'drotrecogin alfa' },
  { name: 'etanercept' },
  { name: 'rilonacept' },
  { name: 'acetazolamide' },
  { name: 'acetyldigitoxin' },
  { name: 'allopurinol' },
  { name: 'brinzolamide' },
  { name: 'cabazitaxel' },
  { name: 'capecitabine' },
  { name: 'carmustine' },
  { name: 'clofarabine' },
  { name: 'dalteparin' },
  { name: 'danaparoid' },
  { name: 'deslanoside' },
  { name: 'dicumarol' },
  { name: 'docetaxel' },
  { name: 'enoxaparin' },
  { name: 'enzalutamide' },
  { name: 'eribulin' },
  { name: 'ethoxzolamide' },
  { name: 'etoposide' },
  { name: 'ezetimibe' },
  { name: 'febuxostat' },
  { name: 'finasteride' },
  { name: 'fondaparinux' },
  { name: 'hydroxyurea' },
  { name: 'irinotecan' },
  { name: 'ixabepilone' },
  { name: 'leflunomide' },
  { name: 'levetiracetam' },
  { name: 'methazolamide' },
  { name: 'mycophenolate mofetil' },
  { name: 'mycophenolic acid' },
  { name: 'nitisinone' },
  { name: 'oxiglutatione' },
  { name: 'pegaptanib' },
  { name: 'pemetrexed' },
  { name: 'phenindione' },
  { name: 'phenprocoumon' },
  { name: 'pralatrexate' },
  { name: 'repaglinide' },
  { name: 'ribavirin' },
  { name: 'teriflunomide' },
  { name: 'thioguanine' },
  { name: 'tinzaparin' },
  { name: 'tolbutamide' },
  { name: 'trilostane' },
  { name: 'vinblastine' },
  { name: 'vorinostat' },
  { name: 'warfarin' },
  { name: 'daclizumab' },
  { name: 'tocilizumab' },
  { name: 'cetuximab' },
  { name: 'panitumumab' },
  { name: 'eculizumab' },
  { name: 'ustekinumab' },
  { name: 'denosumab' },
  { name: 'belimumab' },
  { name: 'ipilimumab' },
  { name: 'muromonab-CD3' },
  { name: 'omalizumab' },
  { name: 'abatacept' },
  { name: 'belatacept' },
  { name: 'ibrutinib' },
  { name: 'obinutuzumab' },
  { name: 'danazol' },
  { name: 'flutamide' },
  { name: 'dromostanolone propionate' },
  { name: 'ethylestrenol' },
  { name: 'nandrolone' },
  { name: 'dextrothyroxine' },
  { name: 'tazarotene' },
  { name: 'palifermin' },
  { name: 'alpha-methyltyrosine' },
  { name: 'tesamorelin' },
  { name: 'anisindione' },
  { name: 'eltrombopag' },
  { name: 'roflumilast' },
  { name: 'clonazepam' },
  { name: 'cosyntropin' },
  { name: 'corticotropin zinc hydroxide' },
  { name: 'pegfilgrastim' },
  { name: 'oprelvekin' },
  { name: 'anakinra' },
  { name: 'calcitonin (salmon)' },
  { name: 'romiplostim' },
  { name: 'vismodegib' },
  { name: 'chlorpheniramine' },
  { name: 'sermorelin' },
  { name: 'romidepsin' },
  { name: 'tazemetostat' },
  { name: 'teduglutide' },
  { name: 'aminoglutethimide' },
  { name: 'beclometasone' },
  { name: 'betamethasone' },
  { name: 'clobetasol propionate' },
  { name: 'desoximetasone' },
  { name: 'diflorasone diacetate' },
  { name: 'ethinylestradiol' },
  { name: 'exemestane' },
  { name: 'famotidine' },
  { name: 'flunisolide' },
  { name: 'fluocinolone acetonide' },
  { name: 'fluocinonide' },
  { name: 'fluorometholone' },
  { name: 'fluticasone propionate' },
  { name: 'hydrocodone' },
  { name: 'hydromorphone' },
  { name: 'methylprednisolone' },
  { name: 'prednisone' },
  { name: 'testosterone propionate' },
  { name: 'acebutolol' },
  { name: 'alfuzosin' },
  { name: 'anagrelide' },
  { name: 'apraclonidine' },
  { name: 'atomoxetine' },
  { name: 'azelastine' },
  { name: 'benzquinamide' },
  { name: 'biperiden' },
  { name: 'bromfenac' },
  { name: 'bupropion' },
  { name: 'carprofen' },
  { name: 'chlorthalidone' },
  { name: 'cilostazol' },
  { name: 'cyclizine' },
  { name: 'dapiprazole' },
  { name: 'desloratadine' },
  { name: 'desvenlafaxine' },
  { name: 'doxazosin' },
  { name: 'epinastine' },
  { name: 'escitalopram' },
  { name: 'esmolol' },
  { name: 'etodolac' },
  { name: 'fenofibrate' },
  { name: 'fluvoxamine' },
  { name: 'frovatriptan' },
  { name: 'hydroxychloroquine' },
  { name: 'hydroxyzine' },
  { name: 'ibutilide' },
  { name: 'inamrinone' },
  { name: 'ketotifen' },
  { name: 'labetalol' },
  { name: 'levallorphan' },
  { name: 'loperamide' },
  { name: 'loratadine' },
  { name: 'meclofenamic acid' },
  { name: 'meloxicam' },
  { name: 'pethidine' },
  { name: 'methylphenidate' },
  { name: 'mirtazapine' },
  { name: 'nefazodone' },
  { name: 'orciprenaline' },
  { name: 'oxaprozin' },
  { name: 'pamidronic acid' },
  { name: 'phenelzine' },
  { name: 'phenoxybenzamine' },
  { name: 'phenylbutazone' },
  { name: 'piroxicam' },
  { name: 'probucol' },
  { name: 'prochlorperazine' },
  { name: 'promazine' },
  { name: 'promethazine' },
  { name: 'protriptyline' },
  { name: 'ropinirole' },
  { name: 'sotalol' },
  { name: 'suprofen' },
  { name: 'tadalafil' },
  { name: 'terazosin' },
  { name: 'testolactone' },
  { name: 'tolazoline' },
  { name: 'triazolam' },
  { name: 'trihexyphenidyl' },
  { name: 'trimipramine' },
  { name: 'tripelennamine' },
  { name: 'tropicamide' },
  { name: 'vardenafil' },
  { name: 'venlafaxine' },
  { name: 'nivolumab' },
  { name: 'flutemetamol' },
  { name: 'evolocumab' },
  { name: 'florbetapir' },
  { name: 'ospemifene' },
  { name: 'vortioxetine' },
  { name: 'macitentan' },
  { name: 'vilanterol' },
  { name: 'bazedoxifene' },
  { name: 'simeprevir' },
  { name: 'apremilast' },
  { name: 'palbociclib' },
  { name: 'ribociclib' },
  { name: 'catumaxomab' },
  { name: 'lixisenatide' },
  { name: 'ramucirumab' },
  { name: 'tasimelteon' },
  { name: 'daratumumab' },
  { name: 'siltuximab' },
  { name: 'ceritinib' },
  { name: 'ibudilast' },
  { name: 'nimesulide' },
  { name: 'raltitrexed' },
  { name: 'tolrestat' },
  { name: 'lenvatinib' },
  { name: 'vilazodone' },
  { name: 'moclobemide' },
  { name: 'budesonide' },
  { name: 'levomilnacipran' },
  { name: 'milnacipran' },
  { name: 'vedolizumab' },
  { name: 'ingenol mebutate' },
  { name: 'mirabegron' },
  { name: 'peginesatide' },
  { name: 'aclidinium' },
  { name: 'tafluprost' },
  { name: 'crofelemer' },
  { name: 'indacaterol' },
  { name: 'glycopyrrolate' },
  { name: 'ulipristal acetate' },
  { name: 'lurasidone' },
  { name: 'peginterferon alfa-2b' },
  { name: 'ciclesonide' },
  { name: 'alvimopan' },
  { name: 'lacosamide' },
  { name: 'difluprednate' },
  { name: 'lorlatinib' },
  { name: 'tapentadol' },
  { name: 'solifenacin' },
  { name: 'pegvisomant' },
  { name: 'palonosetron' },
  { name: 'alpha1-proteinase inhibitor' },
  { name: 'pembrolizumab' },
  { name: 'olaparib' },
  { name: 'lifitegrast' },
  { name: 'brodalumab' },
  { name: 'ixekizumab' },
  { name: 'olodaterol' },
  { name: 'citalopram' },
  { name: 'dosulepin' },
  { name: 'lofepramine' },
  { name: 'dexmethylphenidate' },
  { name: 'perospirone' },
  { name: 'pipotiazine' },
  { name: 'methylnaltrexone' },
  { name: 'dupilumab' },
  { name: 'edoxaban' },
  { name: 'ocrelizumab' },
  { name: 'butorphanol' },
  { name: 'levorphanol' },
  { name: 'pralidoxime' },
  { name: 'cobimetinib' },
  { name: 'blonanserin' },
  { name: 'moxetumomab pasudotox' },
  { name: 'mepolizumab' },
  { name: 'capivasertib' },
  { name: 'rucaparib' },
  { name: 'alectinib' },
  { name: 'brigatinib' },
  { name: 'florbetaben' },
  { name: 'momelotinib' },
  { name: 'baricitinib' },
  { name: 'pacritinib' },
  { name: 'duvelisib' },
  { name: 'gabexate' },
  { name: 'infigratinib' },
  { name: 'dapoxetine' },
  { name: 'capmatinib' },
  { name: 'filgotinib' },
  { name: 'binimetinib' },
  { name: 'alpelisib' },
  { name: 'dinutuximab' },
  { name: 'durvalumab' },
  { name: 'atezolizumab' },
  { name: 'inebilizumab' },
  { name: 'sarilumab' },
  { name: 'tralokinumab' },
  { name: 'secukinumab' },
  { name: 'necitumumab' },
  { name: 'reslizumab' },
  { name: 'romosozumab' },
  { name: 'tildrakizumab' },
  { name: 'flibanserin' },
  { name: 'sonidegib' },
  { name: 'glasdegib' },
  { name: 'sacituzumab govitecan' },
  { name: 'anifrolumab' },
  { name: 'inotuzumab ozogamicin' },
  { name: 'niraparib' },
  { name: 'palovarotene' },
  { name: 'safinamide' },
  { name: 'tepotinib' },
  { name: 'idarucizumab' },
  { name: 'abaloparatide' },
  { name: 'tucidinostat' },
  { name: 'fadrozole' },
  { name: 'sotagliflozin' },
  { name: 'talazoparib' },
  { name: 'venetoclax' },
  { name: 'aducanumab' },
  { name: 'elotuzumab' },
  { name: 'elagolix' },
  { name: 'ertugliflozin' },
  { name: 'polatuzumab vedotin' },
  { name: 'pimavanserin' },
  { name: 'sparsentan' },
  { name: 'ixazomib' },
  { name: 'tremelimumab' },
  { name: 'mavorixafor' },
  { name: 'margetuximab' },
  { name: 'valbenazine' },
  { name: 'deutetrabenazine' },
  { name: 'ozanimod' },
  { name: 'pexidartinib' },
  { name: 'brolucizumab' },
  { name: 'evinacumab' },
  { name: 'isatuximab' },
  { name: 'nemolizumab' },
  { name: 'avelumab' },
  { name: 'acalabrutinib' },
  { name: 'umbralisib' },
  { name: 'ublituximab' },
  { name: 'risankizumab' },
  { name: 'pitolisant' },
  { name: 'tezepelumab' },
  { name: 'galcanezumab' },
  { name: 'opicapone' },
  { name: 'mometasone' },
  { name: 'brivaracetam' },
  { name: 'apalutamide' },
  { name: 'difelikefalin' },
  { name: 'enoximone' },
  { name: 'satralizumab' },
  { name: 'lanadelumab' },
  { name: 'caplacizumab' },
  { name: 'naldemedine' },
  { name: 'olaratumab' },
  { name: 'fremanezumab' },
  { name: 'ivosidenib' },
  { name: 'upadacitinib' },
  { name: 'vamorolone' },
  { name: 'erenumab' },
  { name: 'tirabrutinib' },
  { name: 'siponimod' },
  { name: 'burosumab' },
  { name: 'emapalumab' },
  { name: 'lemborexant' },
  { name: 'ponesimod' },
  { name: 'etrasimod' },
  { name: 'ipragliflozin' },
  { name: 'tofogliflozin' },
  { name: 'bimekizumab' },
  { name: 'itolizumab' },
  { name: 'leniolisib' },
  { name: 'fruquintinib' },
  { name: 'relatlimab' },
  { name: 'belumosudil' },
  { name: 'ritlecitinib' },
  { name: 'tislelizumab' },
  { name: 'betrixaban' },
  { name: 'delgocitinib' },
  { name: 'tapinarof' },
  { name: 'semaglutide' },
  { name: 'macimorelin' },
  { name: 'sutimlimab' },
  { name: 'mirikizumab' },
  { name: 'teneligliptin' },
  { name: 'tucatinib' },
  { name: 'trifarotene' },
  { name: 'lusutrombopag' },
  { name: 'pralsetinib' },
  { name: 'cemiplimab' },
  { name: 'vibegron' },
  { name: 'mizolastine' },
  { name: 'rupatadine' },
  { name: 'ravulizumab' },
  { name: 'ibalizumab' },
  { name: 'fezolinetant' },
  { name: 'ripasudil' },
  { name: 'deucravacitinib' },
  { name: 'darolutamide' },
  { name: 'envafolimab' },
  { name: 'amivantamab' },
  { name: 'teprotumumab' },
  { name: 'eptinezumab' },
  { name: 'rimegepant' },
  { name: 'enfortumab vedotin' },
  { name: 'tafasitamab' },
  { name: 'clascoterone' },
  { name: 'naxitamab' },
  { name: 'voclosporin' },
  { name: 'dotinurad' },
  { name: 'faricimab' },
  { name: 'dostarlimab' },
  { name: 'tisotumab vedotin' },
  { name: 'bexagliflozin' },
  { name: 'cadonilimab' },
  { name: 'sintilimab' },
  { name: 'divozilimab' },
];
