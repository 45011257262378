import { Box, Stack, Typography } from '@mui/material';
import moment from 'moment-timezone';
import React, { useContext } from 'react';
import { ProfileContext } from '../contexts/Profile';
import useResponsive from '../hooks/useResponsive';
import { EVENTS } from './AddOrEditEventDialog';

const NewEventHeader = ({ event, fromDialog = false }) => {
  const isDesktop = useResponsive('up', 'md');
  const { customEvents } = useContext(ProfileContext);

  const getEventById = (id = '123') => {
    return [...EVENTS, ...customEvents].find((e) => e.id === id);
  };

  return (
    <Stack
      direction={'column'}
      spacing={1}
      alignItems={'start'}
      sx={{ width: '100%', height: '100%' }}
    >
      <Stack
        direction={'row'}
        spacing={1}
        alignItems={'center'}
        sx={{ width: '100%', display: 'flex', height: 32 }}
      >
        <Typography sx={{ textAlign: 'left', fontSize: 14 }}>
          {moment(event.data().submittedAt.seconds * 1000).format('D MMM, YY')}
        </Typography>
        <div style={{ flexGrow: 1 }}></div>
        <Stack direction={'row'} alignItems={'center'} spacing={0.5}>
          <Box
            sx={{
              pl: 1,
              pr: 1,
              pt: 0.5,
              pb: 0.5,
              borderRadius: 1,
              fontSize: 24,
            }}
          >
            {event.data().emoji}
          </Box>
          {/* <IconButton onClick={() => setOpenEditEventDialog(true)}>
            <Edit />
          </IconButton> */}
        </Stack>
      </Stack>
      <Typography
        sx={{
          textAlign: 'left',
          color: '#0B3954',
          fontSize: 16,
          fontWeight: 500,
          maxWidth: 480,
        }}
      >
        {event.data().description}
      </Typography>
      <Stack direction={'row'} sx={{ flexWrap: 'wrap' }}>
        {event.data().events.map((e, i) => (
          <Box
            key={i}
            sx={{
              bgcolor: getEventById(e)?.color,
              pl: 1.5,
              pr: 1.5,
              pt: 0.5,
              pb: 0.5,
              borderRadius: 4,
              mr: 1,
              mt: 1,
            }}
          >
            <Typography
              sx={{ color: getEventById(e)?.textColor, fontSize: 14 }}
            >
              {getEventById(e)?.name}
            </Typography>
          </Box>
        ))}
      </Stack>
    </Stack>
  );
};

export default NewEventHeader;
