// ----------------------------------------------------------------------

export default function TextField(theme) {
  return {
    MuiFilledInput: {
      styleOverrides: {
        root: {
          'borderRadius': 16,
          'br': 2,
          // background: '#EAEBEF',
          'background': '#F6F6F6',
          'color': 'black',
          // boxShadow: '0px 4px 12px rgba(0,0,0,0.1)',
          // boxShadow: 'rgb(221 221 224 / 84%) 0px 3px 8px',
          '&:hover': {
            background: '#F6F6F6',
            // background: '#EAEBEF',
            // backgroundColor: '#EAEBEF',
            color: 'black',
          },
          '&:focused': {
            background: '#F6F6F6',
            // background: '#EAEBEF',
            // backgroundColor: '#EAEBEF',
            color: '#111111',
          },
          '&.Mui-focused': {
            // background: '#EAEBEF',
            // backgroundColor: '#EAEBEF',
            background: '#F6F6F6',
          },
        },
        input: {
          'color': '#111111',
          'borderRadius': 16,
          // backgroundColor: '#EAEBEF',
          'background': '#F6F6F6',
          '&:hover': {
            color: '#111111',
            background: '#F6F6F6',
            // backgroundColor: '#EAEBEF'
          },
          '&:focused': {
            color: '#111111',
            // backgroundColor: '#EAEBEF',
            background: '#F6F6F6',
          },
          '&.Mui-focused': {
            background: '#EAEBEF',
            // backgroundColor: '#EAEBEF',
            background: '#F6F6F6',
          },
        },
        underline: {
          '&&&:before': {
            borderBottom: 'none',
          },
          '&&:after': {
            borderBottom: 'none',
          },
        },
      },
    },
  };
}
