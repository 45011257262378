/* eslint-disable quotes */
import { Stack } from '@mui/material';
import * as React from 'react';
import CustomizedDialogs from '../Dialog';
import { legalData } from './legalData';

export default function LegalDialog({ open, setOpen }) {
  return (
    <CustomizedDialogs open={open !== false} setOpen={setOpen} fullScreen>
      <Stack
        direction={'column'}
        justifyContent={'start'}
        alignItems={'center'}
        sx={{ width: '100%', height: '100%' }}
        spacing={6}
      >
        {open === 'privacy' && (
          <div dangerouslySetInnerHTML={{ __html: legalData.privacyText }} />
        )}
        {open === 'terms' && (
          <div dangerouslySetInnerHTML={{ __html: legalData.termsText }} />
        )}
        {open === 'hipaa' && (
          <div dangerouslySetInnerHTML={{ __html: legalData.hipaaText }} />
        )}
        {open === 'ccpa' && (
          <div dangerouslySetInnerHTML={{ __html: legalData.ccpaText }} />
        )}
      </Stack>
    </CustomizedDialogs>
  );
}
