import { Stack, Typography } from '@mui/material';
import moment from 'moment-timezone';
import React from 'react';

const CardHeader = ({ event, fromDialog = false }) => {
  return (
    <Stack
      direction={'column'}
      spacing={1}
      alignItems={'start'}
      sx={{ width: '100%', height: '100%', flexGrow: 1 }}
    >
      <Stack
        direction={'row'}
        spacing={1.5}
        alignItems={'center'}
        sx={{ width: '100%', display: 'flex', height: 32 }}
      >
        <img
          src={require('../../assets/icons/journal.png')}
          alt="journal"
          width={32}
          height={32}
        />
        <Typography
          sx={{ textAlign: 'left', fontSize: 18, fontWeight: 600, flexGrow: 1 }}
        >
          Journal
        </Typography>
        <Typography sx={{ textAlign: 'right', fontSize: 14 }}>
          {moment(event.data().submittedAt.seconds * 1000).format('D MMM, YY')}
        </Typography>
      </Stack>
      <Typography
        sx={{
          textAlign: 'left',
          color: '#0B3954',
          fontSize: 16,
          fontWeight: 500,
          maxWidth: 480,
          pt: 1.5,
          pb: 1.5,
          mb: 1.5,
        }}
      >
        {event.data().description}
      </Typography>
    </Stack>
  );
};

export default CardHeader;
