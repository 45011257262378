import styled from '@emotion/styled';
import { Popper, Typography } from '@mui/material';
import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import * as React from 'react';

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    'boxSizing': 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
});

export default function ConditionsStoryCatch({
  forWho,
  value,
  setValue,
  error,
}) {
  return (
    <Stack direction={'column'} spacing={1} sx={{ width: '100%' }}>
      <Typography
        sx={{
          textAlign: 'start',
          fontSize: 16,
          fontWeight: 500,
          color: '#0B3954',
        }}
      >
        {forWho !== 1
          ? 'What areas of mental health have you been struggling with?'
          : 'What areas of mental health have they been struggling with?'}
      </Typography>
      <Autocomplete
        multiple
        disableListWrap
        value={value}
        onChange={(_event, newValue) => {
          setValue(newValue);
        }}
        id="story-catch-condition-select"
        options={conditions}
        PopperComponent={StyledPopper}
        freeSolo
        renderTags={(value, getTagProps) =>
          value.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip variant="filled" label={option} key={key} {...tagProps} />
            );
          })
        }
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            // placeholder="Select all that apply or enter other things"
            helperText="Press enter to add something that is not in this list"
          />
        )}
      />
      {error && (
        <Typography sx={{ color: 'red', textAlign: 'start', fontSize: 14 }}>
          {error}
        </Typography>
      )}
    </Stack>
  );
}

const conditions = [
  'Depressive Disorder',
  'Anxiety Disorder',
  'Obsessive compulsive disorder (OCD)',
  'Post-traumatic stress disorder (PTSD)',
  'Autism Spectrum Disorder',
  'Attention-deficit/hyperactivity disorder (ADHD)',
  'Schizophrenia',
  'Bipolar Disorder',
  'Substance Abuse/Addiction',
  'Personality Disorder',
  'Oppositional Defiance Disorder (ODD)',
  'Feeding/Eating Disorders',
];
