import { ArrowBackIosNew } from '@mui/icons-material';
import {
  AppBar,
  Container,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import React from 'react';
import ManageMedications from '../components/ManageMedications';
import { SignupContext } from '../contexts/Signup';

const Medications = () => {
  const { afterSignup } = React.useContext(SignupContext);

  return (
    <Container
      disableGutters
      maxWidth={'lg'}
      sx={{
        p: 3,
        minHeight: '100vh',
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
      }}
    >
      <AppBar position="fixed" sx={{ height: 56, bgcolor: 'white' }}>
        <Toolbar>
          {!afterSignup && (
            <IconButton
              size="small"
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={() => {
                window.history.back();
              }}
              sx={{ mr: 2, display: { sm: 'none' }, color: 'black' }}
            >
              <ArrowBackIosNew />
            </IconButton>
          )}
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: 'start',
              color: 'black',
            }}
          >
            Medications
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack
        direction={'column'}
        alignItems={'start'}
        spacing={2}
        sx={{ width: '100%', pt: 8 }}
      >
        <ManageMedications />
      </Stack>
    </Container>
  );
};

export default Medications;
