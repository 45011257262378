import {
  Add,
  ArrowBackIosNew,
  CloseRounded,
  EditRounded,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  AppBar,
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Lottie from 'lottie-react';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { AuthContext } from '../contexts/Auth';
import { ProfileContext } from '../contexts/Profile';
import { SignupContext } from '../contexts/Signup';
import firebase from '../Firebase';
import { emailIsValid } from '../utils/validator';

const ShareProfile = () => {
  const navigate = useNavigate();

  const { currentUser } = useContext(AuthContext);
  const { afterSignup } = useContext(SignupContext);
  const { organization } = useContext(ProfileContext);

  const [profileInvitations, setProfileInvitations] = useState([]);

  const [openAddEditInvitationDialog, setOpenAddEditInvitationDialog] =
    useState(false);
  const [invitationInEdit, setInvitationInEdit] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (currentUser) {
      const unsubscribe = firebase
        .firestore()
        .collection('profileInvitations')
        .where('invitedBy', '==', currentUser.uid)
        .onSnapshot((snapshot) => {
          setProfileInvitations(snapshot.docs);
          setLoaded(true);
        });

      // Return the unsubscribe function to clean up the listener on unmount
      return () => unsubscribe();
    }
  }, [currentUser]);

  useEffect(() => {
    if (!openAddEditInvitationDialog) {
      setInvitationInEdit(null);
    }
  }, [openAddEditInvitationDialog]);

  return (
    <Container
      disableGutters
      maxWidth={'lg'}
      sx={{
        p: 3,
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <AppBar position="fixed" sx={{ height: 56, bgcolor: 'white' }}>
        <Toolbar>
          <IconButton
            size="small"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => {
              window.history.back();
            }}
            sx={{ mr: 2, display: { sm: 'none' }, color: 'black' }}
          >
            <ArrowBackIosNew />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: 'start',
              color: 'black',
            }}
          >
            Profile Access
          </Typography>
        </Toolbar>
      </AppBar>
      <Stack
        direction={'column'}
        alignItems={'start'}
        spacing={2}
        sx={{ width: '100%', pt: 8 }}
      >
        <Stack
          direction={'column'}
          alignItems={'center'}
          spacing={2}
          sx={{ width: '100%' }}
        >
          {loaded && profileInvitations.length === 0 && (
            <>
              <Typography sx={{ fontSize: 22, fontWeight: 700 }}>
                {organization === 'Story Catch'
                  ? 'Invite others to view or collaborate on your story'
                  : 'Invite others to view or manage your health story'}
              </Typography>
              <Lottie
                animationData={require('../assets/lotties/profileAcessBlankState.json')}
                style={{
                  height: 220,
                  width: 280,
                }}
                loop={true}
              />
              <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                Provide friends, family, or others access to view or manage your
                profile together with you
              </Typography>
            </>
          )}
          {profileInvitations.map((invite) => (
            <Card
              key={invite.id}
              elevation={0}
              sx={{
                p: 0,
                width: '100%',
                borderBottom: '1px solid lightgray',
                borderRadius: 0,
                pb: 2,
              }}
            >
              <Stack
                direction={'row'}
                spacing={2}
                sx={{ width: '100%' }}
                alignItems={'center'}
              >
                <Stack
                  direction={'column'}
                  sx={{ flexGrow: 1, textAlign: 'start' }}
                  justifyContent={'start'}
                >
                  <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
                    {invite.data().name}
                  </Typography>
                  <Typography sx={{ color: '#4A4A4A', fontSize: 14 }}>
                    {`${invite.data().description}`}
                  </Typography>
                </Stack>
                <Typography
                  sx={{
                    color: '#4A4A4A',
                    height: 42,
                    border: '1px solid lightgray',
                    borderRadius: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pl: 2,
                    pr: 2,
                  }}
                >
                  {invite.data().role === 'admin' ? 'Manager' : 'Viewer'}
                </Typography>
                <IconButton
                  size="small"
                  sx={{
                    width: 42,
                    height: 42,
                    border: '1px solid lightgray',
                    borderRadius: 1,
                  }}
                  onClick={() => {
                    setInvitationInEdit(invite);
                    setOpenAddEditInvitationDialog(true);
                  }}
                >
                  <EditRounded />
                </IconButton>
              </Stack>
            </Card>
          ))}
        </Stack>
        <AddEditInvitation
          open={openAddEditInvitationDialog}
          setOpen={setOpenAddEditInvitationDialog}
          invitation={invitationInEdit}
        />
      </Stack>
      <Box sx={{ flexGrow: 1 }} />
      <Button
        variant="contained"
        onClick={() => setOpenAddEditInvitationDialog(true)}
        fullWidth
        size="large"
        startIcon={<Add />}
      >
        Add New
      </Button>
      {afterSignup && (
        <Button
          variant="contained"
          onClick={() => navigate('/dashboard/home')}
          fullWidth
          size="large"
          sx={{
            'bgcolor': '#AAAAAA',
            'mt': 2,
            ':focus': {
              bgcolor: '#AAAAAA',
            },
            ':hover': {
              bgcolor: '#AAAAAA',
            },
          }}
        >
          Finish for Now{' '}
        </Button>
      )}
    </Container>
  );
};

export default ShareProfile;

const AddEditInvitation = ({ open, setOpen, invitation }) => {
  const { currentUser } = useContext(AuthContext);
  const { currentProfile } = useContext(ProfileContext);

  const [email, setEmail] = useState('');
  const [role, setRole] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      if (invitation) {
        setEmail(invitation.data().invitedEmail);
        setRole(invitation.data().role);
        setName(invitation.data().name);
        setDescription(invitation.data().description);
      }
    } else {
      clear();
    }
  }, [open]);

  const clear = () => {
    setEmail('');
    setRole('');
    setName('');
    setDescription('');
    setError('');
  };

  const handleInvite = async () => {
    setError('');
    setLoading(true);
    // check if email is valid
    if (!emailIsValid(email)) {
      setLoading(false);
      setError('Invalid email');
      return;
    }
    // create a new invitation
    const invite = {
      invitedAt: firebase.firestore.Timestamp.now(),
      profileId: currentProfile.id,
      profileName: currentProfile.data().name,
      invitedBy: currentUser.uid,
      invitedEmail: email,
      name,
      description,
      // viewer or admin
      role,
      // pending, accepted, declined
      status: 'pending',
    };
    // if invitation is being edited, update it
    if (invitation) {
      delete invite.status;
      await firebase
        .firestore()
        .collection('profileInvitations')
        .doc(invitation.id)
        .update(invite)
        .catch((e) => {
          setError('Failed to update invitation');
        });
      setLoading(false);
      setOpen(false);
      clear();
      return;
    } else {
      await firebase
        .firestore()
        .collection('profileInvitations')
        .doc(`${currentProfile.id}_${email}`)
        .set(invite)
        .catch((e) => {
          setError('Failed to invite user');
        });
      setLoading(false);
      setOpen(false);
      clear();
    }
  };

  const handleDelete = async () => {
    if (invitation) {
      setLoading(true);
      await firebase
        .firestore()
        .collection('profileInvitations')
        .doc(invitation.id)
        .delete()
        .catch((e) => {
          console.log('e', e);
          setError('Failed to delete invitation');
        });
      setLoading(false);
      setOpen(false);
      clear();
    }
  };

  return (
    <Dialog fullWidth maxWidth="md" open={open} setOpen={setOpen}>
      <DialogTitle>
        <Stack
          direction={'row'}
          sx={{ width: '100%' }}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
            {invitation ? 'Edit Invitation' : 'Invite User'}
          </Typography>
          <IconButton onClick={() => setOpen(false)}>
            <CloseRounded />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Stack
          sx={{ width: '100%', textAlign: 'start' }}
          alignItems={'start'}
          spacing={2}
        >
          <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 2 }}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: '#0B3954',
                textAlign: 'start',
              }}
            >
              Name
            </Typography>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{
                '& .MuiInputBase-input': {
                  position: 'relative',
                  backgroundColor: 'white',
                  border: '1px solid',
                  borderColor: '#C4C4C4',
                  fontSize: 16,
                  padding: '10px 12px',
                  borderRadius: 1,
                },
              }}
            />
          </Stack>
          <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 2 }}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: '#0B3954',
                textAlign: 'start',
              }}
            >
              Email
            </Typography>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="User email"
              disabled={invitation}
              sx={{
                '& .MuiInputBase-input': {
                  position: 'relative',
                  backgroundColor: 'white',
                  border: '1px solid',
                  borderColor: '#C4C4C4',
                  fontSize: 16,
                  padding: '10px 12px',
                  borderRadius: 1,
                },
              }}
            />
          </Stack>
          <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 2 }}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: '#0B3954',
                textAlign: 'start',
              }}
            >
              Description
            </Typography>
            <TextField
              select
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              sx={{
                '& .MuiInputBase-input': {
                  position: 'relative',
                  backgroundColor: 'white',
                  border: '1px solid',
                  borderColor: '#C4C4C4',
                  fontSize: 16,
                  padding: '10px 12px',
                },
              }}
            >
              {[
                { value: 'Parent', label: 'Parent' },
                { value: 'Sibling', label: 'Sibling' },
                { value: 'Other family member', label: 'Other family member' },
                { value: 'Unrelated caregiver', label: 'Unrelated caregiver' },
                { value: 'Medical aid', label: 'Medical aid' },
                { value: 'Social worker', label: 'Social worker' },
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack direction={'column'} spacing={1} sx={{ width: '100%', mt: 2 }}>
            <Typography
              sx={{
                fontSize: 16,
                fontWeight: 500,
                color: '#0B3954',
                textAlign: 'start',
              }}
            >
              Role
            </Typography>
            <TextField
              select
              placeholder="Admin or Viewer"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              sx={{
                '& .MuiInputBase-input': {
                  position: 'relative',
                  backgroundColor: 'white',
                  border: '1px solid',
                  borderColor: '#C4C4C4',
                  fontSize: 16,
                  padding: '10px 12px',
                },
              }}
            >
              {[
                { value: 'admin', label: 'Admin' },
                { value: 'viewer', label: 'Viewer' },
              ].map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Box></Box>
          <LoadingButton
            fullWidth
            variant="contained"
            onClick={handleInvite}
            loading={loading}
            disabled={loading || !email || !role || !name || !description}
            size="large"
          >
            {invitation ? 'Update' : 'Invite'}
          </LoadingButton>
          {invitation && (
            <LoadingButton
              variant="contained"
              color="error"
              fullWidth
              onClick={handleDelete}
              loading={loading}
            >
              Delete Invitation
            </LoadingButton>
          )}
          <Typography sx={{ color: 'red' }}>{error}</Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
