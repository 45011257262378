import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React, { useEffect } from 'react';
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';
import preSurveyDialogEmotional from '../assets/lotties/preSurveyDialogEmotional.json';
import preSurveyDialogFatigue from '../assets/lotties/preSurveyDialogFatigue.json';
import useResponsive from '../hooks/useResponsive';
import { ASSESSMENTS } from './AddOrEditEventDialog';
import SurveyCard from './SurveyCard';

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton disabled={isFirstItemVisible} onClick={() => scrollPrev()}>
        <ArrowBackIos />
      </IconButton>
    </Box>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <IconButton disabled={isLastItemVisible} onClick={() => scrollNext()}>
        <ArrowForwardIos />
      </IconButton>
    </Box>
  );
}

const SurveysHomeWidget = () => {
  useEffect(() => {}, []);
  const isDesktop = useResponsive('up', 'md');

  return (
    <ScrollMenu
      LeftArrow={!isDesktop || LeftArrow}
      RightArrow={!isDesktop || RightArrow}
      style={{
        maxWidth: isDesktop
          ? 'calc(100vw - 248px - 48px)'
          : 'calc(100vw - 48px)',
      }}
      wrapperClassName={isDesktop ? 'scrollable' : 'scrollable-mobile'}
    >
      <SurveyCard
        surveyData={ASSESSMENTS[0]}
        title={'Emotional Health'}
        subtitle={'Measure how your health is affecting your emotions'}
        image={preSurveyDialogEmotional}
        bgcolor={'#FEF1AB'}
      />
      <SurveyCard
        surveyData={ASSESSMENTS[1]}
        formId="FMkEt9ok"
        title={'Fatigue'}
        subtitle={
          'Find out how much fatigue and tiredness is affecting your daily life'
        }
        image={preSurveyDialogFatigue}
        bgcolor={'#CFF5E3'}
      />
    </ScrollMenu>
  );
};

export default SurveysHomeWidget;
